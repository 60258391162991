import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = "https://bookbackend3.bruktiethiotour.com/api";

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

const setAuthToken = () => {
  const token = localStorage.getItem('userToken');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Fetch books by specific statuses for the logged-in author
export const fetchBooksByStatus = createAsyncThunk(
  'authors/fetchBooksByStatus',
  async (status, { rejectWithValue }) => {
    setAuthToken();
    const validStatuses = ['PENDING', 'PROCESSING', 'APPROVED', 'REJECTED'];
    if (!validStatuses.includes(status)) {
      return rejectWithValue('Invalid status value');
    }

    try {
      const response = await axios.get(`${baseURL}/book/logged-author-bystatus/${status}`);
      return response.data.books || [];
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching books by status');
    }
  }
);

// Fetch all books for the logged-in author
export const fetchAllBooksLoggedAuthor = createAsyncThunk(
  'authors/fetchAllBooksLoggedAuthor',
  async (_, { rejectWithValue }) => {
    setAuthToken();
    try {
      const response = await axios.get(`${baseURL}/book/allbooks-logged-author`);
      return response.data.books || [];
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching all books for logged-in author');
    }
  }
);

// Fetch all approved books for the logged-in author
export const fetchApprovedBooksLoggedAuthor = createAsyncThunk(
  'authors/fetchApprovedBooksLoggedAuthor',
  async (_, { rejectWithValue }) => {
    setAuthToken();
    try {
      const response = await axios.get(`${baseURL}/book/all-approved-books-logged-author`);
      return response.data.books || [];
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching approved books for logged-in author');
    }
  }
);

const authors = createSlice({
  name: 'authors',
  initialState: {
    items: [],
    loading: false,
    error: null,
    status: null,
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch books by status
      .addCase(fetchBooksByStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBooksByStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchBooksByStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      // Fetch all books for logged-in author
      .addCase(fetchAllBooksLoggedAuthor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllBooksLoggedAuthor.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchAllBooksLoggedAuthor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      // Fetch all approved books for logged-in author
      .addCase(fetchApprovedBooksLoggedAuthor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchApprovedBooksLoggedAuthor.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchApprovedBooksLoggedAuthor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export const { clearError } = authors.actions;
export default authors.reducer;
