import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import { useState } from "react";
import CustomModal from '../../components/modal'; // Adjust the import path as necessary
import { useLocation } from "react-router-dom"; // Import useLocation to access passed data
import axios from 'axios';

const EditBookForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalSeverity, setModalSeverity] = useState('success');

  const location = useLocation();
  const bookData = location.state?.bookData || {};
  const token = localStorage.getItem('adminToken'); // Get the token from local storage

 
  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
        const response = await axios.put(`https://bookbackend3.bruktiethiotour.com/api/admin/book/${bookData.id}`, values, {
            headers: { Authorization: `Bearer ${token}` } // Include the token if required
        });
        setModalSeverity('success');
        setModalMessage('Book updated successfully!');
    } catch (error) {
        console.error("Error updating book:", error);
        setModalSeverity('error');
        setModalMessage(error.response?.data?.message || 'Error updating book');
    } finally {
        setModalOpen(true); // Show the modal regardless of success or error
        setSubmitting(false);
    }
};

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          title: bookData.title || "",
          author: bookData.author || "",
          price: bookData.price || "",
          pages: bookData.pages || "",
          description: bookData.description || "",
          publicationYear: bookData.publicationYear || "",
          language: bookData.language || "",
        }}
        validationSchema={checkoutSchema}
        enableReinitialize // Reinitialize form when initialValues change
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px" gridTemplateColumns="repeat(1, 1fr)">
              <TextField
                fullWidth
                variant="filled"
                label="Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Author"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.author}
                name="author"
                error={!!touched.author && !!errors.author}
                helperText={touched.author && errors.author}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                name="price"
                error={!!touched.price && !!errors.price}
                helperText={touched.price && errors.price}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Pages"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.pages}
                name="pages"
                error={!!touched.pages && !!errors.pages}
                helperText={touched.pages && errors.pages}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Publication Year"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.publicationYear}
                name="publicationYear"
                error={!!touched.publicationYear && !!errors.publicationYear}
                helperText={touched.publicationYear && errors.publicationYear}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Language"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.language}
                name="language"
                error={!!touched.language && !!errors.language}
                helperText={touched.language && errors.language}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" fullWidth disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Update Book'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      {/* Custom Modal for alerts */}
      <CustomModal
        open={modalOpen}
        onClose={handleCloseModal}
        severity={modalSeverity}
        message={modalMessage}
      />
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Required"),
  author: yup.string().required("Required"),
  price: yup.number().required("Required").positive("Must be positive"),
  pages: yup.number().required("Required").positive("Must be positive"),
  description: yup.string().required("Required").min(15, "Must be at least 15 characters"),
  publicationYear: yup.string().required("Required"),
  language: yup.string().required("Required"),
  // Other validation rules for image and audio files can be added if necessary
});

export default EditBookForm;
