import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux"; 
import { createUser } from "../../redux/slices/userSlice"; 
import { useState } from "react"; 
import CustomModal from '../../components/modal'; // Adjust the import path as necessary

const RegisterUserForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalSeverity, setModalSeverity] = useState('success');

  const handleFormSubmit = async (values) => {
    const userData = {
      email: values.email,
      password: values.password,
      fname: values.fname,
      lname: values.lname,
      role: values.role,
    };
    
    // Dispatching the createUser action and wait for the promise
    const resultAction = await dispatch(createUser({ endpoint: 'admin/register', data: userData }));

    if (createUser.fulfilled.match(resultAction)) {
      // Success modal
      setModalMessage('User created successfully!');
      setModalSeverity('success');
    } else {
      // Failure modal
      setModalMessage(resultAction.error.message || 'Failed to create user!');
      setModalSeverity('error');
    }

    // Open the modal
    setModalOpen(true);
  };

  // Close modal handler
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px" gridTemplateColumns="repeat(1, 1fr)">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fname}
                name="fname"
                error={!!touched.fname && !!errors.fname}
                helperText={touched.fname && errors.fname}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lname}
                name="lname"
                error={!!touched.lname && !!errors.lname}
                helperText={touched.lname && errors.lname}
              />
              <FormControl fullWidth variant="filled">
                <InputLabel>Role</InputLabel>
                <Select
                  label="Role"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.role}
                  name="role"
                  error={!!touched.role && !!errors.role}
                >
                  <MenuItem value="ADMIN">ADMIN</MenuItem>
                  <MenuItem value="MODERATOR">MODERATOR</MenuItem>
          
                  <MenuItem value="MODERATOR">SALES MAN</MenuItem>
                  <MenuItem value="MODERATOR">COMPOSER</MenuItem>
                
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained"  fullWidth >
                Add User
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {/* Custom Modal for alerts */}
      <CustomModal
        open={modalOpen}
        onClose={handleCloseModal}
        severity={modalSeverity}
        message={modalMessage}
      />
    </Box>
  );
};

// Validation schema for the form
const checkoutSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Required"),
  password: yup.string().required("Required"),
  fname: yup.string().required("Required"),
  lname: yup.string().required("Required"),
  role: yup.string().required("Required"),
});

// Initial values for the form fields
const initialValues = {
  email: "",
  password: "",
  fname: "",
  lname: "",
  role: "",
};

export default RegisterUserForm;
