import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, Typography, CircularProgress, Alert, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper,
  TableSortLabel, TablePagination, Avatar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[4],
  marginTop: theme.spacing(2),
}));

const Last7Days = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchLast7DaysBooks = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/book/last7days/');
      if (response.data.success) {
        setBooks(response.data.books);
      } else {
        setError('Failed to fetch books from the last 7 days.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLast7DaysBooks();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedBooks = [...books].sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    } else {
      return a[orderBy] < b[orderBy] ? 1 : -1;
    }
  });

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" mb={2}>Books from the Last 7 Days</Typography>

      {loading && (
       <Loading />
      )}

      {error && (
       <ErrorComponent
       message="Oops! Something went wrong. Please try again."
       showIcon={true}
       severity="error"
     />
      )}

      {!loading && !error && books.length === 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          No books available from the last 7 days.
        </Alert>
      )}

      {!loading && !error && books.length > 0 && (
        <StyledTableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection={orderBy === 'title' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'title'}
                    direction={orderBy === 'title' ? order : 'asc'}
                    onClick={() => handleRequestSort('title')}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'author' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'author'}
                    direction={orderBy === 'author' ? order : 'asc'}
                    onClick={() => handleRequestSort('author')}
                  >
                    Author
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'publicationYear' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'publicationYear'}
                    direction={orderBy === 'publicationYear' ? order : 'asc'}
                    onClick={() => handleRequestSort('publicationYear')}
                  >
                    Year
                  </TableSortLabel>
                </TableCell>
                <TableCell>Language</TableCell>
                <TableCell sortDirection={orderBy === 'price' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'price'}
                    direction={orderBy === 'price' ? order : 'asc'}
                    onClick={() => handleRequestSort('price')}
                  >
                    Price
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'pages' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'pages'}
                    direction={orderBy === 'pages' ? order : 'asc'}
                    onClick={() => handleRequestSort('pages')}
                  >
                    Pages
                  </TableSortLabel>
                </TableCell>
                <TableCell>Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedBooks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((book) => (
                <TableRow key={book.id} hover>
                  <TableCell>{book.title}</TableCell>
                  <TableCell>{book.author}</TableCell>
                  <TableCell>{book.publicationYear}</TableCell>
                  <TableCell>{book.language}</TableCell>
                  <TableCell>{book.price}</TableCell>
                  <TableCell>{book.pages}</TableCell>
                  <TableCell>
                    <Avatar
                      variant="square"
                      src={`https://bookbackend3.bruktiethiotour.com/${book.imageFilePath}`}
                      alt={book.title}
                      sx={{ width: 60, height: 80, borderRadius: 1 }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={books.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </StyledTableContainer>
      )}
    </Box>
  );
};

export default Last7Days;
