import React, { useState, useRef } from "react";
import axios from "axios";
import { Button, CircularProgress, Snackbar, Alert, TextField } from "@mui/material";
import { useParams } from "react-router-dom";

const UploadAudio = () => {
  const { bookId } = useParams(); // Get bookId from URL parameters
  const [audioFile, setAudioFile] = useState(null);
  const [episode, setEpisode] = useState(""); // State to manage episode input
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const userToken = localStorage.getItem("userToken"); // Get user token from localStorage

  // Reference for the file input to reset it
  const fileInputRef = useRef(null);

  // Handle audio file change
  const handleAudioChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAudioFile(file);
    }
  };

  // Handle episode change
  const handleEpisodeChange = (event) => {
    setEpisode(event.target.value); // Update the episode state
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!audioFile) {
      setErrorMessage("Please select an audio file to upload.");
      return;
    }
    if (!episode) {
      setErrorMessage("Please enter an episode name.");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("episode", episode); // Add the episode name from input
    formData.append("audio", audioFile); // Attach the audio file

    try {
      const response = await axios.post(
        `https://bookbackend3.bruktiethiotour.com/api/book/upload-audio/${bookId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken}`, // Attach user token
          },
        }
      );

      if (response.data.success) {
        setSuccessMessage(response.data.message);
        // Reset the form fields after successful upload
        setEpisode(""); // Clear episode input
        setAudioFile(null); // Clear the audio file state
        fileInputRef.current.value = ""; // Clear the file input field
      }
    } catch (error) {
      setErrorMessage("An error occurred while uploading the audio.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Upload Audio for Book {bookId}</h2>

      {/* Episode Input */}
      <TextField
        label="Episode Name"
        value={episode}
        onChange={handleEpisodeChange}
        variant="outlined"
        fullWidth
        margin="normal"
        disabled={loading}
      />

      {/* Audio File Input */}
      <input
        type="file"
        accept="audio/*"
        onChange={handleAudioChange}
        disabled={loading}
        ref={fileInputRef} // Reference to the file input
      />
      <br /> <br />

      {/* Submit Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading || !audioFile || !episode}
      >
        Upload Audio
      </Button>

      {loading && <CircularProgress />}

      {/* Success or Error message */}
      <Snackbar
        open={!!successMessage || !!errorMessage}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
            setErrorMessage("");
          }}
          severity={successMessage ? "success" : "error"}
        >
          {successMessage || errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UploadAudio;
