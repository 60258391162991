import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  CircularProgress,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const SubscriptionManagement = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState({
    id: null,
    name: '',
    limitCount: '',
    price: '',
  });

  // Fetch the token from local storage
  const token = localStorage.getItem('adminToken'); // Retrieve token

  // Fetch subscriptions
  const fetchSubscriptions = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/subscription');
      if (response.data.success) {
        setSubscriptions(response.data.subscriptions);
      } else {
        setError('Failed to fetch subscriptions.');
        setSubscriptions([]);
      }
    } catch (error) {
      setError('Failed to fetch subscriptions.');
      setSubscriptions([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch subscriptions on component mount
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  // Handle dialog open
  const handleClickOpen = (subscription) => {
    setCurrentSubscription(subscription || {
      id: null,
      name: '',
      limitCount: '',
      price: '',
    });
    setEditMode(!!subscription);
    setOpenDialog(true);
  };

  // Handle dialog close
  const handleClose = () => {
    setOpenDialog(false);
    setCurrentSubscription({
      id: null,
      name: '',
      limitCount: '',
      price: '',
    });
  };

  // Handle subscription delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://bookbackend3.bruktiethiotour.com/api/subscription/${id}`);
      setSubscriptions(subscriptions.filter(sub => sub.id !== id));
    } catch (error) {
      setError('Failed to delete subscription.');
    }
  };

  // Handle subscription edit/submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { id, name, limitCount, price } = currentSubscription;

    try {
      if (editMode) {
        // Update subscription
        await axios.put(
          `https://bookbackend3.bruktiethiotour.com/api/subscription/${id}`,
          {
            name,
            limitCount,
            price,
          },
          
        );
      } else {
        // Add new subscription
        await axios.post('https://bookbackend3.bruktiethiotour.com/api/subscription', {
          name,
          limitCount,
          price,
        });
      }

      // Refresh subscriptions
      fetchSubscriptions(); // Call fetchSubscriptions to refresh the list
      handleClose();
    } catch (error) {
      setError('Failed to save subscription. Please check your input or permissions.');
    }
  };

  return (
    <div>
      <Typography variant="h4">Subscription Management</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          {subscriptions.length > 0 ? (
            subscriptions.map((subscription) => (
              <Card key={subscription.id} style={{ margin: '16px', padding: '16px' }}>
                <CardContent>
                  <Typography variant="h5">{subscription.name}</Typography>
                  <Typography variant="body2">Limit Count: {subscription.limitCount}</Typography>
                  <Typography variant="body2">Price: {subscription.price}</Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => handleClickOpen(subscription)}>Edit</Button>
                  <Button color="secondary" onClick={() => handleDelete(subscription.id)}>Delete</Button>
                </CardActions>
              </Card>
            ))
          ) : (
            <Typography>No subscriptions found.</Typography>
          )}
        </div>
      )}

      <Button variant="contained" color="primary" onClick={() => handleClickOpen(null)}>
        Add Subscription
      </Button>

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Edit Subscription' : 'Add Subscription'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            fullWidth
            variant="outlined"
            value={currentSubscription.name}
            onChange={(e) => setCurrentSubscription({ ...currentSubscription, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Limit Count"
            fullWidth
            variant="outlined"
            type="number"
            value={currentSubscription.limitCount}
            onChange={(e) => setCurrentSubscription({ ...currentSubscription, limitCount: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Price"
            fullWidth
            variant="outlined"
            type="number"
            value={currentSubscription.price}
            onChange={(e) => setCurrentSubscription({ ...currentSubscription, price: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
};

export default SubscriptionManagement;
