import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const TopSales = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token=localStorage.getItem("adminToken");
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/sales/books/reports/top-books',{          
            headers: {
              Authorization: `Bearer ${token}`,
            },          
        });

        if (response.data && Array.isArray(response.data)) {
          setData(response.data);
        } else {
          console.error('Unexpected API response:', response.data);
          setError('No data found or response format is incorrect.');
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const sortedData = data.sort((a, b) => {
    if (sortDirection === 'asc') {
      return a[sortColumn] < b[sortColumn] ? -1 : 1;
    }
    return a[sortColumn] > b[sortColumn] ? -1 : 1;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Order Number', 'Price', 'Total Spent', 'User', 'Status', 'Date']],
      body: data.map(item => [
        item.orderNumber,
        item.price,
        item.totalSpent,
        item.orderUser.fname,
        item.status,
        new Date(item.createdAt).toLocaleDateString(), // Format as needed
      ]),
    });
    doc.save('topsales.pdf');
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
    
    
    </div>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Top Sales
      </Typography>
      <Button variant="contained" color="primary" onClick={exportToPDF}>
        Export to PDF
      </Button>
      <CSVLink
        data={data}
        filename="topsales.csv"
        className="btn btn-secondary"
        target="_blank"
        color="primary"
      >
        Export to Excel
      </CSVLink>
      <TableContainer sx={{ backgroundColor: '#141B2D', marginTop: 10}}  component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'orderNumber'}
                  direction={sortColumn === 'orderNumber' ? sortDirection : 'asc'}
                  onClick={() => handleSort('orderNumber')}
                >
                  Order Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'price'}
                  direction={sortColumn === 'price' ? sortDirection : 'asc'}
                  onClick={() => handleSort('price')}
                >
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'totalSpent'}
                  direction={sortColumn === 'totalSpent' ? sortDirection : 'asc'}
                  onClick={() => handleSort('totalSpent')}
                >
                  Total Spent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'orderUser.fname'}
                  direction={sortColumn === 'orderUser.fname' ? sortDirection : 'asc'}
                  onClick={() => handleSort('orderUser.fname')}
                >
                  User
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'status'}
                  direction={sortColumn === 'status' ? sortDirection : 'asc'}
                  onClick={() => handleSort('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'createdAt'}
                  direction={sortColumn === 'createdAt' ? sortDirection : 'asc'}
                  onClick={() => handleSort('createdAt')}
                >
                  Date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{row.orderNumber}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>{row.totalSpent}</TableCell>
                <TableCell>{row.orderUser.fname}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TopSales;
