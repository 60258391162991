import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

const ReviewForm = () => {
  const { bookId } = useParams(); // Extract bookId from URL params
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalSeverity, setModalSeverity] = useState('success'); // 'success' or 'error'

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment || !rating) {
      setModalSeverity('error');
      setModalMessage('Please fill in all fields before submitting.');
      setModalOpen(true);
      return;
    }

    setLoading(true);

    const userToken = localStorage.getItem('userToken'); // Get token from local storage

    try {
      const response = await axios.post(
        `https://bookbackend3.bruktiethiotour.com/api/review/create/${bookId}`,
        { comment, reviewRating: rating },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success) {
        setModalSeverity('success');
        setModalMessage('Your review has been submitted successfully!');
        setComment('');
        setRating('');
      } else {
        setModalSeverity('error');
        setModalMessage(response.data.message || 'Unexpected error occurred.');
      }
    } catch (err) {
      if (err.response) {
        const errorMessage =
          err.response.data.message || 'An error occurred while submitting your review.';
        setModalSeverity('error');
        setModalMessage(errorMessage);
      } else {
        setModalSeverity('error');
        setModalMessage('Network error. Please try again later.');
      }
    } finally {
      setLoading(false);
      setModalOpen(true);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: 'auto',
        mt: 4,
        p: 2,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Typography variant="h5" align="center" mb={2}>
        Submit Your Review
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Comment"
          variant="outlined"
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
        <Select
          fullWidth
          value={rating}
          onChange={(e) => setRating(e.target.value)}
          displayEmpty
          required
          sx={{ mb: 2 }}
        >
          <MenuItem value="" disabled>
            Select Rating
          </MenuItem>
          <MenuItem value={1}>1 - Poor</MenuItem>
          <MenuItem value={2}>2 - Fair</MenuItem>
          <MenuItem value={3}>3 - Good</MenuItem>
          <MenuItem value={4}>4 - Very Good</MenuItem>
          <MenuItem value={5}>5 - Excellent</MenuItem>
        </Select>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
        </Button>
      </form>

      {/* Modal for success or error messages */}
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>{modalSeverity === 'success' ? 'Success' : 'Error'}</DialogTitle>
        <DialogContent>
          <Typography>{modalMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReviewForm;
