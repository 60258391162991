import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TableSortLabel,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const BASE_URL = 'https://bookbackend3.bruktiethiotour.com/api';

const SalesReportByBook = () => {
  const { id } = useParams(); // Get the book ID from the route parameters
  const [bookReport, setBookReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  useEffect(() => {
    const fetchReport = async () => {
      setLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('adminToken') || localStorage.getItem('userToken') || null;
        const response = await axios.get(`${BASE_URL}/sales/books/reports/bybookid/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        setBookReport(response.data[0]); // Assumes response is an array with one object
      } catch (err) {
        setError('Failed to fetch the report.');
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [id]);

  const handleOpenDetails = () => setOpenDetails(true);
  const handleCloseDetails = () => setOpenDetails(false);

  // Sorting logic
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData =
    bookReport && bookReport.order
      ? [...bookReport.order].sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
          if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0;
        })
      : [];

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text(`Sales Report for Book ID: ${id}`, 10, 10);
    doc.autoTable({ html: '#sales-report-table' });
    doc.save(`sales_report_${id}.pdf`);
  };

  const printTable = () => {
    window.print();
  };

  return (
    <Box sx={{ maxWidth: 1000, margin: 'auto', mt: 4, p: 3, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h5" gutterBottom>
        Sales Report by Book
      </Typography>

      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
      ) : bookReport && bookReport.order ? (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <ReactHTMLTableToExcel
              id="excel-button"
              className="btn btn-outline-primary"
              table="sales-report-table"
              filename={`Sales_Report_Book_${id}`}
              sheet="Sheet"
              buttonText="Export to Excel"
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={exportToPDF}
            >
              Export to PDF
            </Button>
            <IconButton color="primary" onClick={printTable}>
              <PrintIcon />
            </IconButton>
          </Box>

          <TableContainer component={Paper} sx={{ backgroundColor: '#141B2D', marginTop: 10}}>
            <Table id="sales-report-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'id'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('id')}
                    >
                      Order ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'title'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('title')}
                    >
                      Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'author'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('author')}
                    >
                      Author
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'price'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('price')}
                    >
                      Price
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Total Sales</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'totalSoldCount'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('totalSoldCount')}
                    >
                      Total Sold Count
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.length > 0 ? (
                  sortedData.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>{bookReport.title}</TableCell>
                      <TableCell>{bookReport.author}</TableCell>
                      <TableCell>{order.type}</TableCell>
                      <TableCell>{order.price}</TableCell>
                      <TableCell>{order.totalSales}</TableCell>
                      <TableCell>{order.totalSoldCount}</TableCell>
                      <TableCell>
                        <Button variant="outlined" color="secondary" onClick={handleOpenDetails}>
                          Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Typography>No report data available.</Typography>
      )}

      {/* Details Dialog */}
      <Dialog open={openDetails} onClose={handleCloseDetails}>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          {bookReport && bookReport.order && bookReport.order[0] && (
            <Typography>
              <strong>Service Charged:</strong> {bookReport.order[0].serviceCharged} <br />
              <strong>Total Sales:</strong> {bookReport.order[0].totalSales} <br />
              <strong>Total Sold Count:</strong> {bookReport.order[0].totalSoldCount}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SalesReportByBook;
