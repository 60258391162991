import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
    CircularProgress,
    Modal,
} from '@mui/material';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const SubscriptionOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const adminToken = localStorage.getItem('adminToken');

    // Enum for status
    const statusOptions = ['PENDING', 'PROCESSING', 'APPROVED', 'CANCELLED'];

    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true); // Start loading
            try {
                const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/subscription-order/', {
                    headers: {
                        'Authorization': `Bearer ${adminToken}`, // Include the token
                    },
                });

                if (response.data) {
                    setOrders(response.data.orders);
                } else {
                    console.error('Error fetching orders:', response.data.message);
                }
            } catch (error) {
                console.error('API error:', error);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchOrders();
    }, [adminToken]);

    // Search filter
    const filteredOrders = orders.filter(order =>
        order.orderNumber.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Export to Excel
    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filteredOrders);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Orders');
        XLSX.writeFile(wb, 'SubscriptionOrders.xlsx');
    };

    // Export to PDF
    const exportToPDF = () => {
        const doc = new jsPDF();
        const table = document.getElementById('orders-table');
        html2canvas(table).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            doc.addImage(imgData, 'PNG', 10, 10);
            doc.save('SubscriptionOrders.pdf');
        });
    };

    // Handle Approve button click
    const handleApproveClick = (order) => {
        setSelectedOrder(order);
        setNewStatus(order.status); // Pre-fill the status with current status
    };

    // Handle modal close
    const handleCloseModal = () => {
        setSelectedOrder(null);
        setNewStatus('');
    };

    // Handle status update
    const handleStatusUpdate = async () => {
        try {
            // Validate new status
            if (!statusOptions.includes(newStatus)) {
                alert('Invalid status selected. Please choose a valid status.');
                return;
            }

            const response = await axios.put(
                `https://bookbackend3.bruktiethiotour.com/api/subscription-order/${selectedOrder.id}`,
                { status: newStatus },
                {
                    headers: {
                        'Authorization': `Bearer ${adminToken}`, // Include the token
                    },
                }
              

            );
            console.log(newStatus);
            console.log(selectedOrder.id);
            if (response.data) {
                // Update the orders state with the new status
                setOrders(prevOrders =>
                    prevOrders.map(order =>
                        order.id === selectedOrder.id ? { ...order, status: newStatus } : order
                    )
                );
                handleCloseModal(); // Close the modal
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                Subscription Orders
            </Typography>

            <TextField
                label="Search by Order Number"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 2 }}
            />

            <Button variant="contained" color="primary" onClick={exportToExcel} sx={{ mr: 1 }}>
                Export to Excel
            </Button>
            <Button variant="contained" color="secondary" onClick={exportToPDF} sx={{ mr: 1 }}>
                Export to PDF
            </Button>
            <Button variant="contained" onClick={() => window.print()}>
                Print
            </Button>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer sx={{ maxHeight: 400, mt: 2 }}>
                    <Table id="orders-table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Order Number</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Subscription Type</TableCell>
                                <TableCell>Total Price</TableCell>
                                <TableCell>User First Name</TableCell>
                                <TableCell>User Last Name</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredOrders
                            ).map(order => (
                                <TableRow key={order.id}>
                                    <TableCell>{order.orderNumber}</TableCell>
                                    <TableCell>{order.status}</TableCell>
                                    <TableCell>{order.subscriptionType}</TableCell>
                                    <TableCell>{order.totalPrice}</TableCell>
                                    <TableCell>{order.subscriptionUser?.fname}</TableCell>
                                    <TableCell>{order.subscriptionUser?.lname}</TableCell>
                                    <TableCell>
                                        <Button variant="contained" onClick={() => handleApproveClick(order)}>
                                            Approve
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredOrders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {/* Modal for editing status */}
            <Modal
                open={Boolean(selectedOrder)}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        Edit Order Status
                    </Typography>
                    <TextField
                        select
                        label="Status"
                        variant="outlined"
                        fullWidth
                        value={newStatus}
                        onChange={(e) => setNewStatus(e.target.value)}
                        sx={{ mb: 2 }}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="" disabled>Select status</option>
                        {statusOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                    <Button variant="contained" onClick={handleStatusUpdate} sx={{ mr: 1 }}>
                        Update Status
                    </Button>
                    <Button variant="outlined" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default SubscriptionOrders;
