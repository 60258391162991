import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Paper,
} from '@mui/material';

const AddSubscriptionForm = () => {
  const [name, setName] = useState('');
  const [limitCount, setLimitCount] = useState('');
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const token = localStorage.getItem('adminToken');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await axios.post(
        'https://bookbackend3.bruktiethiotour.com/api/subscription',
        { name, limitCount, price },
       
      );
      setSuccessMessage('Subscription added successfully!');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Failed to add subscription.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ padding: 3, maxWidth: 400, margin: 'auto' }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Add Subscription
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Limit Count"
          variant="outlined"
          fullWidth
          margin="normal"
          value={limitCount}
          onChange={(e) => setLimitCount(e.target.value)}
          required
        />
        <TextField
          label="Price"
          variant="outlined"
          fullWidth
          margin="normal"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ marginTop: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Add Subscription'}
        </Button>
      </form>
      {successMessage && (
        <Typography color="success" sx={{ marginTop: 2 }}>
          {successMessage}
        </Typography>
      )}
      {errorMessage && (
        <Typography color="error" sx={{ marginTop: 2 }}>
          {errorMessage}
        </Typography>
      )}
    </Paper>
  );
};

export default AddSubscriptionForm;
