import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const BookSalesByAuthor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('title');
const token=localStorage.getItem("adminToken");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://bookbackend3.bruktiethiotour.com/api/sales/books/reports/total-byauthorid/${id}`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data && Array.isArray(response.data)) {
          // Extract books from the response
          const books = response.data.flatMap(author => 
            author.book.map(b => ({
              title: b.title,
              salesCount: b.order.reduce((total, order) => total + order.bookSoldCount, 0),
              totalRevenue: b.order.reduce((total, order) => total + parseFloat(order.totalSales), 0).toFixed(2),
              author: author.fname,
              dateSold: new Date().toLocaleDateString() // Modify as needed based on your requirements
            }))
          );
          setData(books);
        } else {
          console.error('Unexpected API response:', response.data);
          setError('No data found or response format is incorrect.');
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const sortedData = data
    .filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortColumn] < b[sortColumn] ? -1 : 1;
      }
      return a[sortColumn] > b[sortColumn] ? -1 : 1;
    });

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Book Title', 'Sales Count', 'Total Revenue', 'Author', 'Date Sold']],
      body: data.map(item => [
        item.title,
        item.salesCount,
        item.totalRevenue,
        item.author,
        item.dateSold,
      ]),
    });
    doc.save('booksales.pdf');
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
    </div>;
  }

  return (
    <div>
      <Typography variant="h4">Book Sales by Author</Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={exportToPDF}>
        Export to PDF
      </Button>
      <CSVLink
        data={data}
        filename="booksales.csv"
        className="btn btn-primary"
        target="_blank"
      >
        Export to CSV
      </CSVLink>
      <Button variant="outlined" onClick={() => window.print()} style={{ marginLeft: '8px' }}>
        Print
      </Button>
      
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'title'}
                  direction={sortColumn === 'title' ? sortDirection : 'asc'}
                  onClick={() => handleSort('title')}
                >
                  Book Title
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'salesCount'}
                  direction={sortColumn === 'salesCount' ? sortDirection : 'asc'}
                  onClick={() => handleSort('salesCount')}
                >
                  Sales Count
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'totalRevenue'}
                  direction={sortColumn === 'totalRevenue' ? sortDirection : 'asc'}
                  onClick={() => handleSort('totalRevenue')}
                >
                  Total Revenue
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'author'}
                  direction={sortColumn === 'author' ? sortDirection : 'asc'}
                  onClick={() => handleSort('author')}
                >
                  Author
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'dateSold'}
                  direction={sortColumn === 'dateSold' ? sortDirection : 'asc'}
                  onClick={() => handleSort('dateSold')}
                >
                  Date Sold
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.salesCount}</TableCell>
                <TableCell>{row.totalRevenue}</TableCell>
                <TableCell>{row.author}</TableCell>
                <TableCell>{row.dateSold}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BookSalesByAuthor;
