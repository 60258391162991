import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../redux/slices/userSlice"; // Update this import path
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Modal, Typography } from "@mui/material";
import CustomModal from '../../components/modal'; // Adjust the import path as necessary

const EditUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = location.state?.userData;
  const [formData, setFormData] = useState({
    email: userData?.email || "",
    fname: userData?.fname || "",
    lname: userData?.lname || "",
    role: userData?.role || "",
  });

  // Destructure status from Redux state
  const { items: users, loading, error, status } = useSelector((state) => state.users);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUser({ endpoint: "admin/admins", id: userData.id, data: formData }));
    if (status === "succeeded") {
        setOpenModal(true);
      }
  };

 

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/users");
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="First Name"
        name="fname"
        value={formData.fname}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Last Name"
        name="lname"
        value={formData.lname}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth variant="filled" margin="normal">
        <InputLabel>Role</InputLabel>
        <Select label="Role" onChange={handleChange} value={formData.role} name="role">
          <MenuItem value="ADMIN">ADMIN</MenuItem>
          <MenuItem value="MODERATOR">MODERATOR</MenuItem>
        </Select>
      </FormControl>
      
      <Button 
  type="submit" 
  variant="contained" 
  color="secondary" 
  fullWidth // This makes the button full width
>
  Save Changes
</Button>

      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        severity= {status === "succeeded" ? "success" : "Failed to update user."}
        message="message"
      />
     
    </Box>
  );
};

export default EditUser;
