import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Typography,
  TextField,
  Box,
} from '@mui/material';

const SubscriptionTiers = () => {
  const [tiers, setTiers] = useState([]);
  const [selectedTier, setSelectedTier] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const apiUrl = 'https://bookbackend3.bruktiethiotour.com/api/subscriptions/tiers';

  // Fetch subscription tiers
  const fetchTiers = async () => {
    try {
      const response = await axios.get(apiUrl);
      setTiers(response.data.data);
    } catch (error) {
      console.error('Error fetching subscription tiers:', error);
    }
  };

  useEffect(() => {
    fetchTiers();
  }, []);

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/${id}`);
      setModalSuccess(true);
      setModalMessage('Subscription tier deleted successfully!');
      fetchTiers(); // Refresh data
    } catch (error) {
      setModalSuccess(false);
      setModalMessage('Failed to delete subscription tier.');
    } finally {
      setModalOpen(true);
    }
  };

  // Handle edit
  const handleEdit = (tier) => {
    setSelectedTier(tier);
    setEditMode(true);
    setModalOpen(true);
  };

  // Handle modal close
  const closeModal = () => {
    setModalOpen(false);
    setSelectedTier(null);
    setEditMode(false);
  };

  // Handle form submission for editing
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${apiUrl}/${selectedTier.id}`, selectedTier);
      setModalSuccess(true);
      setModalMessage('Subscription tier updated successfully!');
      fetchTiers(); // Refresh data
    } catch (error) {
      setModalSuccess(false);
      setModalMessage('Failed to update subscription tier.');
    } finally {
      closeModal();
    }
  };

  // Handle input change for editing
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedTier({ ...selectedTier, [name]: value });
  };

  return (
    <Box sx={{ mt: 4, mx: 'auto', maxWidth: 800 }}>
      <Typography variant="h5" gutterBottom>
        Subscription Tiers
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tier Name</TableCell>
              <TableCell>Monthly Price</TableCell>
              <TableCell>Annual Price</TableCell>
              <TableCell>Content Type</TableCell>
              <TableCell>Benefit Limit</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiers.map((tier) => (
              <TableRow key={tier.id}>
                <TableCell>{tier.id}</TableCell>
                <TableCell>{tier.tier_name}</TableCell>
                <TableCell>{tier.monthly_price}</TableCell>
                <TableCell>{tier.annual_price}</TableCell>
                <TableCell>{tier.content_type}</TableCell>
                <TableCell>{tier.benefit_limit}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(tier)}
                    sx={{ mr: 1 }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDelete(tier.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Modal */}
      <Modal open={modalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxWidth: 400,
            textAlign: 'center',
          }}
        >
          {editMode ? (
            <>
              <Typography variant="h6" gutterBottom>
                Edit Subscription Tier
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Tier Name"
                  name="tier_name"
                  value={selectedTier?.tier_name || ''}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Monthly Price"
                  name="monthly_price"
                  value={selectedTier?.monthly_price || ''}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Annual Price"
                  name="annual_price"
                  value={selectedTier?.annual_price || ''}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Content Type"
                  name="content_type"
                  value={selectedTier?.content_type || ''}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  select
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="books">Books</option>
                  <option value="audio_books">Audio Books</option>
                  <option value="exclusive_content">Exclusive Content</option>
                </TextField>
                <TextField
                  label="Benefit Limit"
                  name="benefit_limit"
                  value={selectedTier?.benefit_limit || ''}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Save
                </Button>
              </form>
            </>
          ) : (
            <Typography variant="body1" color={modalSuccess ? 'green' : 'red'}>
              {modalMessage}
            </Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default SubscriptionTiers;
