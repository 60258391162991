import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";

const ProposalPage = () => {
  const [communications, setCommunications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCommunication, setSelectedCommunication] = useState(null);
  const [formData, setFormData] = useState({
    message: "",
    serviceCharge: "",
  });
  const userToken = localStorage.getItem("userToken");
  const { enqueueSnackbar } = useSnackbar();

  // Fetch communications
  useEffect(() => {
    const fetchCommunications = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get("https://bookbackend3.bruktiethiotour.com/api/comm/logged-author", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.data && response.data.succss) {
          setCommunications(response.data.communications);
        } else {
          setError("Failed to fetch communications");
        }
      } catch (err) {
        setError("An error occurred while fetching communications");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCommunications();
  }, [userToken]);

  // Edit communication
  const handleEditClick = (communication) => {
    setSelectedCommunication(communication);
    setFormData({
      message: communication.message,
      serviceCharge: communication.serviceCharge,
    });
    setEditModalOpen(true);
  };

  // Delete communication
  const handleDeleteClick = async (id) => {
    try {
      const response = await axios.delete(`https://bookbackend3.bruktiethiotour.com/api/comm/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response.data.success) {
        enqueueSnackbar("Communication deleted successfully", { variant: "success" });
        setCommunications(communications.filter((comm) => comm.id !== id));
      } else {
        enqueueSnackbar("Failed to delete communication", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("Error deleting communication", { variant: "error" });
    }
  };

  // Close modal
  const handleCloseModal = () => {
    setEditModalOpen(false);
    setFormData({
      message: "",
      serviceCharge: "",
    });
  };

  // Form input change handler
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Update communication
  const handleUpdateCommunication = async () => {
    try {
      const updatedData = {
        message: formData.message,
        serviceCharge: formData.serviceCharge,
      };
      await axios.put(
        `https://bookbackend3.bruktiethiotour.com/api/comm/change-agreement/${selectedCommunication.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      enqueueSnackbar("Communication updated successfully", { variant: "success" });
      setCommunications(
        communications.map((comm) =>
          comm.id === selectedCommunication.id
            ? { ...comm, ...updatedData }
            : comm
        )
      );
      handleCloseModal(); // Close the modal after update
    } catch (err) {
      enqueueSnackbar("Error updating communication", { variant: "error" });
    }
  };


  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        Proposal Communications
      </Typography>

      {/* Loading State */}
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <List>
          {communications.map((comm) => (
            <ListItem key={comm.id} sx={{ borderBottom: "1px solid #ccc" }}>
              <ListItemText
                primary={comm.message}
                secondary={`Service Charge: ${comm.serviceCharge}`}
              />
              <IconButton onClick={() => handleEditClick(comm)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteClick(comm.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}

      {/* Edit Modal */}
      <Modal open={editModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: 400,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Communication
          </Typography>
          <TextField
            label="Message"
            name="message"
            fullWidth
            value={formData.message}
            onChange={handleFormChange}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Service Charge"
            name="serviceCharge"
            type="number"
            fullWidth
            value={formData.serviceCharge}
            onChange={handleFormChange}
            sx={{ marginBottom: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleUpdateCommunication}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProposalPage;
