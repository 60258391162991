import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = "https://bookbackend3.bruktiethiotour.com/api";

// Set default headers for axios
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

// Function to set the authorization token in the headers
const setAuthToken = () => {
  const token = localStorage.getItem('adminToken') || localStorage.getItem('userToken') || null;

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Thunks for CRUD operations with error handling
export const fetchBook = createAsyncThunk('crud/fetchBook', async (endpoint, { rejectWithValue }) => {
  setAuthToken();
  try {
    const response = await axios.get(`${baseURL}/${endpoint}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred while fetching data');
  }
});

export const getBookById = createAsyncThunk('crud/getBookById', async (id, { rejectWithValue }) => {
  setAuthToken();
  try {
    const response = await axios.get(`${baseURL}/book/find/${id}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred while fetching the book');
  }
});
export const createBook = createAsyncThunk('crud/createBook', async ({ endpoint, data }, { rejectWithValue }) => {
  setAuthToken();
  try {
    const response = await axios.post(`${baseURL}/${endpoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Backend responded with a status code other than 2xx
      console.error('Backend Error:', error.response.data);
      return rejectWithValue(error.response.data); // Pass backend message to the Redux store
    } else if (error.request) {
      // No response received from the server
      console.error('No Response Error:', error.request);
      return rejectWithValue('No response from the server. Please check your connection.');
    } else {
      // Error during request setup
      console.error('Request Error:', error.message);
      return rejectWithValue('Failed to create book due to a request setup error');
    }
  }
});


export const updateBook = createAsyncThunk('crud/updateBook', async ({ endpoint, id, data }, { rejectWithValue }) => {
  setAuthToken();
  try {
    const response = await axios.put(`${baseURL}/${endpoint}/${id}`, data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred while updating data');
  }
});

export const deleteBook = createAsyncThunk('crud/deleteBook', async ({ endpoint, id }, { rejectWithValue }) => {
  setAuthToken();
  try {
    const response = await axios.delete(`${baseURL}/${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred while deleting data');
  }
});

// Fetch audio for a specific book by ID
export const fetchBookAudioById = createAsyncThunk(
  'books/fetchBookAudioById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/book/audio/`);
      console.log("API response data:", response.data); // Log this to confirm the data structure
      return response.data; // Ensure we're returning only `data`
    } catch (error) {
      console.error("Error fetching audio:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'Error fetching audio');
    }
  }
);



// Initial state with loading, error, and status indicators
const books = createSlice({
  name: 'crud',
  initialState: {
    items: [],
    loading: false,
    error: null,
    status: null,
    selectedBook: null,
    audio: null,             // Add field for audio data
    loadingAudio: false,     // Add field for audio loading state
    errorAudio: null,        // Add field for audio error state
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearAudioError(state) {
      state.errorAudio = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch data
      .addCase(fetchBook.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
      })
      .addCase(fetchBook.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchBook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      // Fetch single book by ID
      .addCase(getBookById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBookById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedBook = action.payload;
      })
      .addCase(getBookById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create data
      .addCase(createBook.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
      })
      .addCase(createBook.fulfilled, (state, action) => {
        state.loading = false;
        state.items.push(action.payload);
        state.status = "succeeded";
      })
      .addCase(createBook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      // Update data
      .addCase(updateBook.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
      })
      .addCase(updateBook.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.items.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) state.items[index] = action.payload;
        state.status = "succeeded";
      })
      .addCase(updateBook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      // Delete data
      .addCase(deleteBook.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
      })
      .addCase(deleteBook.fulfilled, (state, action) => {
        state.loading = false;
        state.items = state.items.filter((item) => item.id !== action.meta.arg.id);
        state.status = "succeeded";
      })
      .addCase(deleteBook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      // Fetch audio by ID
      .addCase(fetchBookAudioById.pending, (state) => {
        state.loadingAudio = true;
        state.errorAudio = null;
      })
      .addCase(fetchBookAudioById.fulfilled, (state, action) => {
        console.log("Audio data successfully fetched:", action.payload); // Log fetched data here
        state.loadingAudio = false;
        state.audio = action.payload;
        // console.log(data);
      })
      .addCase(fetchBookAudioById.rejected, (state, action) => {
        state.loadingAudio = false;
        state.errorAudio = action.payload;
      });
  },
});

// Export clearError and clearAudioError actions and reducer
export const { clearError, clearAudioError } = books.actions;
export default books.reducer;
