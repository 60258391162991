import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Alert,
} from '@mui/material';

const MostSoldBooks = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch most sold books
  const fetchMostSoldBooks = async () => {
    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/book/most-sold');
      if (response.data.success) {
        setBooks(response.data.books);
      } else {
        setError('Failed to fetch books.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMostSoldBooks();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography
        variant="h4"
        sx={{
          marginBottom: 3,
          textAlign: 'center',
          fontWeight: 'bold',
          color: '#1976d2',
        }}
      >
        Most Sold Books
      </Typography>

      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ marginBottom: 3 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && (
        <Grid container spacing={4}>
          {books.map((book) => (
            <Grid item xs={12} sm={6} md={4} key={book.id}>
              <Card
                sx={{
                  maxWidth: 345,
                  boxShadow: 5,
                  borderRadius: 2,
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={`https://bookbackend3.bruktiethiotour.com/${book.imageFilePath}`} // Replace with correct URL
                  alt={book.title}
                />
                <CardContent
                  sx={{
                    background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
                    padding: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', color: '#333' }}
                  >
                    {book.title}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1, color: '#555' }}>
                    {book.description}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#1976d2' }}>
                    Author: {book.author}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#1976d2' }}>
                    Publication Year: {book.publicationYear}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#1976d2' }}>
                    Language: {book.language}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#1976d2' }}>
                    Price: ETB{book.price}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Sold: {book.sold}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default MostSoldBooks;
