import React, { useState } from 'react';
import { Select, MenuItem, Button, CircularProgress, Grid, Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const BookByStatus = () => {
  const [status, setStatus] = useState('PENDING');
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const fetchBooksByStatus = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await axios.get(`https://bookbackend3.bruktiethiotour.com/api/book/by-status/${status}`);
      if (response.data.success) {
        setBooks(response.data.books);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Select
        value={status}
        onChange={handleStatusChange}
        variant="outlined"
        style={{ marginBottom: '20px', width: '200px' }}
      >
        {['PENDING', 'PROCESSING', 'APPROVED', 'REJECTED'].map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
      <Button variant="contained" color="primary" onClick={fetchBooksByStatus}>
        Fetch Books
      </Button>

      {loading && <Loading />}
      {error &&  <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />}

      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        {books.map((book) => (
          <Grid item xs={12} sm={6} md={4} key={book.id}>
            <Card>
              <CardContent>
                <Typography variant="h5">{book.title}</Typography>
                <Typography color="textSecondary">{book.author}</Typography>
                <Typography variant="body2">{book.description}</Typography>
                <Typography variant="subtitle1">Price: {book.price}</Typography>
                <Typography variant="subtitle1">Status: {book.status}</Typography>
                <Typography variant="caption">Publication Year: {book.publicationYear}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default BookByStatus;
