import {
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Modal,
    CircularProgress,
    Snackbar,
    Alert,
    Select,
    MenuItem,
    FormControl,
  } from "@mui/material";
  import { DataGrid, GridToolbar } from "@mui/x-data-grid";
  import { tokens } from "../../theme";
  import Header from "../../components/Header";
  import { useTheme } from "@mui/material";
  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { fetchAllBooksLoggedAuthor } from "../../redux/slices/authorSlice";
  import Loading from '../../components/loading';
  import ErrorComponent from '../../components/error';
  import { useNavigate } from "react-router-dom";
  
  const AllAuthorBooks = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
  
    const { items: books, loading, error } = useSelector((state) => state.authors);
  
    const [open, setOpen] = useState(false);
    const [selectedBook, setSelectedBook] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState("");
  
    const handleOpen = (book) => {
      setSelectedBook(book);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setSelectedBook(null);
    };
  
    const handleEdit = (book) => {
      navigate(`/edit-book/${book.id}`, { state: { bookData: book } });
    };
  
    const handleApprove = (id) => {
      navigate(`/update-status/${id}`);
    };
  
    const handleView = (book) => {
      navigate(`/book-detail/${book.id}`, { state: { bookData: book } });
    };
  
    const handleApprovedorder = (id) => {
      navigate(`/approved-book-order/${id}`);
    };
    
    const salesReport = (id) => {
      navigate(`/sales-report-bybook/${id}`);
    };
  
    const handleDelete = (id) => {
      if (window.confirm("Are you sure you want to delete this book?")) {
        // dispatch(deleteBook({ endpoint: "book/delete", id }));
        setSnackbarOpen(true);
      }
    };
  
    const handleActionChange = (bookId, action) => {
      if (action === "edit") {
        const book = books.find((b) => b.id === bookId);
        handleEdit(book);
      } else if (action === "delete") {
        handleDelete(bookId);
      }
      setSelectedAction("");
    };
  
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
  
    const columns = [
      { field: "title", headerName: "Title", flex: 1 },
      { field: "author", headerName: "Author", flex: 1 },
      { field: "publicationYear", headerName: "Publication Year", flex: 1 },
      { field: "language", headerName: "Language", flex: 1 },
      { field: "price", headerName: "Price", flex: 1 },
      { field: "pages", headerName: "Pages", flex: 1 },
      { field: "status", headerName: "Status", flex: 1 },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <FormControl variant="outlined" size="small">
            <Select
              value={selectedAction}
              onChange={(e) => {
                const action = e.target.value;
                setSelectedAction(action);
                handleActionChange(params.row.id, action);
              }}
              displayEmpty
            >

            <MenuItem value="audio">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => navigate(`/book-audio/${parseInt(params.row.id)}`)} // Convert to number here
              >
                Audio
              </Button>
            </MenuItem>

            {/* audio upload */}
            <MenuItem value="upload">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => navigate(`/upload-audio/${parseInt(params.row.id)}`)} // Convert to number here
              >
                Upload-Audio
              </Button>
            </MenuItem>

              <MenuItem value="edit">
                <Button variant="contained" color="primary" size="small" onClick={() => handleEdit(params.row)}>
                  Edit
                </Button>
              </MenuItem>
              <MenuItem value="delete">
                <Button variant="contained" color="error" size="small" onClick={() => handleDelete(params.row.id)}>
                  Delete
                </Button>
              </MenuItem>
              <MenuItem value="view">
                <Button variant="contained" color="secondary" size="small" onClick={() => handleView(params.row)}>
                  View
                </Button>
              </MenuItem>
              <MenuItem value="approvedOrders">
                <Button variant="contained" color="secondary" size="small" onClick={() => handleApprovedorder(params.row.id)}>
                  Approved Orders for book
                </Button>
              </MenuItem>
           
              <MenuItem value="salesReport">
                <Button variant="contained" color="secondary" size="small" onClick={() => salesReport(params.row.id)}>
            book sales    Report
                </Button>
              </MenuItem>
                   <MenuItem value="Review">
                   
                    <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => navigate(`/review-id/${params.row.id}`)}
              >
                this  book Reviews
              </Button>
              </MenuItem>
            </Select>
          </FormControl>
        ),
      },
    ];
  
    useEffect(() => {
      dispatch(fetchAllBooksLoggedAuthor());
    }, [dispatch]);
  
    return (
      <Box display="flex" justifyContent="center" p={3} width="100%">
        <Box maxWidth="100%" width="100%">
          <Header title="BOOKS" subtitle="List of Books" />
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
              <Loading />
            </Box>
          ) : error ? (
            <ErrorComponent
              message="Oops! Something went wrong. Please try again."
              showIcon={true}
              severity="error"
            />
          ) : (
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": { border: "none" },
                "& .MuiDataGrid-cell": { borderBottom: "none" },
                "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[100]} !important` },
              }}
            >
              <DataGrid rows={books} columns={columns} components={{ Toolbar: GridToolbar }} autoHeight />
            </Box>
          )}
  
          {/* Modal for Viewing Book Details */}
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              {selectedBook ? (
                <>
                  <Card variant="outlined" sx={{ margin: 2 }}>
                    <CardContent>
                      <Typography variant="h6" component="h2" gutterBottom>
                        Book Details
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Title:</strong> {selectedBook.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Author:</strong> {selectedBook.author}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Description:</strong> {selectedBook.description}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Publication Year:</strong> {selectedBook.publicationYear}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Language:</strong> {selectedBook.language}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Price:</strong> ${selectedBook.price}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Pages:</strong> {selectedBook.pages}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Status:</strong> {selectedBook.status}</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Button variant="contained" color="error" onClick={handleClose} sx={{ mt: 2 }}>
                    Close
                  </Button>
                </>
              ) : (
                <CircularProgress />
              )}
            </Box>
          </Modal>
  
          {/* Snackbar for Success Notification */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%", fontSize: "1.2rem" }}>
              Data deleted successfully!
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    );
  };
  
  export default AllAuthorBooks;
  