import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';

const BookApprovedOrder = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await axios.get(`https://bookbackend3.bruktiethiotour.com/api/order/for-book/${id}`);
        if (response.data.success) {
          setOrderData(response.data.orders);
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [id]);

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };

  const columns = [
    { field: "orderNumber", headerName: "Order Number", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "fullName",
      headerName: "customer name",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.orderUser?.fname || ""} ${params.row.orderUser?.lname || ""}`.trim(),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) => new Date(params.row.createdAt).toLocaleString(),
    },
    {
      field: "details",
      headerName: "Details",
      renderCell: (params) => (
        <Button 
          variant="outlined" 
          color="secondary"
          onClick={() => handleOpenModal(params.row)}
        >
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      {loading ? (
       <Loading />
      ) : (
        <DataGrid
          rows={orderData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      )}

      {selectedOrder && (
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Order Details</DialogTitle>
          <DialogContent>
            <Typography variant="h6">Order Number: {selectedOrder.orderNumber}</Typography>
            <Typography variant="body1">Price: {selectedOrder.price}</Typography>
            <Typography variant="body1">Status: {selectedOrder.status}</Typography>
            <Typography variant="body1">Created At: {new Date(selectedOrder.createdAt).toLocaleString()}</Typography>
            <Typography variant="body1">Service Charged: {selectedOrder.serviceCharged}</Typography>
            <Typography variant="body1">order  count: {selectedOrder.orderUser?.orderCount}</Typography>
            <Typography variant="body1">
              User: {selectedOrder.orderUser?.fname} {selectedOrder.orderUser?.lname}
            </Typography>
            <img 
              src={`https://bookbackend3.bruktiethiotour.com/${selectedOrder.orderUser?.imageFilePath}`} 
              alt={`${selectedOrder.orderUser?.fname}'s profile`} 
              style={{ width: '100px', height: '100px' }}
            />
            <br />
            <a 
              href={`https://bookbackend3.bruktiethiotour.com/${selectedOrder.receiptImagePath}`} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              View Receipt
            </a>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="error">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default BookApprovedOrder;
