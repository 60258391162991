import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';

const LevelComponent = () => {
  const [levels, setLevels] = useState([]); // Initialize levels as an empty array
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [editLevelId, setEditLevelId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(false);
  const adminToken = localStorage.getItem('adminToken');

  // Fetch levels from API
  const fetchLevels = async () => {
    setFetching(true);
    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/levels', {
        headers: { Authorization: `Bearer ${adminToken}` },
      });
      if (response.data.success) {
        setLevels(response.data.levels || []); // Access levels correctly
      } else {
        setSnackbarMessage('Failed to fetch levels.');
        setError(true);
        setSnackbarOpen(true);
      }
    } catch (err) {
      console.log(err);
      setSnackbarMessage('An error occurred while fetching levels.');
      setError(true);
      setSnackbarOpen(true);
    } finally {
      setFetching(false);
    }
  };

  // Handle form submit to add or update a level
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);

    try {
      let response;
      if (editLevelId) {
        response = await axios.put(
          `https://bookbackend3.bruktiethiotour.com/api/levels/${editLevelId}`,
          { name },
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );
      } else {
        response = await axios.post(
          'https://bookbackend3.bruktiethiotour.com/api/levels',
          { name },
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );
      }

      if (response.data.success) {
        setSnackbarMessage(editLevelId ? 'Level updated successfully!' : 'Level added successfully!');
        fetchLevels(); // Refresh levels
        handleCloseModal();
      } else {
        setSnackbarMessage(response.data.message || 'Failed to submit.');
        setError(true);
      }
    } catch (err) {
      console.log(err);
      setSnackbarMessage('An error occurred while submitting the level.');
      setError(true);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  // Open modal for editing
  const handleEditOpen = (level) => {
    setEditLevelId(level.id);
    setName(level.name);
    setModalOpen(true);
  };

  // Close modal and reset form
  const handleCloseModal = () => {
    setModalOpen(false);
    setEditLevelId(null);
    setName('');
  };

  // Delete a level
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this level?')) {
      try {
        const response = await axios.delete(`https://bookbackend3.bruktiethiotour.com/api/levels/${id}`, {
          headers: { Authorization: `Bearer ${adminToken}` },
        });
        if (response.data.success) {
          setSnackbarMessage('Level deleted successfully!');
          fetchLevels();
        } else {
          setSnackbarMessage(response.data.message || 'Failed to delete level.');
          setError(true);
        }
      } catch (err) {
        console.log(err);
        setSnackbarMessage('An error occurred while deleting the level.');
        setError(true);
      } finally {
        setSnackbarOpen(true);
      }
    }
  };

  // Fetch levels on component mount
  useEffect(() => {
    fetchLevels();
  }, []);

  return (
    <Box style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <Typography variant="h6" gutterBottom>Manage Levels</Typography>

      {/* Form to add/edit level */}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Level Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          style={{ marginBottom: '20px' }}
        />
        <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
          {loading ? <CircularProgress size={24} /> : editLevelId ? 'Update Level' : 'Add Level'}
        </Button>
      </form>

      <Typography variant="h6" gutterBottom style={{ marginTop: '40px' }}>
        Levels List
      </Typography>
      {fetching ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Level Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {levels.map((level) => (
                <TableRow key={level.id}>
                  <TableCell>{level.name}</TableCell>
                  <TableCell align="right">
                    <Button variant="outlined" color="secondary" onClick={() => handleEditOpen(level)}>
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleDelete(level.id)} style={{ marginLeft: '10px' }}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Modal for editing level */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            padding: '20px',
            borderRadius: '5px',
          }}
        >
          <Typography variant="h6" gutterBottom>Edit Level</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Level Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={{ marginBottom: '20px' }}
            />
            <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
              {loading ? <CircularProgress size={24} /> : 'Update Level'}
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Snackbar for feedback */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={error ? 'error' : 'success'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LevelComponent;
