import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Modal,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  Alert,
  MenuItem, Select, InputLabel, FormControl,
  TableSortLabel
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const MyAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    content: "",
    status: "",
    reason: "",
  });
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");

  const token = localStorage.getItem("adminToken");
  const navigate = useNavigate();

  // Fetch announcements data with search
  useEffect(() => {
    const fetchAnnouncements = async () => {
      setLoading(true);
      try {
        const response = await axios.get("https://bookbackend3.bruktiethiotour.com/api/announcement/my",{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAnnouncements(response.data.data);
      } catch (error) {
        setSnackMessage("Failed to fetch announcements!");
        setSnackSeverity("error");
        setSnackOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, []);

  // Sorting handler
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedAnnouncements = [...announcements].sort((a, b) => {
    if (orderBy === "title") {
      return (order === "asc" ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title));
    }
    if (orderBy === "status") {
      return (order === "asc" ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status));
    }
    return 0;
  });

  // Searching announcements
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredAnnouncements = sortedAnnouncements.filter(
    (announcement) =>
      announcement.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      announcement.content.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle input change for form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle modal open for editing announcement
  const handleEditOpen = (announcement) => {
    setFormData({
      id: announcement.id,
      title: announcement.title,
      content: announcement.content,
      status: announcement.status,
      reason: announcement.reason,
    });
    setOpenModal(true);
  };

  // Handle modal close
  const handleModalClose = () => {
    setOpenModal(false);
  };

  // Handle form submission for editing announcement
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put(
        `https://bookbackend3.bruktiethiotour.com/api/announcement/update/${formData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackMessage("Announcement updated successfully!");
      setSnackSeverity("success");
      setSnackOpen(true);
      setOpenModal(false);
      setFormData({ id: "", title: "", content: "", status: "", reason: "" });
      // Fetch updated data
      const updatedResponse = await axios.get("https://bookbackend3.bruktiethiotour.com/api/announcement/");
      setAnnouncements(updatedResponse.data.data);
    } catch (error) {
      setSnackMessage("Failed to update announcement!");
      setSnackSeverity("error");
      setSnackOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle delete announcement
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`https://bookbackend3.bruktiethiotour.com/api/announcement/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackMessage("Announcement deleted successfully!");
      setSnackSeverity("success");
      setSnackOpen(true);
      // Fetch updated data
      const updatedResponse = await axios.get("https://bookbackend3.bruktiethiotour.com/api/announcement/");
      setAnnouncements(updatedResponse.data.data);
    } catch (error) {
      setSnackMessage("Failed to delete announcement!");
      setSnackSeverity("error");
      setSnackOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle "View Comments" button click to navigate to comments page
  const handleViewComments = (announcementId) => {
    navigate(`/comments/${announcementId}`);
  };

  return (
    <div>
      <Typography variant="h5" mb={2}>
        Announcements
      </Typography>

      {/* Search Input */}
      <TextField
        label="Search Announcements"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={orderBy === "title" ? order : "asc"}
                  onClick={(e) => handleRequestSort(e, "title")}
                >
                  Title
                </TableSortLabel>
              </TableCell>
              <TableCell>Content</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "status"}
                  direction={orderBy === "status" ? order : "asc"}
                  onClick={(e) => handleRequestSort(e, "status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Likes</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAnnouncements
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((announcement) => (
                <TableRow key={announcement.id}>
                  <TableCell>{announcement.title}</TableCell>
                  <TableCell>{announcement.content}</TableCell>
                  <TableCell>{announcement.status}</TableCell>
                  <TableCell>{announcement.reason}</TableCell>
                  <TableCell>{announcement.likesCount}</TableCell>
                  <TableCell>{announcement.commentsCount}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditOpen(announcement)}>
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(announcement.id)}
                    >
                      <Delete />
                    </IconButton>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleViewComments(announcement.id)}
                    >
                      View Comments
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredAnnouncements.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Modal for editing announcements */}
      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">Edit Announcement</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Content"
              name="content"
              value={formData.content}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleChange}
                label="Status"
              >
                <MenuItem value="approved">Approved</MenuItem>
    <MenuItem value="pending">Pending</MenuItem>
    <MenuItem value="suspended">Suspended</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Reason"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleModalClose}
                sx={{ ml: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      {/* Snackbar */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MyAnnouncements;
