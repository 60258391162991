import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import { createBook } from "../../redux/slices/bookSlice";
import CustomModal from '../../components/modal'; // Adjust the import path as necessary

import axios from "axios";
const RegisterBookForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(""); // State for error handling

  const [modalMessage, setModalMessage] = useState('');
  const [categories, setCategories] = useState([]);
  const [modalSeverity, setModalSeverity] = useState('success');
  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("author", values.author);
      formData.append("price", values.price);
      formData.append("pages", values.pages);
      formData.append("description", values.description);
      formData.append("publicationYear", values.publicationYear);
      formData.append("language", values.language);
      formData.append("category_id", values.category_id);
  
      // Attach files
      if (values.pdf) formData.append("pdf", values.pdf);
      if (values.image) formData.append("pdfImage", values.image);
      values.audio.forEach((audioFile) => formData.append("audio", audioFile));
  
      const resultAction = await dispatch(createBook({ endpoint: 'book/create', data: formData }));
  
      if (createBook.fulfilled.match(resultAction)) {
        setModalMessage('Book created successfully!');
        setModalSeverity('success');
      } else {
        const errorMessage = resultAction.payload || 'Failed to create book!';
        setModalMessage(errorMessage);
        setModalSeverity('error');
      }
    } catch (error) {
      setModalMessage('An error occurred: ' + error.message);
      setModalSeverity('error');
    } finally {
      setModalOpen(true);
      setSubmitting(false);
    }
  };
  
 // Fetch categories from the API
 useEffect(() => {
  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://bookbackend3.bruktiethiotour.com/api/category/get-all");
      if (response.data.success) {
        setCategories(response.data.data); // Set fetched categories to state
      }
    } catch (err) {
      setError("Failed to fetch categories");
    } finally {
      setLoading(false);
    }
  };

  fetchCategories();
}, []);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <Box display="grid" gap="30px" gridTemplateColumns="repeat(1, 1fr)">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Author"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.author}
                name="author"
                error={!!touched.author && !!errors.author}
                helperText={touched.author && errors.author}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                name="price"
                error={!!touched.price && !!errors.price}
                helperText={touched.price && errors.price}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Pages"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.pages}
                name="pages"
                error={!!touched.pages && !!errors.pages}
                helperText={touched.pages && errors.pages}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Publication Year"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.publicationYear}
                name="publicationYear"
                error={!!touched.publicationYear && !!errors.publicationYear}
                helperText={touched.publicationYear && errors.publicationYear}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Language"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.language}
                name="language"
                error={!!touched.language && !!errors.language}
                helperText={touched.language && errors.language}
              />
              <FormControl fullWidth variant="filled">
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                          labelId="category-label"
                          id="category"
                          name="category_id"
                          value={values.category_id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.category_id && !!errors.category_id}
                        >
                          {/* If loading, show a loading option */}
                          {loading && <MenuItem value="">Loading...</MenuItem>}

                          {/* If error, show error message */}
                          {error && <MenuItem value="">Error fetching categories</MenuItem>}

                          {/* Map through categories and display as MenuItems */}
                          {!loading && !error && categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.category_id && errors.category_id && (
                          <div style={{ color: "red" }}>{errors.category_id}</div>
                        )}
                      </FormControl>
              <input
                accept="image/*"
                type="file"
                onChange={(event) => setFieldValue("image", event.currentTarget.files[0])}
                required
              />
              <input
                accept="application/pdf"
                type="file"
                onChange={(event) => setFieldValue("pdf", event.currentTarget.files[0])}
                required
              />
              <input
                accept="audio/*"
                type="file"
                multiple
                onChange={(event) => setFieldValue("audio", Array.from(event.currentTarget.files))}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" fullWidth disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Add Book'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {/* Custom Modal for alerts */}
      <CustomModal
        open={modalOpen}
        onClose={handleCloseModal}
        severity={modalSeverity}
        message={modalMessage}
      />
    </Box>
  );
};
const checkoutSchema = yup.object().shape({
  title: yup.string().required("Required"),
  author: yup.string().required("Required"),
  price: yup.number().required("Required").positive("Must be positive"),
  pages: yup
  .number()
  .required("Required") // Field is required
  .positive("Must be positive") // Must be a positive number
  .min(5, "Must be at least 5 pages"), // Minimum value is 5
  description: yup.string().required("Required").min(15, "Must be at least 15 characters"),
  publicationYear: yup.string().required("Required"),
  language: yup.string().required("Required"),
  category_id: yup.number().required("Required"),
  image: yup.mixed().required("Image file is required").test(
    "fileSize",
    "File too large (max 5MB)",
    (value) => value && value.size <= 5242880 // 5MB
  ),
  pdf: yup.mixed().required("PDF file is required").test(
    "fileSize",
    "File too large (max 10MB)",
    (value) => value && value.size <= 10485760 // 10MB
  ),
  audio: yup.array().of(yup.mixed().test(
    "fileSize",
    "File too large (max 5MB per audio file)",
    (value) => value && value.size <= 5242880 // 5MB
  )),
});

// Initial values for the form fields
const initialValues = {
  title: "",
  author: "",
  price: "",
  pages: "",
  description: "",
  publicationYear: "",
  language: "",
  category_id: "", // Add category_id initial value
  image: null,
  pdf: null,
  audio: [],
};

export default RegisterBookForm;
