// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import users from './slices/userSlice';
import books from './slices/bookSlice';
import authors from './slices/authorSlice'
import audioSlice from './slices/audioSlice'
const store = configureStore({
  reducer: {
    users: users,
    books: books,
    authors: authors,
    audio: audioSlice,
  },
});

export default store;
