import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
  IconButton,
  Button,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const BookReviews = () => {
  const { booksId } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          `https://bookbackend3.bruktiethiotour.com/api/review/by-bookid/${booksId}`
        );
        setReviews(response.data.reviews);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchReviews();
  }, [booksId]);

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedReview(null);
    setOpen(false);
  };

  const columns = [
    { field: "reviewUser", headerName: "Reviewer", flex: 1, valueGetter: (params) => `${params.row.reviewUser.fname} ${params.row.reviewUser.lname}` },
    { field: "comment", headerName: "Comment", flex: 2 },
    { field: "reviewRating", headerName: "Rating", flex: 1 },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) => new Date(params.row.createdAt).toLocaleString(),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => handleOpen(params.row)}
        >
          View
        </Button>
      ),
    },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" mb={2}>
        Reviews Table
      </Typography>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
          <CircularProgress />
        </Box>
      )}
  
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && (
        <TableContainer sx={{ backgroundColor: '#141B2D', marginTop: 10}} component={Paper} elevation={3}>
          <Table>
            <TableHead sx={{ backgroundColor: '#23292EFF' }}>
              <TableRow>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>#</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Comment</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Rating</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Book Title</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Author</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Reviewer</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Status</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviews.map((review, index) => (
                <TableRow key={review.id} hover>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{review.comment}</TableCell>
                  <TableCell>{review.reviewRating}</TableCell>
                  <TableCell>{review.reviewBook.title}</TableCell>
                  <TableCell>{review.reviewBook.author}</TableCell>
                  <TableCell>{review.reviewUser.fname}</TableCell>                  
                  <TableCell>{review.status}</TableCell>
                  <TableCell>{review.reviewUser.email}</TableCell>
                  <MenuItem value="Review">
                   
                   <Button
               variant="contained"
               color="secondary"
               size="small"
               onClick={() => navigate(`/review-feedback/${review.id}`)}
             >
               Answer for review
             </Button>
             </MenuItem>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default BookReviews;
