import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const ReviewsAll = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch reviews from the API
  const fetchReviews = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/review/get-all');
      if (response.data.success) {
        setReviews(response.data.reviews);
      } else {
        setError('Failed to fetch reviews');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while fetching reviews');
    } finally {
      setLoading(false);
    }
  };

  // Update review status
  const updateStatus = async (id, newStatus) => {
    const token = localStorage.getItem("adminToken");
    if (!token) {
      alert('No token found. Please log in again.');
      return;
    }
  
    try {
      await axios.put(
        `https://bookbackend3.bruktiethiotour.com/api/review/update-status/${id}`,
        { status: newStatus }, // Pass the new status in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Authorization header
          },
        }
      );
      // Update the local state after a successful response
      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review.id === id ? { ...review, status: newStatus } : review
        )
      );
    } catch (err) {
      console.error('Error updating status:', err);
      alert('Failed to update status. Please try again.');
    }
  };
  

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" mb={2}>
        Reviews Table
      </Typography>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
          <CircularProgress />
        </Box>
      )}
  
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && (
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead sx={{ backgroundColor: '#23292EFF' }}>
              <TableRow>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>#</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Comment</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Rating</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Book Title</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Author</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Reviewer</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Status</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviews.map((review, index) => (
                <TableRow key={review.id} hover>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{review.comment}</TableCell>
                  <TableCell>{review.reviewRating}</TableCell>
                  <TableCell>{review.reviewBook.title}</TableCell>
                  <TableCell>{review.reviewBook.author}</TableCell>
                  <TableCell>{review.reviewUser.fname}</TableCell>
                  <TableCell>
                    <Select
                      value={review.status || ''}
                      onChange={(e) => updateStatus(review.id, e.target.value)}
                      size="small"
                      sx={{ width: '100%' }}
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="approved">Approved</MenuItem>
                      <MenuItem value="rejected">Rejected</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>{review.reviewUser.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ReviewsAll;
