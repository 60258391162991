import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';

const Last7DaysSales = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('adminToken');
      try {
        const response = await axios.get(
          'https://bookbackend3.bruktiethiotour.com/api/sales/books/reports/last7days',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setData(response.data);
        } else {
          setError('No data found or response format is incorrect.');
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div>
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Sales Report for the Last 7 Days
      </Typography>

      {/* Display total books sold */}
      <Typography variant="h5" gutterBottom>
        Total Books Sold
      </Typography>
      {/* <TableContainer sx={{ backgroundColor: '#141B2D', marginTop: 10}}  component={Paper} style={{ marginBottom: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Book </TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.count.map((item) => (
              <TableRow key={item.book_id}>
                <TableCell>{item.orderBook?.title}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}

      {/* Display best selling books */}
      <Typography variant="h5" gutterBottom>
        Best Selling Books
      </Typography>
      <TableContainer sx={{ backgroundColor: '#141B2D', marginTop: 10}} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
       
              <TableCell>Title</TableCell>
              <TableCell>Total Sold</TableCell>
              <TableCell>Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.bestSellingBooks.map((item) => (
              <TableRow key={item.book_id}>

                <TableCell>{item.orderBook?.title || 'N/A'}</TableCell>
                <TableCell>{item.totalSold}</TableCell>
                <TableCell>{item.totalprice}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Last7DaysSales;
