import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";

const SalesReferralOrder = () => {
  const { id } = useParams(); // Extract referralCode from URL params
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://bookbackend3.bruktiethiotour.com/api/order/sales-referal-order",
          { referralCode:"user-ghghguu-1730971167157" }
        );
        setOrders(response.data.orders);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch orders.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [id]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Sales Referral Orders
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography variant="h6" color="error" align="center">
          {error}
        </Typography>
      ) : orders.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Order Number</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Bank Name</TableCell>
                <TableCell align="center">Transaction Number</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Reviewed By</TableCell>
                <TableCell align="center">Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell align="center">{order.orderNumber}</TableCell>
                  <TableCell align="center">ETB {order.price}</TableCell>
                  <TableCell align="center">{order.bankName}</TableCell>
                  <TableCell align="center">{order.transactionNumber}</TableCell>
                  <TableCell align="center">{order.type}</TableCell>
                  <TableCell align="center">{order.status}</TableCell>
                  <TableCell align="center">{order.reviewedBy}</TableCell>
                  <TableCell align="center">
                    {new Date(order.createdAt).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" align="center" color="textSecondary">
          No orders found for this referral code.
        </Typography>
      )}
    </Container>
  );
};

export default SalesReferralOrder;
