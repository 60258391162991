import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import PrintIcon from '@mui/icons-material/Print';
import GetAppIcon from '@mui/icons-material/GetApp';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const BASE_URL = 'https://bookbackend3.bruktiethiotour.com/api/sales/books/reports/logged-author';

const MyBookReport = () => {
  const [bookReport, setBookReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');

  useEffect(() => {
    const fetchReport = async () => {
      setLoading(true);
      setError(null);
      const userToken = localStorage.getItem('userToken');
      try {
        const response = await axios.get(BASE_URL, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setBookReport(response.data);
      } catch (err) {
        setError('Failed to fetch the report.');
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, []);

  // Sorting function
  const handleSort = (property) => {
    const isAsc = orderBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Filter data based on search query
  const filteredData = bookReport.flatMap((item) =>
    item.book.map((book) => ({
      id: item.id,
      fname: item.fname,
      title: book.title,
      author: book.author,
      orders: book.order,
    }))
  ).filter((book) =>
    book.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    book.author.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort data based on selected column
  const sortedData = filteredData.sort((a, b) => {
    const isAsc = sortDirection === 'asc';
    if (a[orderBy] < b[orderBy]) {
      return isAsc ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return isAsc ? 1 : -1;
    }
    return 0;
  });

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Export to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(sortedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'BookReport');
    XLSX.writeFile(workbook, 'BookReport.xlsx');
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ['Title', 'Author', 'Price', 'Total Sales', 'Total Sold Count'];
    const tableRows = sortedData.map((book) => [
      book.title || 'N/A',
      book.author || 'N/A',
      book.orders.reduce((total, order) => total + parseFloat(order.price), 0) || 'N/A',
      book.orders.reduce((total, order) => total + parseFloat(order.totalSales), 0) || 'N/A',
      book.orders.reduce((total, order) => total + order.bookSoldCount, 0) || 'N/A',
    ]);

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text('Book Report', 14, 15);
    doc.save('BookReport.pdf');
  };

  // Print table
  const printTable = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Book Report</title>');
    printWindow.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />');
    printWindow.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css" />');
    printWindow.document.write('</head><body>');
    printWindow.document.write('<h1 style="text-align: center;">Book Report</h1>');
    printWindow.document.write('<table style="width: 100%; border-collapse: collapse;">');
    printWindow.document.write('<thead><tr><th>Title</th><th>Author</th><th>Price</th><th>Total Sales</th><th>Total Sold Count</th></tr></thead><tbody>');
    
    sortedData.forEach((book) => {
      printWindow.document.write('<tr>');
      printWindow.document.write(`<td>${book.title || 'N/A'}</td>`);
      printWindow.document.write(`<td>${book.author || 'N/A'}</td>`);
      printWindow.document.write(`<td>${book.orders.reduce((total, order) => total + parseFloat(order.price), 0) || 'N/A'}</td>`);
      printWindow.document.write(`<td>${book.orders.reduce((total, order) => total + parseFloat(order.totalSales), 0) || 'N/A'}</td>`);
      printWindow.document.write(`<td>${book.orders.reduce((total, order) => total + order.bookSoldCount, 0) || 'N/A'}</td>`);
      printWindow.document.write('</tr>');
    });

    printWindow.document.write('</tbody></table></body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto', mt: 4, p: 3, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h5" gutterBottom>My Book Report</Typography>

      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
      ) : (
        <>
          <TextField
            variant="outlined"
            placeholder="Search by Title or Author"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />

          <Box sx={{ mb: 2 }}>
            <Button variant="contained" onClick={exportToExcel} startIcon={<GetAppIcon />} sx={{ mr: 2 }}>
              Export to Excel
            </Button>
            <Button variant="contained" onClick={exportToPDF} startIcon={<GetAppIcon />} sx={{ mr: 2 }}>
              Export to PDF
            </Button>
            <Button variant="contained" onClick={printTable} startIcon={<PrintIcon />}>
              Print
            </Button>
          </Box>

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'title'}
                      direction={orderBy === 'title' ? sortDirection : 'asc'}
                      onClick={() => handleSort('title')}
                    >
                      Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'author'}
                      direction={orderBy === 'author' ? sortDirection : 'asc'}
                      onClick={() => handleSort('author')}
                    >
                      Author
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Total Sales</TableCell>
                  <TableCell>Total Sold Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((book) => (
                  <TableRow key={book.id}>
                    <TableCell>{book.title || 'N/A'}</TableCell>
                    <TableCell>{book.author || 'N/A'}</TableCell>
                    <TableCell>
                      {book.orders.reduce((total, order) => total + parseFloat(order.price), 0) || 'N/A'}
                    </TableCell>
                    <TableCell>
                      {book.orders.reduce((total, order) => total + parseFloat(order.totalSales), 0) || 'N/A'}
                    </TableCell>
                    <TableCell>
                      {book.orders.reduce((total, order) => total + order.bookSoldCount, 0) || 'N/A'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default MyBookReport;
