import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const QualityGuidelines = () => {
  const [genre, setGenre] = useState('');
  const [guidelines, setGuidelines] = useState([]);
  const [guidelineList, setGuidelineList] = useState([]);
  const [editingGenre, setEditingGenre] = useState('');
  const [editingGuidelines, setEditingGuidelines] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    axios
      .get('https://bookbackend3.bruktiethiotour.com/api/quality-guidelines')
      .then((response) => {
        const data = response.data.map((item) => ({
          ...item,
          guidelines: JSON.parse(item.guidelines), // Parse the guidelines string into an array
        }));
        setGuidelineList(data);
      })
      .catch((error) => {
        console.error('Error fetching quality guidelines', error);
      });
  }, []);

  // Handle adding a new guideline
  const handleAddGuideline = () => {
    if (!genre || guidelines.length === 0) {
      setError('Please fill out both genre and guidelines');
      return;
    }

    const newGuideline = {
      genre,
      guidelines,
    };

    axios
      .post('https://bookbackend3.bruktiethiotour.com/api/quality-guidelines', newGuideline)
      .then((response) => {
        setGuidelineList([...guidelineList, response.data]);
        setGenre('');
        setGuidelines([]);
        setError('');
      })
      .catch((err) => {
        setError('Failed to add guideline');
        console.error(err);
      });
  };

  // Handle deleting a guideline
  const handleDelete = (id) => {
    axios
      .delete(`https://bookbackend3.bruktiethiotour.com/api/quality-guidelines/${id}`)
      .then(() => {
        setGuidelineList(guidelineList.filter((item) => item.id !== id));
      })
      .catch((err) => {
        setError('Failed to delete guideline');
        console.error(err);
      });
  };

  // Handle editing a guideline
  const handleEdit = (genre, guidelines) => {
    setEditingGenre(genre);
    setEditingGuidelines(guidelines);
  };

  // Handle saving edited guideline
  const handleSaveEdit = () => {
    if (!editingGenre || editingGuidelines.length === 0) {
      setError('Please fill out both genre and guidelines');
      return;
    }

    axios
      .put(`https://bookbackend3.bruktiethiotour.com/api/quality-guidelines/${editingGenre}`, {
        guidelines: editingGuidelines,
      })
      .then((response) => {
        setGuidelineList(
          guidelineList.map((item) =>
            item.genre === editingGenre ? response.data : item
          )
        );
        setEditingGenre('');
        setEditingGuidelines([]);
        setError('');
      })
      .catch((err) => {
        setError('Failed to save edits');
        console.error(err);
      });
  };

  return (
    <div>
      <h1>Quality Guidelines</h1>

      {/* Form to add a new quality guideline */}
      <div>
        <TextField
          label="Genre"
          variant="outlined"
          value={genre}
          onChange={(e) => setGenre(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Enter guidelines (comma-separated)"
          variant="outlined"
          value={guidelines.join(', ')}
          onChange={(e) => setGuidelines(e.target.value.split(', '))}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleAddGuideline}>
          Add Quality Guideline
        </Button>
      </div>

      {/* Error message */}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Displaying the fetched guidelines in a table */}
      <h2>Existing Quality Guidelines</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Genre</TableCell>
              <TableCell>Guidelines</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {guidelineList.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.genre}</TableCell>
                <TableCell>
                  {item.guidelines.map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleEdit(item.genre, item.guidelines)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(item.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit form */}
      {editingGenre && (
        <div>
          <h3>Edit {editingGenre} Guidelines</h3>
          <TextField
            value={editingGuidelines.join(', ')}
            onChange={(e) => setEditingGuidelines(e.target.value.split(', '))}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleSaveEdit}>
            Save Edits
          </Button>
        </div>
      )}
    </div>
  );
};

export default QualityGuidelines;
