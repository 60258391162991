import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  TextField, Button, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Typography, TableSortLabel, TablePagination, 
  InputAdornment 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const ReportByBookAndDate = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [bookId, setBookId] = useState('');
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const [books, setBooks] = useState([]);
  const [selectedBookId, setSelectedBookId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const token = localStorage.getItem('adminToken');
    if (!token) {
      setError('Admin token is missing!');
      return;
    }

    const payload = { startDate, endDate, book_id: bookId };

    try {
      const response = await axios.post('https://bookbackend3.bruktiethiotour.com/api/sales/books/report/date-and-book', payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders(response.data.orders);
    } catch (err) {
      setError('Failed to fetch data. Please try again.');
      console.error(err);
    }
  };

  // Fetch books on mount
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/book/get-all');
        setBooks(response.data);
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };
    fetchBooks();
  }, []);

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Sort orders based on orderBy and order
  const sortedOrders = [...orders].sort((a, b) => {
    if (orderBy === 'createdAt') {
      return (new Date(a.createdAt) - new Date(b.createdAt)) * (order === 'asc' ? 1 : -1);
    }
    return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === 'asc' ? 1 : -1);
  });

  // Filter orders by search query
  const filteredOrders = sortedOrders.filter(order => 
    order.orderNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.orderBook.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.orderBook.author.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Report by Book and Date
      </Typography>

      {/* Form for filtering by date and book */}
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <label htmlFor="book-select">Select a Book:</label>
        <select
          id="book-select"
          value={bookId}
          onChange={(e) => setBookId(e.target.value)}
        >
          <option value="">--Select a Book--</option>
          {books.map((book) => (
            <option key={book.id} value={book.id}>
              {book.title}
            </option>
          ))}
        </select>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Get Report
        </Button>
      </form>

      {/* Search bar */}
      <TextField
        label="Search"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{ marginBottom: '20px' }}
      />

      {/* Display errors if any */}
      {error && (
        <Typography variant="body1" color="error" gutterBottom>
          {error}
        </Typography>
      )}

      {/* Display the table if orders are available */}
      {filteredOrders.length > 0 && (
        <>
          <TableContainer  sx={{ backgroundColor: '#141B2D', marginTop: 10}} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'orderNumber'}
                      direction={orderBy === 'orderNumber' ? order : 'asc'}
                      onClick={() => handleRequestSort('orderNumber')}
                    >
                      Order Number
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'orderBook.title'}
                      direction={orderBy === 'orderBook.title' ? order : 'asc'}
                      onClick={() => handleRequestSort('orderBook.title')}
                    >
                      Book Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Transaction Number</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'createdAt'}
                      direction={orderBy === 'createdAt' ? order : 'asc'}
                      onClick={() => handleRequestSort('createdAt')}
                    >
                      Created At
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.orderNumber}</TableCell>
                      <TableCell>{order.price}</TableCell>
                      <TableCell>{order.status}</TableCell>
                      <TableCell>{order.orderBook.title}</TableCell>
                      <TableCell>{order.orderBook.author}</TableCell>
                      <TableCell>{order.transactionNumber}</TableCell>
                      <TableCell>{new Date(order.createdAt).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredOrders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      {/* If no data available */}
      {filteredOrders.length === 0 && !error && (
        <Typography variant="body1">No orders found for the selected filters.</Typography>
      )}
    </div>
  );
};

export default ReportByBookAndDate;
