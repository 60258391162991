import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../theme";
import AnnouncementIcon from '@mui/icons-material/Announcement'; // New icon for Manage Announcement
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import BookIcon from '@mui/icons-material/Book'; // Icon for Manage Books
import AddBoxIcon from '@mui/icons-material/AddBox'; // Icon for Add Book
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import "react-pro-sidebar/dist/css/styles.css";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'; // Import the money icon
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1"; // For 'Add User'
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"; // For 'Manage Users'
import MessageIcon from "@mui/icons-material/Message"; // For 'View Message'
import AddCommentIcon from "@mui/icons-material/AddComment"; // For 'Add Message'
import ViewListIcon from '@mui/icons-material/ViewList'; // New icon for Manage Books
import SearchIcon from '@mui/icons-material/Search'; // New icon for Filter Books
import StarIcon from '@mui/icons-material/Star'; // New icon for Most Reviewed
import TrendingUpIcon from '@mui/icons-material/TrendingUp'; // New icon for Top Rated
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import TodayIcon from '@mui/icons-material/Today';
import HistoryIcon from '@mui/icons-material/History';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DescriptionIcon from '@mui/icons-material/Description';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import AudioFileIcon from '@mui/icons-material/AudioFile';



const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        width: isCollapsed ? "80px" : "270px",  // Set a wider default width
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  ADMINIS
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={``}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  ET Book
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Dr Monor
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              </Typography>

            <SubMenu title="User Management" icon={<PeopleOutlinedIcon />}>
              <MenuItem icon={<ManageAccountsIcon />}>
                Manage Users
                <Link to="/users" />
              </MenuItem>
              <MenuItem icon={<PersonAddAlt1Icon />}>
                Add User
                <Link to="/add-user" />
              </MenuItem>
              <MenuItem icon={<ManageAccountsIcon />}>
                Authors
                <Link to="/authors" />
              </MenuItem>
              <MenuItem icon={<ManageAccountsIcon />}>
                Verified Users
                <Link to="/verified-users" />
              </MenuItem>
            </SubMenu>

            {/* Manage Books */}
            <SubMenu title="Book Management" icon={<ViewListIcon />}>
              <MenuItem icon={<BookIcon />}>
                Manage Books
                <Link to="/books" />
              </MenuItem>
              <MenuItem icon={<AddBoxIcon />}>
                Add Book
                <Link to="/add-book" />
              </MenuItem>
              <MenuItem icon={<AddBoxIcon />}>
               All book reviews
                <Link to="/review-all" />
              </MenuItem>
              <MenuItem icon={<SearchIcon />}>
                Filter Books
                <Link to="/book-filter" />
              </MenuItem>
              <MenuItem icon={<TrendingUpIcon />}>
                Most Sold Books
                <Link to="/book-sold" />
              </MenuItem>
              <MenuItem icon={<StarIcon />}>
                Most Reviewed
                <Link to="/most-reviewed" />
              </MenuItem>
              <MenuItem icon={<StarIcon />}>
                Top Rated Books
                <Link to="/top-rated" />
              </MenuItem>
              <MenuItem icon={<BookIcon />}>
                Today Books
                <Link to="/today-book" />
              </MenuItem>
              <MenuItem icon={<BookIcon />}>
                Last 7 Day Books
                <Link to="/last7days-books" />
              </MenuItem>
              <MenuItem icon={<BookIcon />}>
                Books by Status
                <Link to="/books-by-status" />
              </MenuItem>

              <MenuItem icon={<AudioFileIcon />}>
                Audio Play
                <Link to="/audio" />
              </MenuItem>
            
            </SubMenu>


            {/* Manage Authors */}
            <SubMenu title="Book Author" icon={<ManageAccountsIcon />}>
              <MenuItem icon={<BookIcon />}>
                Books bystatus
                <Link to="/logged-author-bystatus" />
              </MenuItem>
              
              <MenuItem icon={<ManageAccountsIcon />}>
                All Books
                <Link to="/allbooks-logged-author" />
              </MenuItem>

              <MenuItem icon={<SearchIcon />}>
                Filter Books
                <Link to="/all-approved-books" />
              </MenuItem>
            </SubMenu>


              {/* communication */}
            <h2>communication</h2>
              <MenuItem icon={<BookIcon />}>
                 communication
                <Link to="/communication" />
              </MenuItem>
              
              <MenuItem icon={<ManageAccountsIcon />}>
                view messages
                <Link to="/get-message" />
              </MenuItem>
              <MenuItem icon={<BookIcon />}>
                Not agreed
                <Link to="/notagreed" />
              </MenuItem>
              <MenuItem icon={<BookIcon />}>
               create announcement
                <Link to="/create-announcement" />
              </MenuItem>
              <MenuItem icon={<BookIcon />}>
             announcements
                <Link to="/announcement" />
              </MenuItem>
              <MenuItem icon={<BookIcon />}>
               my announcement
                <Link to="/my-announcement" />
              </MenuItem>


<SubMenu title="Subscription" icon={<SubscriptionsIcon />}>
  
  <MenuItem icon={<ReceiptOutlinedIcon />}>
    Subscription Orders
    <Link to="/subscription-order" />
  </MenuItem>

  <MenuItem icon={<ListAltIcon />}>
Add subscription triers
    <Link to="/subscription-triers" />
  </MenuItem>
  <MenuItem icon={<ListAltIcon />}>
Mange  subscription triers
    <Link to="/manage_subscription-triers" />
  </MenuItem>
  <MenuItem icon={<ListAltIcon />}>
user subscriptions
    <Link to="/user_subscriptions" />
  </MenuItem>
</SubMenu>

<SubMenu title="Order Management" icon={<ShoppingCartOutlinedIcon />}>
  <MenuItem icon={<ListAltIcon />}>
    Orders
    <Link to="/orders" />
  </MenuItem>
  <MenuItem icon={<FilterListIcon />}>
    Order by Status
    <Link to="/orders-by-status" />
  </MenuItem>
  <MenuItem icon={<TodayIcon />}>
    Today Orders
    <Link to="/today-orders" />
  </MenuItem>
  <MenuItem icon={<HistoryIcon />}>
    Last 7 Days Orders
    <Link to="/last7days-orders" />
  </MenuItem>
  <MenuItem icon={<SearchIcon />}>
    Search Orders
    <Link to="/search-orders" />
  </MenuItem>
</SubMenu>

<SubMenu title="Report Management" icon={<BarChartOutlinedIcon />}>
  <MenuItem icon={<AssessmentIcon />}>
    Reports
    <Link to="/sales-report-bybook" />
  </MenuItem>
  <MenuItem icon={<DescriptionIcon />}>
    My Books Report
    <Link to="/my-books-report" />
  </MenuItem>
  <MenuItem icon={<AttachMoneyOutlinedIcon />}>
    Top Book Sales
    <Link to="/top-sales" />
  </MenuItem>
  <MenuItem icon={<StarOutlineIcon />}>
    Top Sellers Report
    <Link to="/top-sellers" />
  </MenuItem>
  <MenuItem icon={<PersonOutlinedIcon />}>
    Top Buyers
    <Link to="/top-buyers" />
  </MenuItem>
  <MenuItem icon={<TodayIcon />}>
    Last 7 Days Sales
    <Link to="/last-7days-sales" />
  </MenuItem>
  <MenuItem icon={<FolderSpecialIcon />}>
    Sold Books Report
    <Link to="/sold-report" />
  </MenuItem>
  <MenuItem icon={<StarOutlineIcon />}>
   report by book and date
    <Link to="/by-book-date/" />
  </MenuItem>
  <MenuItem icon={<StarOutlineIcon />}>
  subscription revenue
    <Link to="/subscription-revenu-report/" />
  </MenuItem>
</SubMenu>
{/* setting */}
<SubMenu title="Settings" icon={<SettingsOutlinedIcon />}>
  <MenuItem icon={<AddBoxIcon />}>
    Add Category
    <Link to="/add-category" />
  </MenuItem>
  <MenuItem icon={<ListAltIcon />}>
    Level
    <Link to="/level" />
  </MenuItem>
  <MenuItem icon={<ListAltIcon />}>
    Account
    <Link to="/account" />
  </MenuItem>
  <MenuItem icon={<ListAltIcon />}>
  Add permission
    <Link to="/add-role" />
  </MenuItem>
  <MenuItem icon={<ListAltIcon />}>
   manage permissions
    <Link to="/manage-role" />
  </MenuItem>
  <MenuItem icon={<ListAltIcon />}>
  Quality Guideline
    <Link to="/qaulity-guidelines" />
  </MenuItem>
</SubMenu>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
