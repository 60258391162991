import {
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Modal,
    CircularProgress,
    Snackbar,
    Alert,
  } from "@mui/material";
  import { DataGrid, GridToolbar } from "@mui/x-data-grid";
  
  import { tokens } from "../../theme";
  import Header from "../../components/Header";
  import { useTheme } from "@mui/material";
  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { fetchUser, deleteUser } from "../../redux/slices/userSlice"; // Adjust the import path accordingly
  import Loading from '../../components/loading';
  import ErrorComponent from '../../components/error';
  import { useNavigate } from "react-router-dom";
  
  const Users = () => { 
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
  
    // Access the Redux state
    const { items: users, loading, error } = useSelector((state) => state.users);
  
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // For Snackbar control
  
    const handleOpen = (user) => {
      setSelectedUser(user);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setSelectedUser(null);
    };
  
    const handleEdit = (user) => {
        navigate(`/edit-user/${user.id}`, { state: { userData: user } });
      };
      
  
    const handleDelete = (id) => {
      if (window.confirm("Are you sure you want to delete this user?")) {
        dispatch(deleteUser({ endpoint: "admin/admins", id })); // Pass an object with endpoint and id
        setSnackbarOpen(true); // Open the Snackbar after deletion
      }
    };
  
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
  
    const columns = [
   
      { field: "email", headerName: "Email" },
      { field: "fname", headerName: "First Name", flex: 1 },
      { field: "lname", headerName: "Last Name", flex: 1 },
      { field: "role", headerName: "Role", flex: 1 },
      { field: "referalCode", headerName: "referal code", flex: 1 },
      { field: "userinvited", headerName: "invited by ", flex: 1 },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
            <Box display="flex" gap={1}>
            <Button variant="contained" color="primary" size="small" onClick={() => handleEdit(params.row)}>
              Edit
            </Button>
            <Button variant="contained" color="error" size="small" onClick={() => handleDelete(params.row.id)}>
              Delete
            </Button>
            <Button variant="contained" color="secondary" size="small" onClick={() => handleOpen(params.row)}>
              View
            </Button>
            <Button variant="contained" color="secondary" size="small"   onClick={() => navigate(`/sales-referal-order/${params.row.referalCode}`)}>
             Sales
            </Button>
          </Box>
          
        ),
      },
    ];
  
    useEffect(() => {
      // Dispatch the fetchUser action when the component mounts
      dispatch(fetchUser("admin/get-all-admins"));
    }, [dispatch]);
  
    return (
      <Box display="flex" justifyContent="center" p={3} width="100%">
        <Box maxWidth="100%" width="100%"> {/* Setting maxWidth for responsive layout */}
          <Header title="USERS" subtitle="List of Registered Users" />
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
              <Loading />
            </Box>
          ) : error ? (
            <ErrorComponent
            message="Oops! Something went wrong. Please try again."
            showIcon={true}
            severity="error"
          />
          ) : (
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={users} // Use the users fetched from Redux
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                autoHeight
              />
            </Box>
          )}
  
          {/* Modal for Viewing User Details */}
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              {selectedUser ? (
                <>
                  <Card variant="outlined" color="secondary" sx={{ margin: 2 }}>
                    <CardContent>
                      <Typography variant="h6" component="h2" gutterBottom>
                        User Details
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Email:</strong> {selectedUser.email}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>First Name:</strong> {selectedUser.fname}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Last Name:</strong> {selectedUser.lname}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Role:</strong> {selectedUser.role}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Referral Code:</strong> {selectedUser.referalCode}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Register Count:</strong> {selectedUser.registerCount}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>User Invited:</strong> {selectedUser.userInvited}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1"><strong>Registered:</strong> {new Date(selectedUser.createdAt).toLocaleString()}</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Button variant="contained" color="error" onClick={handleClose} sx={{ mt: 2 }}>
                    Close
                  </Button>
                </>
              ) : (
                <CircularProgress />
              )}
            </Box>
          </Modal>
  
          {/* Snackbar for Success Notification */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%", fontSize: "1.2rem" }}>
              Data  deleted successfully!
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    );
  };
  
  export default Users;
  