import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Snackbar,
  Alert,
  Button,
  Modal,
  Box,
  TextField,
  TableSortLabel,
  TablePagination,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'; 
import CustomModal from '../../../components/modal'; 
import Loading from '../../../components/loading';
// import ErrorComponent from '../../../components/error';
// import { fetchUser, deleteUser } from "../../redux/slices/userSlice";
// import { useDispatch, useSelector } from "react-redux";

const AdminMessage = () => {
  const [communications, setCommunications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({ message: '', serviceCharge: '', book_id: '' });
  const [currentId, setCurrentId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [modalOpen, setModalOpen] = useState(false);
  // const dispatch = useDispatch();
  
  const adminToken = localStorage.getItem('adminToken');

  // Fetch communications
  useEffect(() => {
    const fetchCommunications = async () => {
      try {
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/comm/get-all', {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
        setCommunications(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message || 'An error occurred');
         setLoading(false);
        setModalOpen(true); 
      }
    };

    fetchCommunications();
  }, [adminToken]);

  // Handle sort requests
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Sorting function
  const sortCommunications = (array) => {
    return array.sort((a, b) => {
      if (orderBy === 'id') {
        return order === 'asc' ? a.id - b.id : b.id - a.id;
      } else if (orderBy === 'message') {
        return order === 'asc'
          ? a.message.localeCompare(b.message)
          : b.message.localeCompare(a.message);
      } else if (orderBy === 'serviceCharge') {
        return order === 'asc' ? a.serviceCharge - b.serviceCharge : b.serviceCharge - a.serviceCharge;
      } else {
        return 0;
      }
    });
  };

  // Handle pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // Handle modal open
  const handleEditClick = (id) => {
    const communicationToEdit = communications.find((comm) => comm.id === id);
    if (communicationToEdit) {
      setFormData({
        message: communicationToEdit.message,
        serviceCharge: communicationToEdit.serviceCharge,
        book_id: communicationToEdit.comBook.id,
      });
      setCurrentId(id);
      setOpenModal(true);
    }
  };

  // Handle update API call
  const handleUpdate = async () => {
    if (!adminToken) {
      setError('Please, Login First.');
      setModalOpen(true);  //to display an error
      return;
    }

    try {
      const response = await axios.put(
        `https://bookbackend3.bruktiethiotour.com/api/comm/update/${currentId}`,
        {
          message: formData.message,
          serviceCharge: formData.serviceCharge,
          book_id: formData.book_id,
        },
        {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage('Communication updated successfully');
        setOpenModal(false); //to close the first modal
        setModalOpen(true); 
       
        setCommunications((prevCommunications) =>
          prevCommunications.map((comm) =>
            comm.id === currentId ? { ...comm, ...formData } : comm
          )
        );
        setOpenModal(true);
      } else {
        setError('Failed to update communication.');
        setOpenModal(true);
      }
    } catch (err) {
      setError(err.message || 'An error occurred during update');
      setOpenModal(true);
    }
  };


  // Handle delete API call
  const handleDelete = async (id) => {
    if (!adminToken) {
      setError('Check If login in ADMIN.');
      setModalOpen(true);
      // setOpenSnackbar(true);
      return;
      // if (window.confirm("Are you sure you want to delete this user?")) {
      //   dispatch(deleteUser({ endpoint: "admin/admins", id })); // Pass an object with endpoint and id
      //   setOpenSnackbar(true); // Open the Snackbar after deletion
      // }
    }

    try {
if (window.confirm("Are you sure you want to delete this user?")) {
     
      const response = await axios.delete(
        `https://bookbackend3.bruktiethiotour.com/api/comm/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      
      setOpenSnackbar(true); // Open the Snackbar after deletion
      
    
      if (response.status === 204) {
        setSuccessMessage('Communication deleted successfully');
        setModalOpen(true); 
        setCommunications((prevCommunications) =>
          prevCommunications.filter((comm) => comm.id !== id)
        );
        // setOpenSnackbar(true);
      } else {
        setError('Failed to delete communication.');
        setModalOpen(true); 
      }
    } } catch (err) {
      setError(err.message || 'An error occurred during delete');
      setModalOpen(true); 
    }
  };

  // Close the snackbar
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setFormData({
      message: '',
      serviceCharge: '',
      book_id: ''
    });
    setCurrentId(null);
  };

  // Filter communications based on search query
  const filteredCommunications = communications.filter((communication) =>
    communication.message.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort communications
  const sortedCommunications = sortCommunications(filteredCommunications);

  // Paginate communications
  const paginatedCommunications = sortedCommunications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Handle form field changes in the modal
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Container sx={{marginTop:8,}}>
      <Typography variant="h4" gutterBottom sx={{}}> 
        <h3>Communication Records</h3>
      </Typography>

      {/* Search bar */}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchChange}
        // icon={<SearchIcon/>}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          marginBottom: 1,
          width: 200, // Minimize the search bar
          position: 'absolute',
          right: '0',
          top: '0',
          marginRight: 5,
          marginTop: 10,
        }}
      />

      {loading ? (
      //   <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
      //   <Loading />
      // </Box>): error ? (
      //       <ErrorComponent
      //       message="Oops! Something went wrong. Please try again."
      //       showIcon={true}
      //       severity="error"
      //     /> 
      <Loading />
          ) : (
        <TableContainer component={Paper}>
          <Table sx={{ backgroundColor: '#141B2D'}}>
            <TableHead sx={{ backgroundColor: '#3E4396', color: '#fff' }}>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'id'}
                    direction={orderBy === 'id' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'message'}
                    direction={orderBy === 'message' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'message')}
                  >
                    Message
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'serviceCharge'}
                    direction={orderBy === 'serviceCharge' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'serviceCharge')}
                  >
                    Service Charge
                  </TableSortLabel>
                </TableCell>
                <TableCell>Reviewed By</TableCell>
                <TableCell>Book Title</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Agreed?</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCommunications.map((communication) => (
                <TableRow key={communication.id}>
                  <TableCell>{communication.id}</TableCell>
                  <TableCell>{communication.message}</TableCell>
                  <TableCell>{communication.serviceCharge}</TableCell>
                  <TableCell>{communication.reviewedBy}</TableCell>
                  <TableCell>{communication.comBook.title}</TableCell>
                  <TableCell>{`${communication.comUser.fname} ${communication.comUser.lname}`}</TableCell>
                  <TableCell>{communication.isAgreed ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained" color="primary" size="small" 
                      onClick={() => handleEditClick(communication.id)}
                      // startIcon={<EditIcon />}
                      // sx={{color: 'blue', border: '1 blue'}}
                    >
                      Edit
                    </Button>
                    <Button
                    variant="contained" color="error" size="small"
                      onClick={() => handleDelete(communication.id)}
                      // startIcon={<DeleteIcon />}
                      sx={{ marginLeft: 2 }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredCommunications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ backgroundColor: '#3E4396', color: '#fff' }}
      />

      {/* Snackbar for error or success messages */}
      {/* <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={error ? 'error' : 'success'} sx={{ width: '100%' }}>
          {error || successMessage}
        </Alert>
      </Snackbar> */}

      {/* Modal for editing communication */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="edit-communication-modal"
        aria-describedby="edit-communication-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Edit Communication
          </Typography>
          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Service Charge"
            name="serviceCharge"
            value={formData.serviceCharge}
            onChange={handleChange}
            type="number"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Book ID"
            name="book_id"
            value={formData.book_id}
            onChange={handleChange}
            type="number"
            fullWidth
            margin="normal"
          />
          <Box sx={{ marginTop: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              sx={{ marginRight: 2 }}
            >
              Update
            </Button>
            <Button variant="outlined" onClick={handleCloseModal} color={'secondary'} size="small">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

        {/* Success Message */}
        {successMessage && (
      
      <CustomModal
      open={modalOpen}
      onClose={handleModalClose}
      severity={'success'}
      message={successMessage}
    />
    )}
     {error && (
    
    <CustomModal
    open={modalOpen}
    onClose={handleModalClose}
    severity={'error'}
    message={error}
  />
  )}
    </Container>
  );
};

// Modal style
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#424242',
  padding: '16px',
  borderRadius: '8px',
  boxShadow: 24,
};

export default AdminMessage;
