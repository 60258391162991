import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = "https://bookbackend3.bruktiethiotour.com/api";

// Set default headers for axios
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

// Function to set the authorization token in the headers
const setAuthToken = () => {
  const token = localStorage.getItem('adminToken');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Thunks for CRUD operations with error handling
export const fetchUser = createAsyncThunk('crud/fetchUser', async (endpoint, { rejectWithValue }) => {
  setAuthToken(); // Set the token before the request
  try {
    const response = await axios.get(`${baseURL}/${endpoint}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred while fetching data');
  }
});

export const createUser = createAsyncThunk('crud/createUser', async ({ endpoint, data }, { rejectWithValue }) => {
  setAuthToken(); // Set the token before the request
  try {
    const response = await axios.post(`${baseURL}/${endpoint}`, data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred while creating data');
  }
});

export const updateUser = createAsyncThunk('crud/updateUser', async ({ endpoint, id, data }, { rejectWithValue }) => {
  setAuthToken(); // Set the token before the request
  try {
    const response = await axios.put(`${baseURL}/${endpoint}/${id}`, data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred while updating data');
  }
});

export const deleteUser = createAsyncThunk('crud/deleteUser', async ({ endpoint, id }, { rejectWithValue }) => {
  setAuthToken(); // Set the token before the request
  try {
    const response = await axios.delete(`${baseURL}/${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred while deleting data');
  }
});

// Initial state with loading, error, and status indicators
const users = createSlice({
    name: 'crud',
    initialState: {
      items: [],
      loading: false,
      error: null,
      status: null, // New field added
    },
    reducers: {
      clearError(state) {
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        // Fetch data
        .addCase(fetchUser.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.status = "loading";
        })
        .addCase(fetchUser.fulfilled, (state, action) => {
          state.loading = false;
          state.items = action.payload;
          state.status = "succeeded";
        })
        .addCase(fetchUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
          state.status = "failed";
        })
        // Create data
        .addCase(createUser.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.status = "loading";
        })
        .addCase(createUser.fulfilled, (state, action) => {
          state.loading = false;
          state.items.push(action.payload);
          state.status = "succeeded";
        })
        .addCase(createUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
          state.status = "failed";
        })
        // Update data
        .addCase(updateUser.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.status = "loading";
        })
        .addCase(updateUser.fulfilled, (state, action) => {
          state.loading = false;
          const index = state.items.findIndex((item) => item.id === action.payload.id);
          if (index !== -1) state.items[index] = action.payload;
          state.status = "succeeded";
        })
        .addCase(updateUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
          state.status = "failed";
        })
        // Delete data
        .addCase(deleteUser.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.status = "loading";
        })
        .addCase(deleteUser.fulfilled, (state, action) => {
          state.loading = false;
          state.items = state.items.filter((item) => item.id !== action.meta.arg.id);
          state.status = "succeeded";
        })
        .addCase(deleteUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
          state.status = "failed";
        });
    },
  });
// Export clearError action and reducer
export const { clearError } = users.actions;
export default users.reducer;
  