import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, Alert, Grid, Card, CardMedia, CardContent, Button } from '@mui/material';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const MostReviewed = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch most reviewed books from the API
  const fetchMostReviewedBooks = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/book/most-reviewed');
      if (response.data.success) {
        setBooks(response.data.books);
      } else {
        setError('Failed to fetch most reviewed books.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMostReviewedBooks();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" mb={2}>Most Reviewed Books</Typography>

      {loading && (
       <Loading />
      )}

      {error && (
      <ErrorComponent
      message="Oops! Something went wrong. Please try again."
      showIcon={true}
      severity="error"
    />
      )}

      {!loading && !error && books.length === 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          No most reviewed books available.
        </Alert>
      )}

      <Grid container spacing={2}>
        {books.map((book) => (
          <Grid item xs={12} sm={6} md={4} key={book.id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={`https://bookbackend3.bruktiethiotour.com/${book.imageFilePath}`}
                alt={book.title}
              />
              <CardContent>
                <Typography variant="h6">{book.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {book.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">Author: {book.author}</Typography>
                <Typography variant="body2" color="text.secondary">Language: {book.language}</Typography>
                <Typography variant="body2" color="text.secondary">Pages: {book.pages}</Typography>
                <Typography variant="body2" color="text.secondary">Publication Year: {book.publicationYear}</Typography>
                <Typography variant="body2" color="text.secondary">Price: {book.price} ETB</Typography>
                <Typography variant="body2" color="text.secondary">Rating: {book.rating} ({book.rateCount} reviews)</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{ mt: 2 }}
                  onClick={() => window.open(`https://bookbackend3.bruktiethiotour.com/${book.imageFilePath}`, '_blank')}
                >
                  View Image
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MostReviewed;
