// CustomModal.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const CustomModal = ({ open, onClose, severity, message }) => {
  const modalTitle = severity === 'success' ? 'Success' : 'Error';
  const backgroundColor = severity === 'success' ? '#4CAF50' : '#f44336';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm" // You can adjust the maxWidth as needed
      fullWidth // Makes the dialog use the full width of its container
      sx={{
        '& .MuiDialogTitle-root': {
          backgroundColor: backgroundColor,
          color: 'white',
          textAlign: 'center',
          padding: '20px',
        },
        '& .MuiDialogContent-root': {
          padding: '20px',
          fontSize: '18px',
          lineHeight: '1.5',
        },
        '& .MuiDialogActions-root': {
          padding: '10px',
          justifyContent: 'center', // Center the button
        },
      }}
    >
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>
      { severity=="success"? " data submitted  is valid and  Inserted successfully!":"the date inserted is invalid please try again"}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
