import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  TableSortLabel,
} from '@mui/material';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';

const SoldReport = () => {
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('adminToken');
      try {
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/sales/books/reports/total', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setData(response.data.sales);
          setFilteredData(response.data.sales);
        } else {
          setError('No data found or response format is incorrect.');
        }
      } catch (err) {
        console.error('An error occurred while fetching data:', err);
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = data.filter(
      (item) =>
        item.orderBook.title.toLowerCase().includes(term) ||
        item.orderUser.fname.toLowerCase().includes(term) ||
        item.orderNumber.toLowerCase().includes(term) ||
        item.type.toLowerCase().includes(term)
    );

    setFilteredData(filtered);
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });

    const sorted = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredData(sorted);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div>
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Sold Report
      </Typography>

      {/* Search Bar */}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearch}
      />

      {/* Display Total Sales */}
      <Typography variant="h5" gutterBottom>
        Total Sales: {filteredData.reduce((total, item) => total + parseFloat(item.price), 0)} ETB
      </Typography>

      {/* Display Detailed Sales Data */}
      <TableContainer sx={{ backgroundColor: '#141B2D', marginTop: 10 }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'id'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('id')}
                >
                  Order ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'price'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('price')}
                >
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell>Bank Name</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'type'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('type')}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell>Transaction Number</TableCell>
              <TableCell>Service Charged</TableCell>
              <TableCell>Order Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Reviewed By</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'createdAt'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('createdAt')}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell>Book Title</TableCell>
              <TableCell>User Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.price}</TableCell>
                <TableCell>{item.bankName}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>{item.transactionNumber}</TableCell>
                <TableCell>{item.serviceCharged}</TableCell>
                <TableCell>{item.orderNumber}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.reviewedBy}</TableCell>
                <TableCell>{new Date(item.createdAt).toLocaleString()}</TableCell>
                <TableCell>{item.orderBook.title}</TableCell>
                <TableCell>{item.orderUser.fname}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SoldReport;
