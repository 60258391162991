import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box, Typography, CircularProgress, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';

const AuthorsList = () => {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('fname');
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  // Function to fetch authors
  const fetchAuthors = async () => {
    setLoading(true);
    setError(null);

    // Retrieve token from localStorage
    const token = localStorage.getItem('adminToken');
    if (!token) {
      setError('Token not found. Please login.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/manage-user/filter-allusers?role=AUTHOR', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setAuthors(response.data.users); // Assuming 'users' is the key in the response
      } else {
        setError('Failed to fetch authors data.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while fetching data');
    } finally {
      setLoading(false);
    }
  };

  const getBooks = (id) => {
    navigate(`/author-books/${id}`);
  };

  const approvedBooks = (id) => {
    navigate(`/author-approved-books/${id}`);
  };

  const salesReport = (id) => {
    navigate(`/book-sales-by-author/${id}`);
  };

  useEffect(() => {
    fetchAuthors();
  }, []);

  // Sorting function
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Sorting function logic
  const sortedAuthors = React.useMemo(() => {
    return authors
      .filter(author =>
        author.fname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        author.lname.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        if (orderBy === 'fname') {
          if (a.fname < b.fname) return sortDirection === 'asc' ? -1 : 1;
          if (a.fname > b.fname) return sortDirection === 'asc' ? 1 : -1;
          return 0;
        }
        return 0; // Add more sorting logic if needed
      });
  }, [authors, searchQuery, sortDirection, orderBy]);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" mb={2}>List of Authors</Typography>

      {loading && (
        <Loading />
      )}

      {error && (
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
      )}

      <TextField
        label="Search Authors"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 2 }}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="authors table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'fname'}
                  direction={sortDirection}
                  onClick={() => handleRequestSort('fname')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAuthors.map((author) => (
              <TableRow hover key={author.id}>
                <TableCell>{author.fname} {author.lname}</TableCell>
                <TableCell>{author.email}</TableCell>
                <TableCell>{author.phone || 'N/A'}</TableCell>
                <TableCell>{author.level}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" size="small" onClick={() => getBooks(author.id)}>
                    View Books
                  </Button>
                  <Button variant="contained" color="secondary" size="small" onClick={() => approvedBooks(author.id)} sx={{ ml: 1 }}>
                    Approved Books
                  </Button>
                  <Button variant="contained" color="secondary" size="small" onClick={() => salesReport(author.id)} sx={{ ml: 1 }}>
                    Sales Report
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AuthorsList;
