import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

const CommunicationPageAuthor = () => {
  const [formData, setFormData] = useState({
    message: "",
    serviceCharge: 100,
    book_id: "", // Initially empty, will be set when the user selects a book
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);
  const [books, setBooks] = useState([]); // State to store the fetched books
  const userToken = localStorage.getItem("userToken");
  const [selectedBook, setSelectedBook] = useState('');
  // Fetch the list of books when the component mounts
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get("https://bookbackend3.bruktiethiotour.com/api/book/allbooks-logged-author", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        if (response.data && Array.isArray(response.data.books)) {
          setBooks(response.data.books); // Set the list of books
          console.log("Books fetched:", response.data.books); // Log books data for debugging
        }
      } catch (error) {
        console.error("Failed to fetch books", error);
      }
    };

    fetchBooks();
  }, [userToken]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data before submitting:", formData); // Log the formData to verify book_id
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "https://bookbackend3.bruktiethiotour.com/api/comm/propose",
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setModalMessage("Message sent successfully!");
      setModalSuccess(true);
    } catch (error) {
      setModalMessage("Failed to send the message. Please try again.");
      setModalSuccess(false);
    } finally {
      setIsSubmitting(false);
      setModalOpen(true);
    }
  };

  // Handle modal close
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 400,
        margin: "auto",
        mt: 5,
      }}
    >
      <Typography variant="h5" textAlign="center">
        Send a Message
      </Typography>

      <TextField
        label="Message"
        name="message"
        value={formData.message}
        onChange={handleInputChange}
        required
      />

      <TextField
        label="Service Charge"
        name="serviceCharge"
        type="number"
        value={formData.serviceCharge}
        onChange={handleInputChange}
        required
      />


              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Book</InputLabel>
                <Select
                  value={selectedBook}
                  onChange={(e) => setSelectedBook(e.target.value)}
                  label="Book"
                >
                  {books.map((book) => (
                    <MenuItem key={book.id} value={book.id}>
                      {book.title} {/* Display the book title */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
       

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
      </Button>

      {/* Modal for success or error message */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h6"
            color={modalSuccess ? "green" : "red"}
            textAlign="center"
          >
            {modalMessage}
          </Typography>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button onClick={handleCloseModal} variant="contained" color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CommunicationPageAuthor;
