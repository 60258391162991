import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";

const Comments = () => {
  const { id } = useParams(); // Get the announcementId from the URL params
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");

  const token = localStorage.getItem("adminToken");
  // Fetch comments based on the announcementId
  useEffect(() => {
    const fetchComments = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://bookbackend3.bruktiethiotour.com/api/announcement/comments/${id}`
        );
        setComments(response.data.data);
      } catch (error) {
        setSnackMessage("Failed to fetch comments!");
        setSnackSeverity("error");
        setSnackOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [id]);

  // Handle delete comment
  const handleDeleteComment = async (commentId) => {
    setLoading(true);
    try {
      await axios.delete(
        `https://bookbackend3.bruktiethiotour.com/api/announcement/delete-comment/${commentId}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackMessage("Comment deleted successfully!");
      setSnackSeverity("success");
      setSnackOpen(true);
      // Fetch updated comments
      const response = await axios.get(
        `https://bookbackend3.bruktiethiotour.com/api/announcement/comments/${id}`
      );
      setComments(response.data.data);
    } catch (error) {
      setSnackMessage("Failed to delete comment!");
      setSnackSeverity("error");
      setSnackOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography variant="h5" mb={2}>
        Comments for Announcement {id}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Comment</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comments.map((comment) => (
              <TableRow key={comment.id}>
                <TableCell>{comment.comment}</TableCell>
                <TableCell>{new Date(comment.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteComment(comment.id)}
                    disabled={loading}
                  >
                    {loading ? "Deleting..." : "Delete"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for success or failure */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snackSeverity} sx={{ width: "100%" }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Comments;
