import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Snackbar, Modal } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import '../../styles/login.css'; // Importing the CSS file for styles
import jwtDecode from "jwt-decode";

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false); // Modal state

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post('https://bookbackend3.bruktiethiotour.com/api/admin/login', {
        email: username,
        password,
      });

      const { adminToken } = response.data;

      // Decode token to get user details
      const decodedToken = jwtDecode(adminToken);
      const {id, fname, lname, role } = decodedToken;

      // Store token and user details in local storage
      localStorage.setItem('adminToken', adminToken);
      localStorage.setItem('fname', fname);
      localStorage.setItem('lname', lname);
      localStorage.setItem('role', role);
      localStorage.setItem('id', id);

      // Set token expiration
      const expiryTime = Date.now() + 3600000; // 1 hour in milliseconds
      localStorage.setItem('tokenExpiry', expiryTime);  
      console.log('Login successful:', response.data);
      window.location.href = "/";

    } catch (err) {
      console.error('Login error:', err);

      setError(err.response?.data?.message || 'Login failed. Please try again.');
      setOpenErrorModal(true); // Show error modal
    }
  };

  const handleCloseModal = () => {
    setOpenErrorModal(false); // Close error modal
  };

  return (
    <Box className="login-container">
      <Box className="login-box" component="form" onSubmit={handleLogin}>
        <Typography variant="h3" className="login-title">
          Welcome Back ADMIN
        </Typography>
        
        <label htmlFor="email">Email</label>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="input-field"
          size="large"
        />

        <label htmlFor="password">Password</label>
        <TextField
          fullWidth
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="input-field"
          size="large"
        />
        
        <Button type="submit" variant="contained" color="primary" fullWidth className="login-button">
          Login
        </Button>
      </Box>

      {/* Error Modal */}
      <Modal open={openErrorModal} onClose={handleCloseModal} className="error-modal">
        <Box className="modal-content">
          <Typography variant="h6" color="error">
            Error: {error}
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleCloseModal}>Close</Button>
        </Box>
      </Modal>

      {/* Snackbar for error messages (optional) */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <MuiAlert onClose={() => setOpenSnackbar(false)} severity="error" elevation={6} variant="filled">
          {error}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default LoginPage;
