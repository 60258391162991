import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const TopSellers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('fname');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const token=localStorage.getItem("adminToken");
      try {
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/sales/books/reports/top-sellers',
          {          
            headers: {
              Authorization: `Bearer ${token}`,
            },          
        });

       
        if (response.data && Array.isArray(response.data)) {
          setData(response.data);
        } else {
          console.error('Unexpected API response:', response.data);
          setError('No data found or response format is incorrect.');
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Seller Name', 'Total Orders', 'Min Spent', 'Max Spent', 'Total Spent']],
      body: data.map(item => [
        item.fname,
        item.order[0]?.totalOrders,
        item.order[0]?.minSpent,
        item.order[0]?.maxSpent,
        item.order[0]?.totalSpent,
      ]),
    });
    doc.save('topsellers.pdf');
  };

  const filteredData = data.filter(item => 
    item.fname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedData = filteredData.sort((a, b) => {
    if (sortDirection === 'asc') {
      return a[sortColumn] < b[sortColumn] ? -1 : 1;
    }
    return a[sortColumn] > b[sortColumn] ? -1 : 1;
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>
      <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
    </div>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Top Sellers
      </Typography>
      <TextField
        label="Search by Seller Name"
        variant="outlined"
        fullWidth
        style={{ marginBottom: '20px' }}
        onChange={handleSearch}
      />
      <Button variant="contained" color="primary" onClick={exportToPDF} style={{ marginBottom: '20px' }}>
        Export to PDF
      </Button>
      <CSVLink
        data={sortedData.map(item => ({
          SellerName: item.fname,
          TotalOrders: item.order[0]?.totalOrders,
          MinSpent: item.order[0]?.minSpent,
          MaxSpent: item.order[0]?.maxSpent,
          TotalSpent: item.order[0]?.totalSpent,
        }))}
        filename="topsellers.csv"
        className="btn btn-primary"
        target="_blank"
      >
        Export to Excel
      </CSVLink>
      <TableContainer sx={{ backgroundColor: '#141B2D', marginTop: 10}}  component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'fname'}
                  direction={sortColumn === 'fname' ? sortDirection : 'asc'}
                  onClick={() => handleSort('fname')}
                >
                  Seller Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'order.totalOrders'}
                  direction={sortColumn === 'order.totalOrders' ? sortDirection : 'asc'}
                  onClick={() => handleSort('order.totalOrders')}
                >
                  Total Orders
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'order.minSpent'}
                  direction={sortColumn === 'order.minSpent' ? sortDirection : 'asc'}
                  onClick={() => handleSort('order.minSpent')}
                >
                  Min Spent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'order.maxSpent'}
                  direction={sortColumn === 'order.maxSpent' ? sortDirection : 'asc'}
                  onClick={() => handleSort('order.maxSpent')}
                >
                  Max Spent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'order.totalSpent'}
                  direction={sortColumn === 'order.totalSpent' ? sortDirection : 'asc'}
                  onClick={() => handleSort('order.totalSpent')}
                >
                  Total Spent
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.fname}</TableCell>
                <TableCell>{row.order[0]?.totalOrders}</TableCell>
                <TableCell>{row.order[0]?.minSpent}</TableCell>
                <TableCell>{row.order[0]?.maxSpent}</TableCell>
                <TableCell>{row.order[0]?.totalSpent}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TopSellers;
