import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button, TextField, Typography, Box } from '@mui/material';

const RegisterTier = () => {
  const [formData, setFormData] = useState({
    tier_name: '',
    monthly_price: '',
    annual_price: '',
    content_type: 'audio_books',
    benefit_limit: '',
    is_active: true,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://bookbackend3.bruktiethiotour.com/api/subscriptions/tiers', formData);
      setModalSuccess(true);
      setModalMessage('Subscription Tier registered successfully!');
    } catch (error) {
      setModalSuccess(false);
      setModalMessage(error.response?.data?.message || 'Failed to register subscription tier.');
    } finally {
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ maxWidth: 400, margin: '0 auto', mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Register Subscription Tier
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Tier Name"
          name="tier_name"
          value={formData.tier_name}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Monthly Price"
          name="monthly_price"
          type="number"
          value={formData.monthly_price}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Annual Price"
          name="annual_price"
          type="number"
          value={formData.annual_price}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Content Type"
          name="content_type"
          value={formData.content_type}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
          select
          SelectProps={{
            native: true,
          }}
        >
          <option value="books">Books</option>
          <option value="audio_books">Audio Books</option>
          <option value="exclusive_content">Exclusive Content</option>
        </TextField>
        <TextField
          label="Benefit Limit"
          name="benefit_limit"
          type="number"
          value={formData.benefit_limit}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>

      {/* Success/Failure Modal */}
      <Modal open={modalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h6"
            color={modalSuccess ? 'green' : 'red'}
            gutterBottom
          >
            {modalSuccess ? 'Success' : 'Error'}
          </Typography>
          <Typography variant="body1">{modalMessage}</Typography>
          <Button
            onClick={closeModal}
            variant="contained"
            color={modalSuccess ? 'success' : 'error'}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default RegisterTier;
