import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = "https://bookbackend3.bruktiethiotour.com/api";

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

const setAuthToken = () => {
  const token = localStorage.getItem('adminToken');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// deleting audio is authors previlege so it takes useToken
const setAuthTokens = () => {
  const token = localStorage.getItem('userToken');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Fetch all audio files
export const fetchAllAudioFiles = createAsyncThunk(
  'audio/fetchAllAudioFiles',
  async (_, { rejectWithValue }) => {
    setAuthToken();
    try {
      const response = await axios.get(`${baseURL}/book/audio`);
      return response.data || [];
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching audio files');
    }
  }
);

// Delete audio file by ID
export const deleteAudioFileById = createAsyncThunk(
  'audio/deleteAudioFileById',
  async (audioId, { rejectWithValue }) => {
    setAuthTokens();
    try {
      const response = await axios.delete(`${baseURL}/book/delete-audio/${audioId}`, {
        data: { audio_id: audioId }, // Ensure audio_id is sent in the body
      });
      return { id: audioId, ...response.data }; // Return response along with audioId
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error deleting audio file');
    }
  }
);

// Fetch audio files by book_id for the logged-in author
export const fetchAudioFilesByBookId = createAsyncThunk(
  'audio/fetchAudioFilesByBookId',
  async (bookId, { rejectWithValue }) => {
    setAuthTokens();
    try {
      const response = await axios.get(`${baseURL}/book/audio-for-logged-author/${bookId}`);
      return response.data || []; // Return the audio array
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching audio files for the book');
    }
  }
);


const audioSlice = createSlice({
  name: 'audio',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAudioFiles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllAudioFiles.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchAllAudioFiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle delete audio file by ID
      .addCase(deleteAudioFileById.fulfilled, (state, action) => {
        state.items = state.items.filter((audio) => audio.id !== action.meta.arg);
      })
      .addCase(deleteAudioFileById.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchAudioFilesByBookId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAudioFilesByBookId.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload.audio;
      })
      .addCase(fetchAudioFilesByBookId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = audioSlice.actions;
export default audioSlice.reducer;
