import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useParams } from "react-router-dom";

const ReviewFeedbackForm = () => {
  const [answer, setAnswer] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [editFeedback, setEditFeedback] = useState(null); // State for editing
  const { id } = useParams();

  const apiUrl = "https://bookbackend3.bruktiethiotour.com/api/review-feedback";
  const token = localStorage.getItem("userToken");

  // Fetch feedbacks
  const fetchFeedbacks = async () => {
    try {
      const response = await axios.get(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFeedbacks(response.data.feedbacks);
    } catch (error) {
      setAlert({ type: "error", message: "Failed to fetch feedbacks." });
    }
  };

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editFeedback) {
        // Update existing feedback
        await axios.put(
          `${apiUrl}/${editFeedback.id}`,
          { answer },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAlert({ type: "success", message: "Feedback updated successfully!" });
        setEditFeedback(null);
      } else {
        // Add new feedback
        await axios.post(
          apiUrl,
          { answer, review_id: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAlert({ type: "success", message: "Feedback submitted successfully!" });
      }
      setAnswer("");
      fetchFeedbacks();
    } catch (error) {
      setAlert({ type: "error", message: "Failed to submit feedback." });
    }
  };

  // Handle delete
  const handleDelete = async (feedbackId) => {
    try {
      await axios.delete(`${apiUrl}/${feedbackId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAlert({ type: "success", message: "Feedback deleted successfully!" });
      fetchFeedbacks();
    } catch (error) {
      setAlert({ type: "error", message: "Failed to delete feedback." });
    }
  };

  // Open edit dialog
  const handleEdit = (feedback) => {
    setEditFeedback(feedback);
    setAnswer(feedback.answer);
  };

  // Close edit dialog
  const handleCancelEdit = () => {
    setEditFeedback(null);
    setAnswer("");
  };

  return (
    <div>
      <h2>Submit Feedback</h2>
      {alert.message && (
        <Alert severity={alert.type} onClose={() => setAlert({ type: "", message: "" })}>
          {alert.message}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary">
          {editFeedback ? "Update" : "Submit"}
        </Button>
        {editFeedback && (
          <Button onClick={handleCancelEdit} variant="outlined" color="secondary" style={{ marginLeft: 10 }}>
            Cancel
          </Button>
        )}
      </form>

      <h2>Feedback List</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Review </TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Answer </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbacks.map((feedback) => (
              <TableRow key={feedback.id}>
                <TableCell>{feedback.id}</TableCell>
                <TableCell>{feedback.review?.comment || "N/A"}</TableCell>
                <TableCell>{feedback.author?.fname || "N/A"}</TableCell>
         
                <TableCell>{feedback.answer}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEdit(feedback)}
                    style={{ marginRight: 10 }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(feedback.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReviewFeedbackForm;
