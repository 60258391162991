import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Alert, Grid, Card, CardMedia, CardContent, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';

const AuthorBooks = () => {
  const { id } = useParams(); // Get author id from route parameters
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("adminToken");

  // Function to fetch books by author ID
  const fetchBooks = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`https://bookbackend3.bruktiethiotour.com/api/book/by-authors-id/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setBooks(response.data.books);
      } else {
        setError('Failed to fetch books data.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooks();
  }, [id]);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" mb={2}>Books by Author</Typography>

      {loading && <Loading />}

      {error && (
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
      )}

      <Grid container spacing={2}>
        {books.map((book) => (
          <Grid item xs={12} sm={6} md={4} key={book.id}>
            <Card sx={{ boxShadow: 3, borderRadius: 2, '&:hover': { boxShadow: 6 }, backgroundColor: '#8A6D6DFF' }}>
              <CardMedia
                component="img"
                height="200"
                image={`https://bookbackend3.bruktiethiotour.com/${book.imageFilePath}`}
                alt={book.title}
                sx={{ objectFit: 'cover', borderTopLeftRadius: 2, borderTopRightRadius: 2 }}
              />
              <CardContent sx={{ padding: 2 }}>
                <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
                  {book.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>Author: {book.author}</Typography>
                <Typography variant="body2" color="text.secondary">Language: {book.language}</Typography>
                <Typography variant="body2" color="text.secondary">Pages: {book.pages}</Typography>
                <Typography variant="body2" color="text.secondary">Publication Year: {book.publicationYear}</Typography>
                <Typography variant="body2" color="text.secondary">Price: {book.price} ETB</Typography>
                <Typography variant="body2" color="text.secondary">Rating: {book.rating} ({book.rateCount} reviews)</Typography>
                <Typography variant="body2" color="text.secondary">Status: {book.status}</Typography>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ mt: 2, backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#1565c0' } }}
                  onClick={() => window.open(`https://bookbackend3.bruktiethiotour.com/${book.pdfFilePath}`, '_blank')}
                >
                  View PDF
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AuthorBooks;
