// ErrorComponent.js
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing

const ErrorComponent = ({ message, description, showIcon = true, severity = 'error' }) => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleGoHome = () => {
    navigate('/'); // Navigate to the home page
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 1,
        margin: 2,
        borderRadius: 2,
        background: severity === 'error' ? 
          'linear-gradient(135deg, #08A11FFF 0%, #C23543FF 100%)' : 
          'linear-gradient(135deg, #fff3cd 0%, #ffeeba 100%)',
        color: severity === 'error' ? '#721c24' : '#856404',
        boxShadow: 4,
        animation: 'fadeIn 0.5s',
      }}
    >
      {showIcon && (
        <ErrorOutline
          sx={{
            fontSize: 60,
            marginBottom: 1,
            color: severity === 'error' ? '#721c24' : '#856404',
            animation: 'bounce 1s infinite',
          }}
        />
      )}
      <Typography variant="h6" fontWeight="bold">{message}</Typography>
      {description && (
        <Typography variant="body2" sx={{ textAlign: 'center', marginTop: 1 }}>
          {description}
        </Typography>
      )}
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={handleGoHome} 
        sx={{ marginTop: 2 }}
      >
        Go to Home
      </Button>
      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes bounce {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
          }
        `}
      </style>
    </Box>
  );
};

ErrorComponent.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string,
  showIcon: PropTypes.bool,
  severity: PropTypes.oneOf(['error', 'warning']),
};

export default ErrorComponent;
