import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Divider,
  Box
} from '@mui/material';
import { styled } from '@mui/system';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const OrderContainer = styled(Box)({
  padding: '20px',
  backgroundColor: '#0CB084FF',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  maxWidth: '600px',
  margin: 'auto',
  marginTop: '20px',
});

function SearchOrder() {
  const [orderNumber, setOrderNumber] = useState('');
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (e) => setOrderNumber(e.target.value);

  const fetchOrder = async () => {
    setLoading(true);
    setError(null);
    setOrderData(null);

    try {
      const response = await axios.get(`https://bookbackend3.bruktiethiotour.com/api/order/ordernumber/${orderNumber}`);
      if (response.data.success) {
        setOrderData(response.data.order);
      } else {
        setError('Order not found');
      }
    } catch (err) {
      setError('Error fetching order details');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (orderNumber) {
      fetchOrder();
    }
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <OrderContainer>
      <Typography variant="h4" gutterBottom textAlign="center">
        Order Lookup
      </Typography>
      <form onSubmit={handleSearch}>
        <TextField
          label="Order Number"
          variant="outlined"
          value={orderNumber}
          onChange={handleInputChange}
          required
          fullWidth
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          fullWidth
          style={{ marginTop: '10px' }}
        >
          {loading ? <Loading /> : 'Search'}
        </Button>
      </form>

      {error && <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        /> }

      {orderData && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6" color="primary">Order Summary</Typography>
          <Typography><strong>Order Number:</strong> {orderData.orderNumber}</Typography>
          <Typography><strong>Status:</strong> {orderData.status}</Typography>
          <Button variant="outlined" color="primary" onClick={handleShowModal} style={{ marginTop: '10px' }}>
            View Details
          </Button>
        </div>
      )}

      <Dialog open={showModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          {orderData && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">General Information</Typography>
                <Divider style={{ marginBottom: '10px' }} />
                <Typography><strong>Order ID:</strong> {orderData.id}</Typography>
                <Typography><strong>Order Number:</strong> {orderData.orderNumber}</Typography>
                <Typography><strong>Price:</strong> ${orderData.price}</Typography>
                <Typography><strong>Service Charged:</strong> {orderData.serviceCharged}</Typography>
                <Typography><strong>Status:</strong> {orderData.status}</Typography>
                <Typography><strong>Created At:</strong> {new Date(orderData.createdAt).toLocaleString()}</Typography>
                <Typography><strong>Updated At:</strong> {new Date(orderData.updatedAt).toLocaleString()}</Typography>
                <img
                  src={`https://bookbackend3.bruktiethiotour.com/${orderData.receiptImagePath}`}
                  alt="Receipt"
                  style={{ width: '100px', marginTop: '10px', borderRadius: '4px' }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" color="primary">User Information</Typography>
                <Divider style={{ marginBottom: '10px' }} />
                <Typography><strong>User ID:</strong> {orderData.orderUser.id}</Typography>
                <Typography><strong>First Name:</strong> {orderData.orderUser.fname}</Typography>
                <Typography><strong>Last Name:</strong> {orderData.orderUser.lname}</Typography>
                <img
                  src={`https://bookbackend3.bruktiethiotour.com/${orderData.orderUser.imageFilePath}`}
                  alt="User"
                  style={{ width: '100px', marginTop: '10px', borderRadius: '4px' }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" color="primary">Book Information</Typography>
                <Divider style={{ marginBottom: '10px' }} />
                <Typography><strong>Title:</strong> {orderData.orderBook.title}</Typography>
                <Typography><strong>Description:</strong> {orderData.orderBook.description}</Typography>
                <Typography><strong>Author:</strong> {orderData.orderBook.author}</Typography>
                <Typography><strong>Publication Year:</strong> {orderData.orderBook.publicationYear}</Typography>
                <Typography><strong>Language:</strong> {orderData.orderBook.language}</Typography>
                <Typography><strong>Pages:</strong> {orderData.orderBook.pages}</Typography>
                <Typography><strong>Price:</strong> ${orderData.orderBook.price}</Typography>
                <Typography><strong>Revenue:</strong> ${orderData.orderBook.revenue}</Typography>
                <Typography><strong>Sold:</strong> {orderData.orderBook.sold}</Typography>
                <img
                  src={`https://bookbackend3.bruktiethiotour.com/${orderData.orderBook.imageFilePath}`}
                  alt="Book"
                  style={{ width: '100px', marginTop: '10px', borderRadius: '4px' }}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </OrderContainer>
  );
}

export default SearchOrder;
