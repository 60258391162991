import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, MenuItem, Select, FormControl, InputLabel, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';

const OrderStatusFilter = () => {
  const [selectedStatus, setSelectedStatus] = useState('PENDING');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [approveModal, setApproveModal] = useState(false);
  const [orderToApprove, setOrderToApprove] = useState(null);
  const [newStatus, setNewStatus] = useState('');

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleFilterClick = async () => {
    setLoading(true);
    setError(null);
    try {
      const adminToken = localStorage.getItem("adminToken");
      if (!adminToken) {
        setLoading(false);
        setError("Admin token is missing");
        return;
      }

      const response = await axios.get(
        `https://bookbackend3.bruktiethiotour.com/api/order/bystatus/${selectedStatus}`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );

      if (response.data.success) {
        setOrders(response.data.orders);
      } else {
        console.error("Error fetching orders:", response.data.message);
        setError(response.data.message || "Failed to fetch orders");
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message || error);
      setError("An error occurred while fetching orders. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    // Automatically fetch pending orders on page load
    handleFilterClick();
  }, []);

  const handleApproveClick = (orderId) => {
    // Set the order id to approve
    setOrderToApprove(orderId); // Store the id of the order to be approved
    setNewStatus(''); // Reset the newStatus before opening the modal
    setApproveModal(true); // Open the approval modal
  };
  

  const handleStatusSelection = (event) => {
    setNewStatus(event.target.value);
  };

  const handleConfirmApproval = async () => {
    if (!newStatus) {
      setError("Please select a status to update.");
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const adminToken = localStorage.getItem("adminToken");
      if (!adminToken) {
        setLoading(false);
        setError("Admin token is missing");
        return;
      }
  
      const orderId = orderToApprove; // Use the orderId that was set in handleApproveClick
  
      // Send the PUT request with the correct data
      const response = await axios.put(
        `https://bookbackend3.bruktiethiotour.com/api/order/update-status/${orderId}`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
  
      if (response.data.success) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId ? { ...order, status: newStatus } : order
          )
        );
      } else {
        setError(response.data.message || "Failed to update order status");
      }
    } catch (error) {
      console.error("Error updating order status:", error.message || error);
      setError("An error occurred while updating the order status. Please try again.");
    } finally {
      setLoading(false);
      setApproveModal(false);
      setOrderToApprove(null);
    }
  };
  
  const columns = [
    { field: 'orderNumber', headerName: 'Order Number', flex: 1 },
    { field: 'price', headerName: 'Price', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (params) => new Date(params.row.createdAt).toLocaleString(),
    },
    {
      field: 'user',
      headerName: 'User Name',
      flex: 1,
      valueGetter: (params) => `${params.row.orderUser.fname} ${params.row.orderUser.lname}`,
    },
    {
      field: 'type',
      headerName: 'book Type',
      flex: 1,
      valueGetter: (params) => `${params.row.orderUser.type} ${params.row.orderUser.type}`,
    },
    {
      field: 'details',
      headerName: 'Details',
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleImageClick(`https://bookbackend3.bruktiethiotour.com/${params.row.receiptImagePath}`)}
        >
          View Receipt
        </Button>
      ),
    },
    {
      field: 'approve',
      headerName: 'Approve',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleApproveClick(params.row.id)} // Pass the `id` of the current row
        >
          Approve
        </Button>
      ),
    },
  ];
  

  return (
    <div>
      <FormControl variant="outlined" style={{ minWidth: 120, marginBottom: '20px' }}>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          value={selectedStatus}
          onChange={handleStatusChange}
          label="Status"
        >
          <MenuItem value="PENDING">PENDING</MenuItem>
          <MenuItem value="PROCESSING">PROCESSING</MenuItem>
          <MenuItem value="APPROVED">APPROVED</MenuItem>
          <MenuItem value="CANCELLED">CANCELLED</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" onClick={handleFilterClick} disabled={loading}>
        Filter
      </Button>

      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorComponent message={error} />
      ) : (
        <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
          <DataGrid
            rows={orders}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            getRowId={(row) => row.id}
          />
        </div>
      )}

      {/* Modal for displaying full-screen image */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogContent style={{ textAlign: 'center' }}>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Receipt"
              style={{ width: '100%', height: 'auto', maxHeight: '80vh' }}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Approve Modal for selecting status */}
      <Dialog open={approveModal} onClose={() => setApproveModal(false)}>
        <DialogTitle>Select New Status</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="new-status-select-label">New Status</InputLabel>
            <Select
              labelId="new-status-select-label"
              value={newStatus}
              onChange={handleStatusSelection}
              label="New Status"
            >
              <MenuItem value="PENDING">PENDING</MenuItem>
              <MenuItem value="PROCESSING">PROCESSING</MenuItem>
              <MenuItem value="APPROVED">APPROVED</MenuItem>
              <MenuItem value="CANCELLED">CANCELLED</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setApproveModal(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmApproval} color="primary" disabled={loading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderStatusFilter;
