// src/components/Loading.js
import { Box, CircularProgress, Typography } from "@mui/material";

const Loading = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="75vh"
    >
      <CircularProgress size={100} color="error" /> {/* Larger spinner */}
      <Typography variant="h6" marginTop={2} style={{ color: 'red', fontWeight: 'bold' }}>
        Please wait...
      </Typography>
    </Box>
  );
};

export default Loading;
