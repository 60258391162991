import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Alert,
  MenuItem,
} from '@mui/material';

const ManagerRole = () => {
  const [permissions, setPermissions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    fetchPermissions();
  }, []);

  const fetchPermissions = async () => {
    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/role/permissions/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPermissions(response.data.permissions);
    } catch (error) {
      console.error('Error fetching permissions', error);
    }
  };

  const handleOpenModal = (permission, edit = false) => {
    setSelectedPermission(permission);
    setIsEdit(edit);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPermission(null);
  };

  const handleDelete = async (permissionId) => {
    try {
      await axios.delete(`https://bookbackend3.bruktiethiotour.com/api/role/permissions/${permissionId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSnackMessage('Permission deleted successfully!');
      setSnackSeverity('success');
      setSnackOpen(true);
      fetchPermissions(); // Refresh the list after deletion
    } catch (error) {
      setSnackMessage('Error deleting permission!');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleEdit = async () => {
    try {
      await axios.put(
        `https://bookbackend3.bruktiethiotour.com/api/role/permissions/${selectedPermission.id}`,
        selectedPermission,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackMessage('Permission updated successfully!');
      setSnackSeverity('success');
      setSnackOpen(true);
      fetchPermissions(); // Refresh the list after editing
      handleCloseModal();
    } catch (error) {
      setSnackMessage('Error updating permission!');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleInputChange = (e) => {
    setSelectedPermission({ ...selectedPermission, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>Module</TableCell>
              <TableCell>Can View</TableCell>
              <TableCell>Can Create</TableCell>
              <TableCell>Can Edit</TableCell>
              <TableCell>Can Delete</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions.map((permission) => (
              <TableRow key={permission.id}>
                <TableCell>{permission.roleName}</TableCell>
                <TableCell>{permission.module}</TableCell>
                <TableCell>{permission.canView ? 'Yes' : 'No'}</TableCell>
                <TableCell>{permission.canCreate ? 'Yes' : 'No'}</TableCell>
                <TableCell>{permission.canEdit ? 'Yes' : 'No'}</TableCell>
                <TableCell>{permission.canDelete ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Button
                    color="secondary"
                    onClick={() => handleOpenModal(permission, true)}
                    variant="outlined"
                    size="small"
                   
                    style={{ marginRight: '10px' }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="error"
                    onClick={() => handleDelete(permission.id)}
                    variant="outlined"
                    size="small"
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
{/* Edit Modal */}
<Modal open={openModal} onClose={handleCloseModal} sx={{ backgroundColor: '#141B2D', marginTop: 10 }}>
  <Box
    sx={{
      width: 400,
      margin: 'auto',
      padding: 2,
      bgcolor: 'black', // Changed the background color to black
      borderRadius: 2,
      boxShadow: 3,
      color: 'white', // Optional: Set text color to white for better contrast
    }}
  >
    <Typography variant="h6" gutterBottom>
      {isEdit ? 'Edit Permission' : 'Create Permission'}
    </Typography>
    <TextField
      label="Role Name"
      name="roleName"
      value={selectedPermission?.roleName || ''}
      onChange={handleInputChange}
      fullWidth
      margin="normal"
      InputProps={{
        style: { color: 'white' }, // Set input text color to white
      }}
      InputLabelProps={{
        style: { color: 'white' }, // Set label color to white
      }}
    />
  
    <Grid item xs={12}>
            <TextField
              select
              label="Module"
              name="module"
              value={selectedPermission?.module || ''}
              onChange={handleInputChange}
              fullWidth
              required
            >
              <MenuItem value="order">Order</MenuItem>
              <MenuItem value="report">Report</MenuItem>
              <MenuItem value="dashboard">Dashboard</MenuItem>
              <MenuItem value="author">Author</MenuItem>
              <MenuItem value="user">User Module</MenuItem>
              <MenuItem value="announcement">announcement</MenuItem>
              <MenuItem value="subscription">subscription</MenuItem>
              <MenuItem value="setting">setting</MenuItem>
              <MenuItem value="book">book</MenuItem>
              <MenuItem value="communication">communication</MenuItem>
              <MenuItem value="subscription">subscription</MenuItem>
            
            </TextField>
          </Grid>


    <div>
      <label style={{ color: 'white' }}>Can View</label>
      <input
        type="checkbox"
        name="canView"
        checked={selectedPermission?.canView || false}
        onChange={(e) => handleInputChange({ target: { name: 'canView', value: e.target.checked } })}
      />
    </div>
    <div>
      <label style={{ color: 'white' }}>Can Create</label>
      <input
        type="checkbox"
        name="canCreate"
        checked={selectedPermission?.canCreate || false}
        onChange={(e) => handleInputChange({ target: { name: 'canCreate', value: e.target.checked } })}
      />
    </div>
    <div>
      <label style={{ color: 'white' }}>Can Edit</label>
      <input
        type="checkbox"
        name="canEdit"
        checked={selectedPermission?.canEdit || false}
        onChange={(e) => handleInputChange({ target: { name: 'canEdit', value: e.target.checked } })}
      />
    </div>
    <div>
      <label style={{ color: 'white' }}>Can Delete</label>
      <input
        type="checkbox"
        name="canDelete"
        checked={selectedPermission?.canDelete || false}
        onChange={(e) => handleInputChange({ target: { name: 'canDelete', value: e.target.checked } })}
      />
    </div>
    <Button
      onClick={handleEdit}
      color="primary"
      variant="contained"
      fullWidth
      style={{ marginTop: '20px' }}
    >
      Save
    </Button>
  </Box>
</Modal>


      {/* Snackbar for feedback */}
      <Snackbar open={snackOpen} autoHideDuration={3000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity={snackSeverity} sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ManagerRole;
