import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, CircularProgress, Typography } from '@mui/material';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const TodayOrder = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/order/today');
        if (response.data.success) {
          setOrders(response.data.orders);
        } else {
          throw new Error('Failed to fetch orders');
        }
      } catch (error) {
        setError(error.message || 'Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const columns = [
    { field: 'orderNumber', headerName: 'Order Number', flex: 1 },
    { field: 'price', headerName: 'Price', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (params) => new Date(params.row.createdAt).toLocaleString(),
    },
    {
      field: 'user',
      headerName: 'User Name',
      flex: 1,
      valueGetter: (params) => `${params.row.orderUser.fname} ${params.row.orderUser.lname}`,
    },
    {
      field: 'details',
      headerName: 'Details',
      renderCell: (params) => (
        <Button 
          variant="outlined" 
            color="secondary"
          onClick={() => window.open(`https://bookbackend3.bruktiethiotour.com/${params.row.receiptImagePath}`, '_blank')}
        >
          View Receipt
        </Button>
      ),
      flex: 1,
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      {loading ? (
       <Loading />
      ) : error ? (
        <ErrorComponent
        message="Oops! Something went wrong. Please try again."
        showIcon={true}
        severity="error"
      />
      ) : (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={orders}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      )}
    </div>
  );
};

export default TodayOrder;
