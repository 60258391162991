import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress,
  Snackbar, Alert, Container, Grid
} from '@mui/material';
import CustomModal from '../../../components/modal'; // Adjust the import path as necessary
// import Loading from '../../../components/loading';

const CommunicationPage = () => {
  const [message, setMessage] = useState('');
  const [serviceCharge, setServiceCharge] = useState('');
  const [selectedBook, setSelectedBook] = useState('');
  const [books, setBooks] = useState([]);
  const [loadingBooks, setLoadingBooks] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  // Get the admin token from localStorage
  const adminToken = localStorage.getItem('adminToken');

  // Fetch books from API
  useEffect(() => {
    const fetchBooks = async () => {
      if (!adminToken) {
        setError('Check Your Login Status');
        setLoadingBooks(false);
        return;
      }

      try {
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/book/get-all', {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
        setBooks(response.data || []);
        setLoadingBooks(false);
      } catch (err) {
        setError('Failed to load books');
        setLoadingBooks(false);
      }
    };

    fetchBooks();
  }, [adminToken]);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!message || !serviceCharge || !selectedBook) {
      setError('All fields are required.');
      return;
    }
  
    setLoadingSubmit(true);
    setError('');
  
    const payload = {
      message,
      serviceCharge,
      book_id: selectedBook,
    };
    
    try {
      // Perform API request to create communication
      const response = await axios.post('https://bookbackend3.bruktiethiotour.com/api/comm/create', payload, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
  
      if (response.status === 201) {
        setSuccessMessage('Communication created successfully');
        setMessage('');
        setServiceCharge('');
        setSelectedBook('');
        setModalOpen(true); // Open the modal after success
      } else if (response.status === 200 && response.data.success === true) {
        setError('Communication already exists');
        setModalOpen(true); // Open the modal after success
      }
    } catch (err) {
      setError('Sorry, please try again later');
      setModalOpen(true);
      console.log(err);
    } finally {
      setLoadingSubmit(false);
    }
  };
  

 

  

  return (
    <Container>
      <h2>Create Communication</h2>

      {loadingBooks ? (
        <CircularProgress />
      ) : error ? (
        <Snackbar open={true} autoHideDuration={6000}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Service Charge (%)"
                variant="outlined"
                fullWidth
                value={serviceCharge}
                onChange={(e) => setServiceCharge(e.target.value)}
                required
                type="number"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Book</InputLabel>
                <Select
                  value={selectedBook}
                  onChange={(e) => setSelectedBook(e.target.value)}
                  label="Book"
                >
                  {books.map((book) => (
                    <MenuItem key={book.id} value={book.id}>
                      {book.title} {/* Display the book title */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth disabled={loadingSubmit}>
                {loadingSubmit ? <CircularProgress size={24} color="inherit" /> : 'Create Communication'}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      {/* Success Message */}
      {successMessage && (
      
        <CustomModal
        open={modalOpen}
        onClose={handleCloseModal}
        severity={'success'}
        message={successMessage}
      />
      )}
       {error && (
      
      <CustomModal
      open={modalOpen}
      onClose={handleCloseModal}
      severity={'error'}
      message={error}
    />
    )}
    </Container>
  );
};

export default CommunicationPage;
