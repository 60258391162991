import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"; 
import Topbar from "./common/Topbar";
import Sidebar from "./common/Sidebar";
import AuthorSidebar from "./common/Sidebar_author";
import SalesAuthor from "./common/Sidebar2";
import Dashboard from "./components/dashboard";
import SalesDashboard from "./components/sales_dashboard";
import AuthorDashboard from "./components/author_dashboard";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import LoginPage from "./pages/user/login";
import AuthorLogin from "./pages/user/autherLogin";
import RegisterUserForm from "./pages/user/add";
import Users from "./pages/user/manage";
import EditUserForm from "./pages/user/edit";
import RegisterBookForm from "./pages/book/add";
import Books from "./pages/book/manage";
import EditBookForm from "./pages/book/edit";
import NotFoundPage from "./common/notFound";
import BookDetail from "./pages/book/detail";
import BookFilter from "./pages/book/filter";
import MostSoldBooks from "./pages/book/most-sold";
import AuthorsList from "./pages/user/authors";
import AuthorBooks from "./pages/book/author-books";
import ApprovedBooks from "./pages/book/approved-books";
import MostReviewed from "./pages/book/most-reviewed";
import TopRated from "./pages/book/top-rated";
import TodayBook from "./pages/book/today-books";
import Last7Days from "./pages/book/last7days-books";
import BookByStatus from "./pages/book/book-by-status";
import UpdateStatus from "./pages/book/update-status";
import LevelComponent from "./pages/setting/level";
import Account from "./pages/setting/account";
import Category from "./pages/setting/category";
import Order from "./pages/order/orders";
import BookApprovedOrder from "./pages/book/book-approved-order";
import OrderStatusFilter from "./pages/order/order-by-status";
import TodayOrder from "./pages/order/today-order";
import Last7DaysOrder from "./pages/order/last7-days-order";
import SearchOrder from "./pages/order/search-orders";
import SalesReferralOrder  from "./pages/order/sales-referal-order";
import SalesReportByBook from "./pages/reports/sales-report-byBook";
import SubscriptionRevenue from "./pages/reports/subscription-revenu-report";
import MyBookReport from "./pages/reports/my-books-report";
import BookSalesByAuthor from "./pages/reports/author-sales-report";
import TopSale from "./pages/reports/top-sales-report";
import TopSellers from "./pages/reports/top-seller";
import TopBuyers from "./pages/reports/top-buyers";
import Last7DaysSales from "./pages/reports/last7days-report";
import SoldReport from "./pages/reports/sold-report";

import ReportByBookAndDate  from "./pages/reports/report-by-book-date";
import AddSubscriptionForm from "./pages/subscription/add";
import SubscriptionManagement from "./pages/subscription/manage";
import SubscriptionOrders from "./pages/subscription/subscription-orders";
import VerifiedUsers from "./pages/user/verified-user";
// author imports 
import BookByStatuse from './pages/author/logged-author-bystatus';
import AllAuthorBooks from './pages/author/allBooks-logged-author'
import AllApprovedAuthorBooks from './pages/author/all-approved-author-bookes'
import AudioListPage from "./pages/audio/BookAudioPlayer ";
import AudioFilesPage from "./pages/author/author-books-audio";
import UploadAudio from "./pages/audio/UploadAudio";
import ReviewForm from './pages/author/review'
import ReviewsTable from "./pages/book/all-review";
import BooksTable from "./pages/author/other-books";
import BookReviews from './pages/author/review-book-byId'
import ReviewsAll from "./pages/book/all-review";
import UpdateReview from "./pages/author/update-review";
import ReviewFeedbackForm from "./pages/author/answer-for_review";

// 
import CommunicationPageAuthor from "./pages/communication/author/communication";
import CommunicationPage from "./pages/communication/admin/communication";
import ProposalPage  from "./pages/communication/author/sent_proposals";
import Messages from "./pages/communication/author/viewMessage";
import AdminMessage from "./pages/communication/admin/get-message";
import NotAgreedCommunications from "./pages/communication/admin/notagreed";
import CreateRoleForm from "./pages/setting/add_role";
import MangeRole from "./pages/setting/manage_role";
import CreateAnnouncement from "./pages/communication/admin/create_announcment";
import Announcements from "./pages/communication/admin/annoucments";
import Comments from "./pages/communication/admin/comments";
import MyAnnouncements from "./pages/communication/admin/my_annoucments";
import RegisterTier from "./pages/subscription/add_subscription";
import SubscriptionTiers from "./pages/subscription/manage_subscription_triers";
import SubscriptionTable from "./pages/subscription/user_subscrptions";
import Moderator from "./common/Sidebar2";
import QualityGuidelines from "./pages/setting/qaulity";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState('');

  useEffect(() => {
    const adminToken = localStorage.getItem('adminToken');
    const userToken = localStorage.getItem('userToken');
      setRole(localStorage.getItem('role'));
      console.log(role);
    const tokenExpiry = localStorage.getItem('tokenExpiry');

    if ((adminToken || userToken) && Date.now() < tokenExpiry) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    localStorage.removeItem('userToken');
    localStorage.removeItem('tokenExpiry');
    setIsAuthenticated(false);
    window.location.href = "/login"; // Redirect to login
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box display="flex" width="100%" height="100vh" overflow="hidden">
            {isAuthenticated ? (
              <>
                {role === "ADMIN" ? (
                  <Sidebar isSidebar={isSidebar} />
                ) : role === "AUTHOR" ? (
                  <AuthorSidebar isSidebar={isSidebar} />
                ) : role === "MODERATOR" ? (
                  <Moderator isSidebar={isSidebar} />
                ) : null}
  
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    height: "100vh",
                    padding: "20px",
                  }}
                >
                  <Topbar setIsSidebar={setIsSidebar} onLogout={handleLogout} />
  
                  <Box sx={{ flexGrow: 1 }}>
                    <Routes>
{/* user */}
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/add-user" element={<RegisterUserForm />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/edit-user/:id" element={<EditUserForm />} />
                      <Route path="/authors" element={<AuthorsList />} />
                      <Route path="/verified-users/" element={<VerifiedUsers />} />
                      <Route path="/sales-referal-order/:id" element={<SalesReferralOrder />} />
                      {/* Book Routes */}
                      <Route path="/add-book" element={<RegisterBookForm />} />
                      <Route path="/books" element={<Books />} />
                      <Route path="/edit-book/:id" element={<EditBookForm />} />
                      <Route path="/book-detail/:id" element={<BookDetail />} />
                      <Route path="/book-filter/" element={<BookFilter />} />
                      <Route path="/book-sold/" element={<MostSoldBooks />} />
                      <Route path="/most-reviewed/" element={<MostReviewed />} />
                      <Route path="/top-rated/" element={<TopRated />} />
                      <Route path="/today-book/" element={<TodayBook />} />
                      <Route path="/last7days-books/" element={<Last7Days />} />
                      <Route path="/books-by-status/" element={<BookByStatus />} />
                      <Route path="/update-status/:id" element={<UpdateStatus />} />
                      <Route path="/author-books/:id" element={<AuthorBooks />} />
                      <Route path="/author-approved-books/:id" element={<ApprovedBooks />} />
                      <Route path="/approved-book-order/:id" element={<BookApprovedOrder />} />
                      <Route path="/all-review" element={<ReviewsTable />} />
                   
  {/* Author Routes */}
                      <Route path="/logged-author-bystatus" element={<BookByStatuse />} />
                      <Route path="/allbooks-logged-author" element={<AllAuthorBooks />} />
                      <Route path="/all-approved-books" element={<AllApprovedAuthorBooks />} />
                      <Route path="/others-book" element={<BooksTable />} />
                      <Route path="/review/:bookId" element={<ReviewForm />} />
                      <Route path="/review-all" element={<ReviewsAll />} />
                      <Route path="/review-feedback/:id" element={<ReviewFeedbackForm />} />
                      <Route path="/review-id/:booksId" element={<BookReviews />} />
                      <Route path="/update/:id" element={<UpdateReview />} />


                     {/* Audio Routes */}
                     <Route path="/audio" element={<AudioListPage />} />
                      <Route path="/book-audio/:id" element={<AudioFilesPage />} />
                      <Route path="/upload-audio/:bookId" element={<UploadAudio />} />

                      {/* Order Routes */}
                      <Route path="/orders/" element={<OrderStatusFilter />} />
                      <Route path="/orders-by-status/" element={<OrderStatusFilter />} />
                      <Route path="/today-orders/" element={<TodayOrder />} />
                      <Route path="/last7days-orders/" element={<Last7DaysOrder />} />
                      <Route path="/search-orders/" element={<SearchOrder />} />
                      {/* communication routes */}
                      

                      <Route path="/communication/" element={<CommunicationPage/>} />
                      <Route path="/author-proposal/" element={<CommunicationPageAuthor/>}/>
                      <Route path="/author-proposals/" element={<ProposalPage />}/>
                      <Route path="/viewMessage/" element={<Messages/>} />
                      <Route path="/get-message/" element={<AdminMessage/>} />
                      <Route path="/notagreed/" element={<NotAgreedCommunications/>}/>
                      <Route path="/create-announcement/" element={<CreateAnnouncement/>}/>
                      <Route path="/announcement/" element={<Announcements/>}/>
                      <Route path="/comments/:id" element={<Comments />} />
                      <Route path="/my-announcement/" element={<MyAnnouncements/>}/>
                      {/* <Route path="/update/" element={<UpdateCommunication/>} /> */}
  
                      {/* Reports */}
                      <Route path="/sales-report-bybook/:id" element={<SalesReportByBook />} />
                      <Route path="/my-books-report/" element={<MyBookReport />} />
                      <Route path="/book-sales-by-author/:id/" element={<BookSalesByAuthor />} />
                      <Route path="/top-sales/" element={<TopSale />} />
                      <Route path="/top-sellers/" element={<TopSellers />} />
                      <Route path="/top-buyers/" element={<TopBuyers />} />
                      <Route path="/last-7days-sales/" element={<Last7DaysSales />} />
                      <Route path="/sold-report/" element={<SoldReport />} />
                      <Route path="/by-book-date/" element={<ReportByBookAndDate />} />
                      <Route path="/subscription-revenu-report/" element={<SubscriptionRevenue />} />
  
                      {/* Subscription */}
                      <Route path="/add-subscription/" element={<AddSubscriptionForm />} />
                      <Route path="/manage-subscription/" element={<SubscriptionManagement />} />
                      <Route path="/subscription-order/" element={<SubscriptionOrders />} />
                      <Route path="/subscription-triers/" element={<RegisterTier />} />
                      <Route path="/manage_subscription-triers/" element={<SubscriptionTiers />} />
                      <Route path="/user_subscriptions/" element={<SubscriptionTable />} />
  
                      {/* Settings */}
                      <Route path="/add-category/" element={<Category />} />
                      <Route path="/level/" element={<LevelComponent />} />
                      <Route path="/account/" element={<Account />} />
                      <Route path="/add-role/" element={<CreateRoleForm />} />
                      <Route path="/manage-role/" element={<MangeRole />} />
                      <Route path="qaulity-guidelines/" element={<QualityGuidelines />} />                     
  
                      {/* Not Found Page */}  
                    </Routes>
                  </Box>
                </Box>
              </>
            ) : (
              <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/admin-login" element={<LoginPage />} />
                <Route path="/author-login" element={<AuthorLogin />} />
                {/* Redirect to login for all other routes */}
              </Routes>
            )}
          </Box>
        </Router>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
  
     
}

export default App;
