import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container,
    Paper, TablePagination, TextField, CircularProgress, TableSortLabel, 
    Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, FormControl, InputLabel, InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomModal from '../../../components/modal';

const Message = () => {
    const [communications, setCommunications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [sortConfig, setSortConfig] = useState({ key: 'serviceCharge', direction: 'asc' });
    const [openModal, setOpenModal] = useState(false);
    const [selectedComm, setSelectedComm] = useState(null);
    const [isAgreed, setIsAgreed] = useState('');
    const [reason, setReason] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        fetchCommunications();
    }, []);
    const handleCloseModal = () => {
        setModalOpen(false);
      };
    const fetchCommunications = async () => {
        setLoading(true);
        setError(null);
        
        try {
            const userToken = localStorage.getItem('userToken');
            const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/comm/logged-author', {
                headers: { Authorization: `Bearer ${userToken}` }
            });

            if (response.data.succss) {
                setCommunications(response.data.communications);
                setModalOpen(true); // Open the modal after success
            } else {
                setError("Failed to fetch data");
                setModalOpen(true); // Open the modal after success
            }
        } catch (err) {
            setError(err.message);
            setModalOpen(true); // Open the modal after success
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (column) => {
        const isAsc = sortConfig.key === column && sortConfig.direction === 'asc';
        setSortConfig({ key: column, direction: isAsc ? 'desc' : 'asc' });
    };

    const openEditModal = (comm) => {
        setSelectedComm(comm);
        setIsAgreed(comm.isAgreed ? 'Agree' : 'Disagree');
        setReason(comm.reason || '');
        setOpenModal(true);
    };

    const closeModal = () => {
        setOpenModal(false);
        setSelectedComm(null);
        setIsAgreed('');
        setReason('');
    };

    const handleSave = async () => {
        if (!selectedComm) return;

        try {
            const userToken = localStorage.getItem('userToken');
            await axios.put(`https://bookbackend3.bruktiethiotour.com/api/comm/change-agreement/${selectedComm.id}`, {
                reason,
                isAgreed: isAgreed === 'Agree'
            }, {
                headers: { Authorization: `Bearer ${userToken}` }
            });
            //  if(response.status === 200){
            //     setSuccessMessage('Status Changed Successfuly');
            //     setModalOpen(true); // Open the modal after success
            //  }else{
            //     setError('Please try again');
            //     setModalOpen(true); 
            //  }
            // Update local state after successful update
            setCommunications((prev) => prev.map((comm) => 
                comm.id === selectedComm.id ? { ...comm, isAgreed: isAgreed === 'Agree', reason } : comm
            ));

            closeModal();
        } catch (err) {
            setError("Failed to update agreement status");
        }
    };

    const sortedCommunications = [...communications].sort((a, b) => {
        if (sortConfig.key === 'serviceCharge') {
            return sortConfig.direction === 'asc' ? a.serviceCharge - b.serviceCharge : b.serviceCharge - a.serviceCharge;
        } else if (sortConfig.key === 'message') {
            return sortConfig.direction === 'asc'
                ? a.message.localeCompare(b.message)
                : b.message.localeCompare(a.message);
        } else if (sortConfig.key === 'reviewedBy') {
            return sortConfig.direction === 'asc'
                ? a.reviewedBy.localeCompare(b.reviewedBy)
                : b.reviewedBy.localeCompare(a.reviewedBy);
        } else if (sortConfig.key === 'title') {
            return sortConfig.direction === 'asc'
                ? a.comBook.title.localeCompare(b.comBook.title)
                : b.comBook.title.localeCompare(a.comBook.title);
        }
        return 0;
    });

    const filteredCommunications = sortedCommunications.filter((comm) => 
        comm.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
        comm.comBook.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Container sx={{ backgroundColor: '#141B2D'}}>
            <h1 sx={{marginTop:14}}>Communications</h1>
            <TextField
                label="Search"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchQuery}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  marginBottom: 1,
                  width: 200, // Minimize the search bar
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  marginRight: 5,
                  marginTop: 10,
                }}
            />
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <>
                    <TableContainer>
                        <Table >
                            <TableHead sx={{ backgroundColor: '#3E4396', color: '#fff' }}>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'message'}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort('message')}
                                        >
                                            Message
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'serviceCharge'}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort('serviceCharge')}
                                        >
                                            Service Charge
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'reviewedBy'}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort('reviewedBy')}
                                        >
                                            Reviewed By
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Reason</TableCell>
                                    <TableCell>Is Agreed</TableCell>
                                    <TableCell>Book Title</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredCommunications
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((comm) => (
                                        <TableRow key={comm.id}>
                                            <TableCell>{comm.message}</TableCell>
                                            <TableCell>{comm.serviceCharge}</TableCell>
                                            <TableCell>{comm.reviewedBy}</TableCell>
                                            <TableCell>{comm.reason || 'N/A'}</TableCell>
                                            <TableCell>{comm.isAgreed ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{comm.comBook.title}</TableCell>
                                            <TableCell>
                                                <Button variant="contained" color="primary" onClick={() => openEditModal(comm)}>
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={filteredCommunications.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        sx={{ backgroundColor: '#3E4396', color: '#fff' }}
                    />
                </>
            )}

            {/* Modal for editing isAgreed status */}
            <Dialog open={openModal} onClose={closeModal}>
                <DialogTitle>Edit Agreement Status</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Is Agreed</InputLabel>
                        <Select
                            value={isAgreed}
                            onChange={(e) => setIsAgreed(e.target.value)}
                        >
                            <MenuItem value="Agree">Agree</MenuItem>
                            <MenuItem value="Disagree">Disagree</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Reason"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary" variant="contained" >Save</Button>
                    <Button onClick={closeModal} color={'secondary'} variant="outdline">Cancel</Button>
                </DialogActions>
            </Dialog>
 {/* Success Message */}
 {successMessage && (
      
      <CustomModal
      open={modalOpen}
      onClose={handleCloseModal}
      severity={'success'}
      message={successMessage}
    />
    )}
     {error && (
    
    <CustomModal
    open={modalOpen}
    onClose={handleCloseModal}
    severity={'error'}
    message={error}
  />
  )}

        </Container>
    );
};

export default Message;
