import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAudioFilesByBookId, deleteAudioFileById } from '../../redux/slices/audioSlice';
import { useParams } from 'react-router-dom';
import { Box, Paper, IconButton, Typography, Snackbar, Alert } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DeleteIcon from '@mui/icons-material/Delete';

const AudioFilesPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();        // Destructure the 'id' from params
  const bookId = Number(id)  
  const { items: audioFiles, loading, error } = useSelector(state => state.audio);

  const [audioFile, setAudioFiles] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudioUrl, setCurrentAudioUrl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const audioRef = useRef(new Audio());

  useEffect(() => {
    if (bookId) {
      dispatch(fetchAudioFilesByBookId(bookId))
    
    }
  }, [dispatch, bookId]);
  
  const handlePlayPauseAudio = (url) => {
    if (currentAudioUrl === url && isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      if (currentAudioUrl !== url) {
        audioRef.current.src = `https://bookbackend3.bruktiethiotour.com/${url}`;
        setCurrentAudioUrl(url);
      }
      audioRef.current.play();
      setIsPlaying(true);
      
      audioRef.current.onended = () => {
        setIsPlaying(false);
      };
    }
  };

  const handleDeleteAudio = async (id) => {
    if (window.confirm("Are you sure you want to delete this audio file?")) {
      const response = await dispatch(deleteAudioFileById(id));
      
      if (deleteAudioFileById.fulfilled.match(response)) {
        setSnackbarOpen(true);
      } else {
        console.error("Failed to delete audio file:", response.error);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = [
  { field: 'episode', headerName: 'Episode', flex: 1 },
//   { field: 'bookTitle', headerName: 'Book Title', flex: 1 }, // This now works
  { field: 'createdAt', headerName: 'Created At', flex: 1, type: 'date' },
  {
    field: 'playPause',
    headerName: 'Play/Pause',
    flex: 1,
    renderCell: (params) => (
      <IconButton onClick={() => handlePlayPauseAudio(params.row.url)}>
        {isPlaying && currentAudioUrl === params.row.url ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
    ),
  },
  {
    field: 'Actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => (
      <IconButton onClick={() => handleDeleteAudio(params.row.id)} color="error">
        <DeleteIcon />
      </IconButton>
    ),
  },
];


  return (
    <Box display="flex" justifyContent="center" p={3} width="100%">
      <Box maxWidth="100%" width="100%">
        <Typography variant="h4" gutterBottom color="primary" sx={{ color: 'white', marginBottom: 10 }}>Audio Files</Typography>
        
        {loading ? (
          <Typography>Loading...</Typography>
        ) : error ? (
          <Alert severity="error">{String(error.message)}</Alert>
        ) : (
          <Paper
            elevation={4}
            sx={{
              backgroundColor: '#141B2D',
              color: 'white',
              '& .MuiDataGrid-root': {
                border: 'none',
                color: 'white',
              },
              '& .MuiDataGrid-cell': {
                color: 'white',
                borderBottom: '1px solid white',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#3E4396',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: '#3E4396',
                color: 'white',
              },
            }}
          >
            <DataGrid
              rows={audioFiles}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 25]}
              autoHeight
              getRowId={(row) => row.id}
            />
          </Paper>
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Audio deleted successfully!
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default AudioFilesPage;
