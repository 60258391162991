import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import axios from "axios";

const CreateAnnouncement = () => {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
  });
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");

  const token = localStorage.getItem("adminToken"); // Retrieve token from local storage

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://bookbackend3.bruktiethiotour.com/api/announcement/create",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackMessage("Announcement created successfully!");
      setSnackSeverity("success");
      setFormData({ title: "", content: "" }); // Reset form
    } catch (error) {
      setSnackMessage("Failed to create announcement!");
      setSnackSeverity("error");
    } finally {
      setSnackOpen(true);
      setLoading(false);
    }
  };

  return (
    <div>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          maxWidth: "500px",
          margin: "auto",
          padding: "20px",
          boxShadow: 3,
          borderRadius: 2,
          background: "linear-gradient(135deg, #008080, #252B31FF)", // Blue-green gradient
          color: "white", // Text color
        }}
      >
        <Typography
          variant="h5"
          mb={2}
          sx={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Create Announcement
        </Typography>
        <TextField
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
          InputLabelProps={{
            style: { color: "white" }, // White label
          }}
          InputProps={{
            style: { color: "white", borderBottom: "1px solid white" }, // White input text
          }}
        />
        <TextField
          label="Content"
          name="content"
          value={formData.content}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
          multiline
          rows={4}
          InputLabelProps={{
            style: { color: "white" }, // White label
          }}
          InputProps={{
            style: { color: "white", borderBottom: "1px solid white" }, // White input text
          }}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#00CED1", // Teal color
            color: "white",
            "&:hover": {
              backgroundColor: "#20B2AA", // Slightly darker teal on hover
            },
            marginTop: 2,
          }}
          fullWidth
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Box>

      {/* Snackbar for success or failure */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert
          onClose={() => setSnackOpen(false)}
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateAnnouncement;
