import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Grid,
} from '@mui/material';

const BookFilter = () => {
  const [filterData, setFilterData] = useState({
    title: '',
    author: '',
    category: '',
    sortBy: '',
    publicationYear: '',
    language: '',
    minPrice: '',
    maxPrice: '',
    page: 1,
    size: 10,
  });

  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  
  // Retrieve token from local storage
  const token=localStorage.getItem('adminToken');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/book/filter', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filterData,
        },
      });
      setBooks(response.data.books);
      setTotalItems(response.data.totalItems);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while fetching data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" mb={2}>Filter Books</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Title"
              name="title"
              value={filterData.title}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Author"
              name="author"
              value={filterData.author}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Category"
              name="category"
              value={filterData.category}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextField
              label="Sort By"
              name="sortBy"
              value={filterData.sortBy}
              onChange={handleChange}
              fullWidth
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Publication Year"
              name="publicationYear"
              value={filterData.publicationYear}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Language"
              name="language"
              value={filterData.language}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Min Price"
              name="minPrice"
              value={filterData.minPrice}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Max Price"
              name="maxPrice"
              value={filterData.maxPrice}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Page"
              name="page"
              type="number"
              value={filterData.page}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Size"
              name="size"
              type="number"
              value={filterData.size}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Button variant="contained" type="submit" sx={{ mt: 2 }}>Search</Button>
      </form>

      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {books.length > 0 && (
        <Box mt={3}>
          <Typography variant="h6">Total Books Found: {totalItems}</Typography>
          {books.map((book, index) => (
            <Box key={index} sx={{ border: '1px solid #ccc', borderRadius: '4px', p: 2, mb: 2 }}>
              <Typography variant="h6">{book.title}</Typography>
              <Typography variant="body1">Author: {book.author}</Typography>
              <Typography variant="body2">Category: {book.category}</Typography>
              <Typography variant="body2">Publication Year: {book.publicationYear}</Typography>
              <Typography variant="body2">Price: {book.price}</Typography>
              {/* Add other book fields as necessary */}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default BookFilter;
