import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const Account = () => {
    const [accounts, setAccounts] = useState([]);
    const [formData, setFormData] = useState({ name: '', number: '' });
    const [editMode, setEditMode] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const apiUrl = 'https://bookbackend3.bruktiethiotour.com/api/account';

    // Retrieve token from localStorage
    const adminToken = localStorage.getItem('adminToken');

    // Axios instance with token header
    const axiosInstance = axios.create({
        headers: { Authorization: `Bearer ${adminToken}` }
    });

    // Fetch accounts
    const fetchAccounts = async () => {
        try {
            const response = await axiosInstance.get(apiUrl);
            setAccounts(response.data.data || []);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    useEffect(() => {
        fetchAccounts();
    }, []);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission for creating or updating account
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (editMode) {
                // Update account
                await axiosInstance.put(`${apiUrl}/${selectedAccountId}`, formData);
                setEditMode(false);
                setSelectedAccountId(null);
            } else {
                // Create new account
                await axiosInstance.post(apiUrl, formData);
            }

            setFormData({ name: '', number: '' });
            fetchAccounts(); // Refresh accounts list
        } catch (error) {
            console.error('Error creating/updating account:', error);
        }
    };

    // Handle edit
    const handleEdit = (account) => {
        setFormData({ name: account.name, number: account.number });
        setEditMode(true);
        setSelectedAccountId(account.id);
    };

    // Handle delete
    const handleDelete = async (accountId) => {
        try {
            await axiosInstance.delete(`${apiUrl}/${accountId}`);
            fetchAccounts(); // Refresh accounts list
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    return (
        <Box sx={{ padding: '20px' }}>
            <h2>{editMode ? 'Edit Account' : 'Register Account'}</h2>

            <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    style={{ marginRight: '10px' }}
                />
                <TextField
                    label="Number"
                    name="number"
                    value={formData.number}
                    onChange={handleInputChange}
                    required
                    style={{ marginRight: '10px' }}
                />
                <Button variant="contained" color="primary" type="submit">
                    {editMode ? 'Update Account' : 'Create Account'}
                </Button>
            </form>

            <h2>Account List</h2>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accounts.map((account) => (
                            <TableRow key={account.id}>
                                <TableCell>{account.id}</TableCell>
                                <TableCell>{account.name}</TableCell>
                                <TableCell>{account.number}</TableCell>
                                <TableCell>{new Date(account.createdAt).toLocaleString()}</TableCell>
                                <TableCell>{new Date(account.updatedAt).toLocaleString()}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" onClick={() => handleEdit(account)} style={{ marginRight: '10px' }}>
                                        Edit
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={() => handleDelete(account.id)}>
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Account;
