import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, FormControl, Select, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";

const BooksTable = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [selectedAction, setSelectedAction] = useState("");

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const userId = localStorage.getItem("userId"); // Replace with your token
        const response = await axios.get(
          `https://bookbackend3.bruktiethiotour.com/api/book/all-book-except/${userId}`     );
        setBooks(response.data.books);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBooks();
  }, []);

  // const handleActionChange = (id, action) => {
  //   console.log(`Action "${action}" triggered for book ID: ${id}`);
  // };

  const columns = [
    { field: "title", headerName: "Title", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    { field: "author", headerName: "Author", flex: 1 },
    { field: "publicationYear", headerName: "Publication Year", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => navigate(`/review/${params.row.id}`)}
              >
                Review this book
              </Button>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="75vh"
      >
        Loading...
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="75vh"
      >
        <p style={{ color: "red" }}>{error}</p>
      </Box>
    );
  }

  return (
    <Box
      mt={3}
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": { border: "none" },
        "& .MuiDataGrid-cell": { borderBottom: "none" },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      <DataGrid
        rows={books}
        columns={columns}
        getRowId={(row) => row.id}
        autoHeight
        pageSize={10}
      />
    </Box>
  );
};

export default BooksTable;
