import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';
import axios from 'axios';
import Loading from '../../components/loading';

const validTypes = ['PENDING', 'PROCESSING', 'APPROVED', 'REJECTED'];

const UpdateStatus = () => {
  const { id } = useParams();
  const [status, setStatus] = useState('PENDING');
  const [serviceCharge, setServiceCharge] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [initialStatus, setInitialStatus] = useState('');
  const [books, setBooks] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [fetchError, setFetchError] = useState('');
  const [selectedBook, setSelectedBook] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // Fetch the initial data
    const fetchBookStatus = async () => {
      try {
        const response = await axios.get(`https://bookbackend3.bruktiethiotour.com/api/book/by-id/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`, // Include the token in the request
          },
        });
        if (response.data.success) {
          setInitialStatus(response.data.books[0].status); // Assuming the API returns the status in this format
          setStatus(response.data.books[0].status);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError('Error fetching the book status.');
      }
    };

    fetchBookStatus();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    const data = {
      status,
      serviceCharge: status === 'APPROVED' ? '' : serviceCharge, // Clear service charge if status is 'APPROVED'
    };

    try {
      const response = await axios.put(`https://bookbackend3.bruktiethiotour.com/api/book/update-status-and-charge/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`, // Include the token in the request
        },
      });
      if (response.data.success) {
        alert('Status updated successfully!');
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      console.log(err);
      setError('An error occurred while updating the status.');
    } finally {
      setLoading(false);
    }
  };

  const handleFetchByStatus = async () => {
    setFetchLoading(true);
    setFetchError('');

    try {
      const response = await axios.get(`https://bookbackend3.bruktiethiotour.com/api/book/by-status/${status}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`, // Include the token in the request
        },
      });
      if (response.data.success) {
        setBooks(response.data.books); // Store the fetched books
      } else {
        setFetchError(response.data.message);
      }
    } catch (err) {
      console.log(err);
      setFetchError('Error fetching books by status.');
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOpenModal = (book) => {
    setSelectedBook(book);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedBook(null);
    setModalOpen(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      <form onSubmit={handleSubmit}>
        <Select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          variant="outlined"
          style={{ marginBottom: '20px', width: '200px' }}
        >
          {validTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>

        <TextField
          type="number"
          label="Service Charge"
          value={serviceCharge}
          onChange={(e) => setServiceCharge(e.target.value)}
          variant="outlined"
          style={{ marginBottom: '20px', marginLeft: '20px' }}
        />

        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Update Status'}
        </Button>

        <Button
          onClick={handleFetchByStatus}
          variant="contained"
          color="secondary"
          disabled={fetchLoading}
          style={{ marginLeft: '20px' }}
        >
          {fetchLoading ? <Loading /> : 'Fetch by Status'}
        </Button>

        {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
        {fetchError && <Typography color="error" style={{ marginTop: '20px' }}>{fetchError}</Typography>}
      </form>

      <div style={{ marginTop: '20px' }}>
        <Typography variant="h6">Fetched Books:</Typography>
        <Grid container spacing={2}>
          {books.length > 0 ? (
            books.map((book) => (
              <Grid item xs={12} sm={6} md={4} key={book.id}>
                <Card style={{ marginBottom: '20px' }}>
                  <CardContent>
                    <Typography variant="h6">{book.title}</Typography>
                    <Typography color="textSecondary">{book.author}</Typography>
                    <Typography variant="body2">Status: {book.status}</Typography>
                    <Typography variant="body2">Service Charge: {book.serviceCharges}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                      View Details
                    </Button>
                    <Button size="small" color="secondary" onClick={() => handleOpenModal(book)}>
                      Action
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            !fetchLoading && <Typography>No books found for the selected status.</Typography>
          )}
        </Grid>
      </div>

      {/* Modal for Book Details */}
      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle style={{ backgroundColor: '#3f51b5', color: '#fff' }}>{selectedBook?.title}</DialogTitle>
        <DialogContent style={{ backgroundColor: '#0B0B0BFF' }}>
          {selectedBook && (
            <Box padding={2}>
              <Typography variant="h6" style={{ color: '#3f51b5' }}>Details</Typography>
              <Typography variant="body1"><strong>Description:</strong> {selectedBook.description}</Typography>
              <Typography variant="body1"><strong>Author:</strong> {selectedBook.bookAuthor?.fname} {selectedBook.bookAuthor?.lname}</Typography>
              <Typography variant="body1"><strong>Publication Year:</strong> {selectedBook.publicationYear}</Typography>
              <Typography variant="body1"><strong>Language:</strong> {selectedBook.language}</Typography>
              <Typography variant="body1"><strong>Price:</strong> {selectedBook.price}</Typography>
              <Typography variant="body1"><strong>Rating:</strong> {selectedBook.rating} ({selectedBook.rateCount} ratings)</Typography>
              <Typography variant="body1"><strong>Pages:</strong> {selectedBook.pages}</Typography>
              <Typography variant="body1"><strong>Sold:</strong> {selectedBook.sold}</Typography>
              <Typography variant="body1"><strong>Service Charges:</strong> {selectedBook.serviceCharges}</Typography>
              <Typography variant="body1"><strong>Revenue:</strong> {selectedBook.revenue}</Typography>
              <img src={`https://your-domain.com/${selectedBook.imageFilePath}`} alt={selectedBook.title} style={{ maxWidth: '100%', marginTop: '10px', borderRadius: '4px' }} />
              <Typography variant="body2" style={{ marginTop: '10px' }}><strong>PDF:</strong> <a href={`https://your-domain.com/${selectedBook.pdfFilePath}`} target="_blank" rel="noopener noreferrer">Download PDF</a></Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#3f51b5' }}>
          <Button onClick={handleCloseModal} style={{ color: '#fff' }}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateStatus;
