import { Box, Grid, Card, CardContent, Typography, Modal, CircularProgress, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApprovedBooksLoggedAuthor } from "../../redux/slices/authorSlice";
import Loading from "../../components/loading";
import ErrorComponent from "../../components/error";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";

const AllApprovedAuthorBooks = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const { items: books, loading, error } = useSelector((state) => state.authors);

  const [open, setOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);

  const handleOpen = (book) => {
    setSelectedBook(book);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBook(null);
  };

  const handleView = (book) => {
    navigate(`/book-detail/${book.id}`, { state: { bookData: book } });
  };

  const columns = [
    { field: "title", headerName: "Title", flex: 1 },
    { field: "author", headerName: "Author", flex: 1 },
    { field: "publicationYear", headerName: "Publication Year", flex: 1 },
    { field: "language", headerName: "Language", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => handleView(params.row)}
        >
          View
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchApprovedBooksLoggedAuthor());
  }, [dispatch]);

  return (
    <Box display="flex" justifyContent="center" p={3} width="100%">
      <Box maxWidth="100%" width="100%">
        <Header title="APPROVED BOOKS" subtitle="List of Approved Books" />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
            <Loading />
          </Box>
        ) : error ? (
          <ErrorComponent
            message="Oops! Something went wrong. Please try again."
            showIcon={true}
            severity="error"
          />
        ) : (
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
                "& .MuiDataGrid-root": { border: "none" },
                "& .MuiDataGrid-cell": { borderBottom: "none" },
                "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[100]} !important` },
              }}
          >
            <DataGrid
              rows={books}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              autoHeight
            />
          </Box>
        )}

        {/* Modal for Viewing Book Details */}
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            {selectedBook ? (
              <>
                <Card variant="outlined" sx={{ margin: 2 }}>
                  <CardContent>
                    <Typography variant="h6" component="h2" gutterBottom>
                      Book Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <strong>Title:</strong> {selectedBook.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <strong>Author:</strong> {selectedBook.author}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <strong>Publication Year:</strong> {selectedBook.publicationYear}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <strong>Language:</strong> {selectedBook.language}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <strong>Price:</strong> ${selectedBook.price}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <strong>Status:</strong> {selectedBook.status}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Button variant="contained" color="error" onClick={handleClose} sx={{ mt: 2 }}>
                  Close
                </Button>
              </>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default AllApprovedAuthorBooks;
