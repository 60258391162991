import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, MenuItem, Button, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Loading from "../../components/loading";

import ErrorComponent from "../../components/error";
import { fetchBooksByStatus } from "../../redux/slices/authorSlice";

const BookByStatus = () => {
  const [status, setStatus] = useState("PENDING");
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  // Extract authors, loading, and error states from the Redux store
  const { items: authors, loading, error } = useSelector((state) => state.authors);

  // Update status state on change
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  // Dispatch fetchBooksByStatus action with the selected status
  const handleFetchBooks = () => {
    dispatch(fetchBooksByStatus(status));
  };

  // Define columns for the DataGrid
  const columns = [
    { field: "title", headerName: "Title", flex: 1 },
    { field: "author", headerName: "Author", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "publicationYear", headerName: "Publication Year", flex: 1 },
  ];

  return (
    <Box p={3} width="100%">
      {/* Status Selection and Fetch Button */}
      <Box display="flex" gap={2} mb={3}>
        <Select
          value={status}
          onChange={handleStatusChange}
          variant="outlined"
          style={{ width: "200px" }}
        >
          {["PENDING", "PROCESSING", "APPROVED", "REJECTED"].map((statusOption) => (
            <MenuItem key={statusOption} value={statusOption}>
              {statusOption}
            </MenuItem>
          ))}
        </Select>
        <Button variant="contained" color="primary" onClick={handleFetchBooks}>
          Fetch Books
        </Button>
      </Box>

      {/* Loading and Error States */}
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
          <Loading />
        </Box>
      )}
      {error && (
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
      )}

      {/* Books Table */}
      {!loading && !error && (
        <Box
          mt={3}
          height="75vh"
          sx={{
                "& .MuiDataGrid-root": { border: "none" },
                "& .MuiDataGrid-cell": { borderBottom: "none" },
                "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[100]} !important` },
              }}
        >
          <DataGrid
            rows={authors}
            columns={columns}
            getRowId={(row) => row.id}
            autoHeight
            pageSize={10}
          />
        </Box>
      )}
    </Box>
  );
};

export default BookByStatus;
