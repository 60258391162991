import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
const TopBuyers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('orderUser.fname');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token=localStorage.getItem("adminToken");
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/sales/books/reports/top-buyer',
          {          
            headers: {
              Authorization: `Bearer ${token}`,
            },          
        });
        if (response.data && Array.isArray(response.data)) {
          setData(response.data);
        } else {
          console.error('Unexpected API response:', response.data);
          setError('No data found or response format is incorrect.');
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['User Name', 'Total Spent', 'Max Spent', 'Min Spent', 'Average Spent', 'Total Orders']],
      body: data.map(item => [
        item.orderUser.fname,
        item.totalSpent,
        item.maxSpent,
        item.minSpent,
        item.averageSpent,
        item.totalOrders,
      ]),
    });
    doc.save('spent.pdf');
  };

  const filteredData = data.filter(item =>
    item.orderUser.fname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedData = filteredData.sort((a, b) => {
    if (sortDirection === 'asc') {
      return a[sortColumn] < b[sortColumn] ? -1 : 1;
    }
    return a[sortColumn] > b[sortColumn] ? -1 : 1;
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>
        <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
    
    </div>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Spending Reports
      </Typography>
      <TextField
        label="Search by User Name"
        variant="outlined"
        fullWidth
        style={{ marginBottom: '20px' }}
        onChange={handleSearch}
      />
      <Button variant="contained" color="primary" onClick={exportToPDF} style={{ marginBottom: '20px' }}>
        Export to PDF
      </Button>
      <CSVLink
        data={sortedData.map(item => ({
          UserName: item.orderUser.fname,
          TotalSpent: item.totalSpent,
          MaxSpent: item.maxSpent,
          MinSpent: item.minSpent,
          AverageSpent: item.averageSpent,
          TotalOrders: item.totalOrders,
        }))}
        filename="spent.csv"
        className="btn btn-primary"
        target="_blank"
      >
        Export to Excel
      </CSVLink>
      <TableContainer  sx={{ backgroundColor: '#141B2D', marginTop: 10}} component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'orderUser.fname'}
                  direction={sortColumn === 'orderUser.fname' ? sortDirection : 'asc'}
                  onClick={() => handleSort('orderUser.fname')}
                >
                  User Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'totalSpent'}
                  direction={sortColumn === 'totalSpent' ? sortDirection : 'asc'}
                  onClick={() => handleSort('totalSpent')}
                >
                  Total Spent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'maxSpent'}
                  direction={sortColumn === 'maxSpent' ? sortDirection : 'asc'}
                  onClick={() => handleSort('maxSpent')}
                >
                  Max Spent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'minSpent'}
                  direction={sortColumn === 'minSpent' ? sortDirection : 'asc'}
                  onClick={() => handleSort('minSpent')}
                >
                  Min Spent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'averageSpent'}
                  direction={sortColumn === 'averageSpent' ? sortDirection : 'asc'}
                  onClick={() => handleSort('averageSpent')}
                >
                  Average Spent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'totalOrders'}
                  direction={sortColumn === 'totalOrders' ? sortDirection : 'asc'}
                  onClick={() => handleSort('totalOrders')}
                >
                  Total Orders
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.orderUser.fname}</TableCell>
                <TableCell>{row.totalSpent}</TableCell>
                <TableCell>{row.maxSpent}</TableCell>
                <TableCell>{row.minSpent}</TableCell>
                <TableCell>{row.averageSpent}</TableCell>
                <TableCell>{row.totalOrders}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TopBuyers;
