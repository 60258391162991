import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, TextField, CircularProgress } from '@mui/material';
import jwtDecode from "jwt-decode";

import { useParams } from 'react-router-dom';

const UpdateReviewModal = ({ open, handleClose, bookId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [reviewData, setReviewData] = useState({
        comment: '',
        rating: 5,
        book_id: bookId,
    });
    const {id} =  useParams()// get the review ID from the URL params or props

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        const userToken = localStorage.getItem('userToken');
        if (!userToken) {
            setError('User not authenticated');
            setLoading(false);
            return;
        }

        const { user_id } = jwtDecode(userToken);

        axios.get(`https://bookbackend3.bruktiethiotour.com/api/review/${id}`)
            .then(response => {
                if (response.data.success) {
                    const review = response.data.review;
                    setReviewData({
                        comment: review.comment,
                        rating: review.reviewRating,
                        book_id: review.book_id,
                    });
                    setIsEditable(user_id === review.user_id);
                }
                setLoading(false);
            })
            .catch(err => {
                setError('Failed to fetch review');
                setLoading(false);
            });
    }, [bookId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReviewData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isEditable) {
            alert("You can't update this book's review.");
            return;
        }

        axios.post(`https://bookbackend3.bruktiethiotour.com/api/review/update/${id}`, reviewData)
            .then(response => {
                if (response.data.success) {
                    alert('Successfully updated the book\'s review.');
                    handleClose(); // Close modal after successful update
                }
            })
            .catch(err => {
                setError('Failed to update review');
            });
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <div style={{ padding: '20px' }}>
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            name="comment"
                            label="Comment"
                            value={reviewData.comment}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <TextField
                            name="rating"
                            label="Rating"
                            type="number"
                            value={reviewData.rating}
                            onChange={handleChange}
                            fullWidth
                            required
                            inputProps={{ min: 1, max: 5 }}
                        />
                        <Button type="submit" variant="contained" color="primary">
                            Update Review
                        </Button>
                    </form>
                )}
            </div>
        </Modal>
    );
};

export default UpdateReviewModal;