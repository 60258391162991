import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Modal, Box, Typography } from '@mui/material';

const SubscriptionTable = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Fetch data from the API
    axios.get('https://bookbackend3.bruktiethiotour.com/api/subscriptions')
      .then(response => {
        setSubscriptions(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching subscriptions:', error);
      });
  }, []);

  const handleEdit = (subscription) => {
    setSelectedSubscription(subscription);
  };

  const handleDelete = (subscriptionId) => {
    axios.delete(`https://bookbackend3.bruktiethiotour.com/api/subscriptions/${subscriptionId}`)
      .then(response => {
        setModalMessage('Subscription deleted successfully!');
        setOpenModal(true);
        setSubscriptions(subscriptions.filter(sub => sub.id !== subscriptionId));
      })
      .catch(error => {
        setModalMessage('Error deleting subscription!');
        setOpenModal(true);
      });
  };

  const handleApprovalChange = (newStatus) => {
    const updatedSubscription = { ...selectedSubscription, approval_status: newStatus };
    axios.put(`https://bookbackend3.bruktiethiotour.com/api/subscriptions/${selectedSubscription.id}`, updatedSubscription)
      .then(response => {
        setModalMessage('Subscription updated successfully!');
        setOpenModal(true);
        setSubscriptions(subscriptions.map(sub => sub.id === selectedSubscription.id ? updatedSubscription : sub));
      })
      .catch(error => {
        setModalMessage('Error updating subscription!');
        setOpenModal(true);
      });
    setSelectedSubscription(null);
  };

  const handleImageClick = (imagePath) => {
    // Set the full image URL and open the modal to display it
    setImageUrl(`http://localhost:3001/${imagePath}`);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <h2>Subscription List</h2>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tier Name</TableCell>
              <TableCell>Bank Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Approval Status</TableCell>
              <TableCell>Receipt Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((subscription) => (
              <TableRow key={subscription.id}>
                <TableCell>{subscription.tier.tier_name}</TableCell>
                <TableCell>{subscription.bankName}</TableCell>
                <TableCell>{new Date(subscription.start_date).toLocaleDateString()}</TableCell>
                <TableCell>{subscription.end_date ? new Date(subscription.end_date).toLocaleDateString() : 'N/A'}</TableCell>
                <TableCell>{subscription.approval_status}</TableCell>
                <TableCell>
                  <img 
                    src={`http://localhost:3001/${subscription.receiptImagePath}`} 
                    alt="Receipt" 
                    style={{ width: '50px', height: 'auto', cursor: 'pointer' }}
                    onClick={() => handleImageClick(subscription.receiptImagePath)} 
                  />
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(subscription)} variant="contained" color="primary">Edit</Button>
                  <Button onClick={() => handleDelete(subscription.id)} variant="contained" color="secondary" style={{ marginLeft: '10px' }}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for success or failure */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '5px' }}>
          {imageUrl ? (
            <div>
              <img src={imageUrl} alt="Large view" style={{ width: '100%', height: 'auto' }} />
            </div>
          ) : (
            <Typography variant="h6">{modalMessage}</Typography>
          )}
          <Button variant="contained" color="primary" onClick={handleCloseModal}>Close</Button>
        </Box>
      </Modal>

      {/* Edit approval status dialog */}
      {selectedSubscription && (
        <Modal open={Boolean(selectedSubscription)} onClose={() => setSelectedSubscription(null)}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '5px' }}>
            <Typography variant="h6">Edit Approval Status</Typography>
            <div>
              <Button variant="contained" color="primary" onClick={() => handleApprovalChange('Approved')}>Approve</Button>
              <Button variant="contained" color="secondary" onClick={() => handleApprovalChange('Rejected')} style={{ marginLeft: '10px' }}>Reject</Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default SubscriptionTable;
