import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Container,
  Typography,
} from '@mui/material';
import axios from 'axios';

const PermissionForm = () => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    userId: '',
    roleName: '',
    module: '',
    canView: false,
    canCreate: false,
    canEdit: false,
    canDelete: false,
  });

  // Retrieve adminToken from localStorage
  const adminToken = localStorage.getItem('adminToken');

  // Set up axios instance with adminToken in the header
  const axiosInstance = axios.create({
    baseURL: 'http://localhost:3001',
    headers: {
      Authorization: `Bearer ${adminToken}`, // Attach token to every request
    },
  });

  // Fetch users from the API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/admin/get-all-admins');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, [axiosInstance]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/api/role/permissions/', formData);
      console.log('Permission registered successfully:', response.data);
      alert('Permission registered successfully!');
    } catch (error) {
      console.error('Error registering permission:', error);
      alert('Failed to register permission.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Register Permission
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* User Dropdown */}
          <Grid item xs={12}>
            <TextField
              select
              label="Select User"
              name="userId"
              value={formData.id}
              onChange={handleChange}
              fullWidth
              required
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.fname}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Role Name */}
          <Grid item xs={12}>
            <TextField
              label="Role Name"
              name="roleName"
              value={formData.roleName}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          {/* Module Dropdown */}
          <Grid item xs={12}>
            <TextField
              select
              label="Module"
              name="module"
              value={formData.module}
              onChange={handleChange}
              fullWidth
              required
            >
              <MenuItem value="order">Order</MenuItem>
              <MenuItem value="report">Report</MenuItem>
              <MenuItem value="dashboard">Dashboard</MenuItem>
              <MenuItem value="author">Author</MenuItem>
              <MenuItem value="user">User Module</MenuItem>
              <MenuItem value="announcement">announcement</MenuItem>
              <MenuItem value="subscription">subscription</MenuItem>
              <MenuItem value="setting">setting</MenuItem>
              <MenuItem value="book">book</MenuItem>
              <MenuItem value="communication">communication</MenuItem>
              <MenuItem value="subscription">subscription</MenuItem>
            
            </TextField>
          </Grid>

          {/* Permissions */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.canView}
                  onChange={handleChange}
                  name="canView"
                />
              }
              label="Can View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.canCreate}
                  onChange={handleChange}
                  name="canCreate"
                />
              }
              label="Can Create"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.canEdit}
                  onChange={handleChange}
                  name="canEdit"
                />
              }
              label="Can Edit"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.canDelete}
                  onChange={handleChange}
                  name="canDelete"
                />
              }
              label="Can Delete"
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Register Permission
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default PermissionForm;
