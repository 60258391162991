import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  CircularProgress,
  Typography,
  Button,
  Modal,
  Box,
} from '@mui/material';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';
// Function to sort users
const sortUsers = (array, order, orderBy) => {
  return array.sort((a, b) => {
    if (b[orderBy] < a[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    if (b[orderBy] > a[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

const VerifiedUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('fname'); // Default sort by first name
  const [open, setOpen] = useState(false); // Modal open state
  const [selectedUser, setSelectedUser] = useState(null); // Selected user for details

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('adminToken'); // Retrieve the token from local storage

      try {
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/manage-user/verified-users', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        });

        if (response.data.success) {
          setUsers(response.data.users);
        } else {
          setError('Failed to fetch users.'); // Handle failure to fetch users
        }
      } catch (err) {
        setError('Error fetching data'); // Handle errors
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchUsers();
  }, []);

  // Handle sorting
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUsers = sortUsers([...users], order, orderBy);

  // Handle modal open/close
  const handleOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  if (loading) {
    return <Loading />; // Show loading indicator
  }

  if (error) {
    return <div>
      <ErrorComponent
          message="Oops! Something went wrong. Please try again."
          showIcon={true}
          severity="error"
        />
    </div>; // Show error message
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'fname'}
                  direction={orderBy === 'fname' ? order : 'asc'}
                  onClick={() => handleSortRequest('fname')}
                >
                  First Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'lname'}
                  direction={orderBy === 'lname' ? order : 'asc'}
                  onClick={() => handleSortRequest('lname')}
                >
                  Last Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers.map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.fname}</TableCell>
                <TableCell>{user.lname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.point}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleOpen(user)}>
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for displaying user details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="user-details-modal"
        aria-describedby="user-details-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          {selectedUser && (
            <div>
              <Typography variant="h6" component="h2">
                User Details
              </Typography>
              <Typography>ID: {selectedUser.id}</Typography>
              <Typography>Email: {selectedUser.email}</Typography>
              <Typography>Phone: {selectedUser.phone || 'N/A'}</Typography>
              <Typography>First Name: {selectedUser.fname}</Typography>
              <Typography>Last Name: {selectedUser.lname}</Typography>
              <Typography>Referral Code: {selectedUser.referalCode}</Typography>
              <Typography>Role: {selectedUser.role}</Typography>
              <Typography>Points: {selectedUser.point}</Typography>
              <Typography>Created At: {new Date(selectedUser.createdAt).toLocaleString()}</Typography>
              <Typography>Expiration Date: {new Date(selectedUser.expirationDate).toLocaleString()}</Typography>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default VerifiedUsers;
