import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  Alert,
  TextField,
  TableSortLabel,
  TablePagination,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const NotAgreedCommunications = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        setError('Admin token is missing.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/comm/notagreed', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.succss) {
          setData(response.data.notAgreedCommunications);
        } else {
          setError('Failed to load data');
        }
      } catch (error) {
        setError(error.response ? error.response.data : 'Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortData = (array) => {
    return array.sort((a, b) => {
      if (orderBy === 'id') {
        return order === 'asc' ? a.id - b.id : b.id - a.id;
      } else if (orderBy === 'message') {
        return order === 'asc' ? a.message.localeCompare(b.message) : b.message.localeCompare(a.message);
      } else if (orderBy === 'serviceCharge') {
        return order === 'asc' ? a.serviceCharge - b.serviceCharge : b.serviceCharge - a.serviceCharge;
      } else if (orderBy === 'createdAt') {
        return order === 'asc' ? new Date(a.createdAt) - new Date(b.createdAt) : new Date(b.createdAt) - new Date(a.createdAt);
      }
      return 0;
    });
  };

  // Handle pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle search input
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered and sorted data
  const filteredData = data.filter((item) =>
    item.message.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const sortedData = sortData(filteredData);
  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container sx={{ marginTop: 8,}}>
      <Typography variant="h4" gutterBottom sx={{ color: '#fff' }}>
        <h3>Not Agreed Communications</h3>
      </Typography>

      {/* Search Bar */}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        sx={{
          marginBottom: 1,
          width: 200, // Minimize the search bar
          position: 'absolute',
          right: '0',
          top: '0',
          marginRight: 5,
          marginTop: 10,
         border: '1 solid white',
        
          
        }}
      />

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ backgroundColor: '#141B2D',}}>
          <TableHead sx={{ backgroundColor: '#3E4396', color: '#fff' }}>
            <TableRow>
              {/* <TableCell>
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={order}
                  onClick={(e) => handleRequestSort(e, 'id')}
                >
                  ID
                </TableSortLabel>
              </TableCell> */}
              <TableCell>

                <TableSortLabel
                  active={orderBy === 'message'}
                  direction={order}
                  onClick={(e) => handleRequestSort(e, 'message')}
                >
                 Message
                </TableSortLabel>
                
                </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'serviceCharge'}
                  direction={order}
                  onClick={(e) => handleRequestSort(e, 'serviceCharge')}
                >
                  Service Charge
                </TableSortLabel>
              </TableCell>
              <TableCell>Reviewed By</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Is Agreed</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'createdAt'}
                  direction={order}
                  onClick={(e) => handleRequestSort(e, 'createdAt')}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell>Book ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((item) => (
              <TableRow key={item.id}>
                {/* <TableCell>{item.id}</TableCell> */}
                <TableCell>{item.message}</TableCell>
                <TableCell>{item.serviceCharge}</TableCell>
                <TableCell>{item.reviewedBy}</TableCell>
                <TableCell>{item.reason || 'N/A'}</TableCell>
                <TableCell>{item.isAgreed ? 'Yes' : 'No'}</TableCell>
                <TableCell>{new Date(item.createdAt).toLocaleString()}</TableCell>
                <TableCell>{new Date(item.updatedAt).toLocaleString()}</TableCell>
                <TableCell>{item.book_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ backgroundColor: '#3E4396', color: '#fff' }}
      />
    </Container>
  );
};

export default NotAgreedCommunications;
