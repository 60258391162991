import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, Typography, CircularProgress, Alert, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
  TableSortLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Loading from '../../components/loading';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[4],
  marginTop: theme.spacing(2),
}));

const TopRated = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');

  const fetchTopRatedBooks = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://bookbackend3.bruktiethiotour.com/api/book/top-rated');
      if (response.data.success) {
        setBooks(response.data.books);
      } else {
        setError('Failed to fetch top-rated books.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopRatedBooks();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedBooks = books.slice().sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return a[orderBy] > b[orderBy] ? -1 : 1;
  });

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" mb={2}>Top Rated Books</Typography>

      {loading && (
    <Loading />
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && books.length === 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          No top-rated books available.
        </Alert>
      )}

      {!loading && !error && books.length > 0 && (
        <StyledTableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {['Title', 'Author', 'Publication Year', 'Pages', 'Price', 'Rating'].map((headCell, index) => (
                  <TableCell
                    key={headCell}
                    sortDirection={orderBy === headCell.toLowerCase().replace(' ', '') ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.toLowerCase().replace(' ', '')}
                      direction={orderBy === headCell.toLowerCase().replace(' ', '') ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.toLowerCase().replace(' ', ''))}
                    >
                      {headCell}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedBooks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((book) => (
                <TableRow key={book.id} hover>
                  <TableCell>{book.title}</TableCell>
                  <TableCell>{book.author}</TableCell>
                  <TableCell>{book.publicationYear}</TableCell>
                  <TableCell>{book.pages}</TableCell>
                  <TableCell>{book.price}</TableCell>
                  <TableCell>{book.rating} ({book.rateCount} reviews)</TableCell>
                  <TableCell>
                    <img
                      src={`https://bookbackend3.bruktiethiotour.com/${book.imageFilePath}`}
                      alt={book.title}
                      style={{ width: 50, height: 70, borderRadius: '4px' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={books.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyledTableContainer>
      )}
    </Box>
  );
};

export default TopRated;
