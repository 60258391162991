import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Avatar,
  Divider,
  Stack,
  Button,
} from '@mui/material';
import { PictureAsPdf as PdfIcon } from '@mui/icons-material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import ErrorComponent from '../../components/error';

const BookDetail = () => {
  const [book, setBook] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseURL = 'https://bookbackend3.bruktiethiotour.com/';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://bookbackend3.bruktiethiotour.com/api/book/find/${id}`);
        if (response.data.success) {
          setBook(response.data.data);
        } else {
          setError('Failed to fetch data');
        }
      } catch (error) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorComponent
        message="Oops! Something went wrong. Please try again."
        showIcon={true}
        severity="error"
      />
    );
  }

  if (!book) {
    return <Typography>No book found.</Typography>;
  }

  return (
    <Box display="flex" justifyContent="center" p={3}>
      <Box maxWidth="700px" width="100%">
        <Card
          variant="outlined"
          sx={{
            p: 3,
            borderRadius: '16px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#141B2D',
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={{ fontWeight: 'bold', color: '#333' }}
            >
              {book.title}
            </Typography>
            <Box display="flex" justifyContent="center" mb={3}>
              <Avatar
                alt={book.title}
                src={`${baseURL}/${book.imageFilePath}`}
                sx={{
                  width: 140,
                  height: 140,
                  border: '3px solid #1976d2',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  variant: 'square',
                }}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Author:</strong> {book.author}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Publication Year:</strong> {book.publicationYear}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Language:</strong> {book.language}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Price:</strong> ${book.price || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Pages:</strong> {book.pages || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Sold:</strong> {book.sold || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Rating:</strong> {book.rating || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Rate Count:</strong> {book.rateCount || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Service Charges:</strong> ${book.serviceCharges || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Revenue:</strong>{' '}
                  ${book.revenue ? book.revenue.toFixed(2) : '0.00'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Status:</strong> {book.status}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color="text.secondary" paragraph>
                  <strong>Description:</strong> {book.description || 'No description provided'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color="text.secondary" paragraph>
                  <strong>Reason:</strong> {book.reason || 'No reason provided'}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 3, borderColor: '#1976d2' }} />
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                startIcon={<PdfIcon />}
                href={`${baseURL}/${book.pdfFilePath}`}
                target="_blank"
                sx={{
                  borderRadius: '20px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                View PDF
              </Button>
            </Stack>
            <Typography
              variant="caption"
              color="text.secondary"
              align="center"
              display="block"
              sx={{ mt: 3 }}
            >
              Last Updated: {book.updatedAt ? new Date(book.updatedAt).toLocaleDateString() : 'N/A'}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default BookDetail;
