import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TablePagination,
} from '@mui/material';

const SubscriptionRevenue = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalMonthly, setTotalMonthly] = useState(0);
  const [totalAnnual, setTotalAnnual] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedColumn, setSortedColumn] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchRevenueData = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://bookbackend3.bruktiethiotour.com/api/subscriptions/all', {
        startDate,
        endDate,
      });

      const { subscriptions } = response.data;

      // Calculate total monthly and annual prices
      const monthlyTotal = subscriptions.reduce(
        (acc, sub) => acc + parseFloat(sub.tier.monthly_price || 0),
        0
      );
      const annualTotal = subscriptions.reduce(
        (acc, sub) => acc + parseFloat(sub.tier.annual_price || 0),
        0
      );

      setSubscriptions(subscriptions);
      setTotalMonthly(monthlyTotal);
      setTotalAnnual(annualTotal);
    } catch (error) {
      console.error('Error fetching revenue data:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSort = (column) => {
    const newSortOrder = sortedColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortedColumn(column);
    setSortOrder(newSortOrder);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredSubscriptions = subscriptions.filter(
    (sub) =>
      sub.id.toString().includes(searchTerm) ||
      sub.userFname.toLowerCase().includes(searchTerm) ||
      sub.tier.tier_name.toLowerCase().includes(searchTerm)
  );

  const sortedSubscriptions = [...filteredSubscriptions].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
    } else {
      return a[sortedColumn] < b[sortedColumn] ? 1 : -1;
    }
  });

  const paginatedSubscriptions = sortedSubscriptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    fetchRevenueData();
  }, [startDate, endDate]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Revenue Report
      </Typography>

      {/* Date Range Inputs */}
      <Box display="flex" justifyContent="center" gap={2} mb={3}>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={fetchRevenueData}
          disabled={loading || !startDate || !endDate}
        >
          {loading ? <CircularProgress size={24} /> : 'Generate Report'}
        </Button>
      </Box>

      {/* Search Input */}
      <Box display="flex" justifyContent="flex-end" mb={3}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: 300 }}
        />
      </Box>

      {/* Table Display */}
      {subscriptions.length > 0 && (
        <TableContainer sx={{ backgroundColor: '#141B2D', marginTop: 10}}  component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" onClick={() => handleSort('id')}>Subscription ID</TableCell>
                <TableCell align="center" onClick={() => handleSort('userFname')}>User Name</TableCell>
                <TableCell align="center" onClick={() => handleSort('tier.tier_name')}>Tier Name</TableCell>
                <TableCell align="center" onClick={() => handleSort('tier.monthly_price')}>Monthly Price</TableCell>
                <TableCell align="center" onClick={() => handleSort('tier.annual_price')}>Annual Price</TableCell>
                <TableCell align="center" onClick={() => handleSort('start_date')}>Start Date</TableCell>
                <TableCell align="center" onClick={() => handleSort('end_date')}>End Date</TableCell>
                <TableCell align="center" onClick={() => handleSort('approval_status')}>Approval Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedSubscriptions.map((sub) => (
                <TableRow key={sub.id}>
                  <TableCell align="center">{sub.id}</TableCell>
                  <TableCell align="center">{sub.userFname}</TableCell>
                  <TableCell align="center">{sub.tier.tier_name}</TableCell>
                  <TableCell align="center">ETB{sub.tier.monthly_price}</TableCell>
                  <TableCell align="center">ETB{sub.tier.annual_price}</TableCell>
                  <TableCell align="center">{new Date(sub.start_date).toLocaleDateString()}</TableCell>
                  <TableCell align="center">
                    {sub.end_date ? new Date(sub.end_date).toLocaleDateString() : 'N/A'}
                  </TableCell>
                  <TableCell align="center">{sub.approval_status}</TableCell>
                </TableRow>
              ))}

              {/* Total Row */}
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" fontWeight="bold">
                    ETB{totalMonthly.toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" fontWeight="bold">
                    ETB {totalAnnual.toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell align="center" colSpan={3}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* No Data Message */}
      {!loading && subscriptions.length === 0 && (
        <Typography variant="h6" align="center" color="textSecondary">
          No subscriptions found for the selected date range.
        </Typography>
      )}

      {/* Pagination Controls */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredSubscriptions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default SubscriptionRevenue;
